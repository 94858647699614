import { DateFilter } from 'components/molecules/DateRangePicker';
import { API_ENDPOINT } from 'configs/constant';
import { Action } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import fetchApi from 'redux/api/apiAction';
import { TFetchToken } from 'redux/api/apiTypes';
import { setModalOpen } from 'redux/global/globalAction';
import {
  ADD_GROUP,
  ADD_GROUP_FAILLURE,
  ADD_GROUP_MEMBER,
  ADD_GROUP_MEMBER_SUCCESS,
  DELETE_GROUP,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_SUCCESS,
  GET_GROUPS,
  GET_GROUPS_SUCCESS,
  GET_GROUP_ACTIVITIES,
  GET_GROUP_ACTIVITIES_FAILURE,
  GET_GROUP_ACTIVITIES_SUCCESS,
  GET_GROUP_BY_ID,
  GET_GROUP_BY_ID_FAILURE,
  GET_GROUP_BY_ID_SUCCESS,
  GET_GROUP_KEY,
  GET_GROUP_KEY_FAILURE,
  GET_GROUP_KEY_SUCCESS,
  GET_INVITE_BY_LINK_GROUP,
  GET_INVITE_BY_LINK_GROUP_FAILURE,
  Group,
  GroupActivities,
  Groups,
  GroupsAction,
  GroupUpdateProps,
  IKeyFieldProps,
  RESET_GROUP_KEY,
  TMemberLevel,
  UPDATE_GROUP,
  UPDATE_GROUP_FAILURE,
} from './groupTypes';

export interface IGroupProps {
  name: string;
  is_public: boolean;
  description: string;
  key_fields: IKeyFieldProps[];
}

export interface IGroupMemberProps {
  emails: string[];
  level: TMemberLevel;
}

export const getGroups = (token: TFetchToken) => {
  return fetchApi({
    url: `${API_ENDPOINT}groups?&pages=1&limits=10`,
    label: GET_GROUPS,
    onSuccess: getGroupSuccess,
    onFailure: getGroupFailure,
    accessToken: token,
  });
};

const getGroupSuccess = (payload: Groups): GroupsAction => {
  return {
    type: GET_GROUPS_SUCCESS,
    payload,
  };
};

export const getGroupFailure = ({ response }: any): GroupsAction => {
  return {
    type: ADD_GROUP_FAILLURE,
    payload: {
      error: response?.data?.data?.detail || 'Oops somethin wrong !',
    },
  };
};

export const getGroupById = (token: TFetchToken, id: number) => {
  return fetchApi({
    url: `${API_ENDPOINT}groups/${id}/`,
    label: GET_GROUP_BY_ID,
    onSuccess: getGroupByIdSuccess,
    onFailure: getGroupByIdFailure,
    accessToken: token,
  });
};

export const getGroupByIdSuccess = (
  payload: Group,
  action?: () => void
): GroupsAction => {
  if (action) {
    action();
  }

  return {
    type: GET_GROUP_BY_ID_SUCCESS,
    payload,
  };
};

const getGroupByIdFailure = ({ response }: any): GroupsAction => {
  return {
    type: GET_GROUP_BY_ID_FAILURE,
    payload: {
      error: response?.data?.data?.detail || 'Oops somethin wrong !',
    },
  };
};

export const getGroupKey = (token: TFetchToken, id: number) => {
  return fetchApi({
    url: `${API_ENDPOINT}groups/${id}/keys/`,
    label: GET_GROUP_KEY,
    onSuccess: getGroupKeySuccess,
    onFailure: getGroupKeyFailure,
    accessToken: token,
  });
};

const getGroupKeySuccess = (payload: {
  key_fields: IKeyFieldProps[];
}): GroupsAction => {
  return {
    type: GET_GROUP_KEY_SUCCESS,
    payload: payload.key_fields,
  };
};

const getGroupKeyFailure = ({ response }: any): GroupsAction => {
  let errorMessage = 'Oops somethin wrong !';

  if (response.data?.data?.error) {
    errorMessage = response.data.data.error;
  }

  return {
    type: GET_GROUP_KEY_FAILURE,
    payload: {
      error: errorMessage,
    },
  };
};

export const resetGroupKey = (): GroupsAction => ({
  type: RESET_GROUP_KEY,
});

export const addGroup = (
  token: TFetchToken,
  payload: IGroupProps,
  action?: () => void
) => {
  const keyFields = payload.key_fields.map((result) => ({
    ...result,
    id: result.name.split(' ').join('_').toLowerCase(),
  }));

  const data = {
    ...payload,
    key_fields: keyFields,
  };

  return fetchApi({
    url: `${API_ENDPOINT}groups/`,
    method: 'POST',
    label: ADD_GROUP,
    onSuccess: (result: Group) => addGroupSuccess(result, action),
    onFailure: addGroupFailure,
    data,
    accessToken: token,
  });
};

const addGroupSuccess = (payload: Group, action?: () => void): GroupsAction => {
  if (action) {
    action();
  }

  return {
    type: GET_GROUP_BY_ID_SUCCESS,
    payload,
  };
};

export const addGroupFailure = ({ response }: any): GroupsAction => {
  return {
    type: ADD_GROUP_FAILLURE,
    payload: {
      error: response?.data?.data?.detail || 'Oops somethin wrong !',
    },
  };
};

export const updateGroup = (
  token: TFetchToken,
  groupId: number,
  payload: GroupUpdateProps,
  action?: () => void
) => {
  return fetchApi({
    url: `${API_ENDPOINT}groups/${groupId}/`,
    method: 'PUT',
    label: UPDATE_GROUP,
    onSuccess: (result: Group) => updateGroupSuccess(result, action),
    onFailure: updateGroupFailure,
    data: payload,
    accessToken: token,
  });
};

const updateGroupSuccess = (
  payload: Group,
  action?: () => void
): GroupsAction => {
  if (action) {
    action();
  }

  return {
    type: GET_GROUP_BY_ID_SUCCESS,
    payload,
  };
};

const updateGroupFailure = ({ response }: any): GroupsAction => {
  return {
    type: UPDATE_GROUP_FAILURE,
    payload: {
      error: response?.data?.data?.detail || 'Oops somethin wrong !',
    },
  };
};
export const deleteGroup = (
  token: TFetchToken,
  groupId: number,
  actionSuccess?: () => void
) => {
  return fetchApi({
    url: `${API_ENDPOINT}groups/${groupId}/`,
    label: DELETE_GROUP,
    method: 'DELETE',
    onSuccess: () => deleteGroupSuccess(token, actionSuccess),
    onFailure: (error: any) => deleteGroupFailure(error),
    accessToken: token,
  });
};

const deleteGroupSuccess = (
  token: TFetchToken,
  action?: () => void
): GroupsAction => {
  getGroups(token);

  if (action) {
    action();
  }

  return {
    type: DELETE_GROUP_SUCCESS,
  };
};

const deleteGroupFailure =
  ({ response }: any) =>
  async (dispatch: ThunkDispatch<GroupsAction, void, Action>) => {
    let errorMessage = 'Oops somethin wrong !';

    if (response.data.data.error) {
      errorMessage = response.data.data.error;
    }

    dispatch({
      type: DELETE_GROUP_FAILURE,
      payload: {
        error: errorMessage,
      },
    });

    return dispatch(
      setModalOpen({
        isOpen: true,
        title: `Proyek tidak dapat dihapus !`,
        description: errorMessage,
        secondActionText: 'tutup',
      })
    );
  };

export const getInviteByLink = (
  token: TFetchToken,
  groupToken: string,
  action?: any
) => {
  return fetchApi({
    url: `${API_ENDPOINT}join/${groupToken}/`,
    label: GET_INVITE_BY_LINK_GROUP,
    method: 'POST',
    onSuccess: (result: Group) =>
      getGroupByIdSuccess(result, () => action(`/group/${result.id}`)),
    onFailure: getInviteByLinkFailure,
    data: {
      token: groupToken,
    },
    accessToken: token,
  });
};

const getInviteByLinkFailure = ({ response }: any): GroupsAction => {
  let errorMessage = 'Oops somethin wrong !';

  if (response.data.data.error) {
    errorMessage = response.data.data.error;
  }

  return {
    type: GET_INVITE_BY_LINK_GROUP_FAILURE,
    payload: {
      error: errorMessage,
    },
  };
};

export const addGroupMember = (
  token: TFetchToken,
  groupId: number,
  payload: IGroupMemberProps,
  actionSuccess?: () => void
) => {
  return fetchApi({
    url: `${API_ENDPOINT}groups/${groupId}/invite/`,
    label: ADD_GROUP_MEMBER,
    method: 'POST',
    onSuccess: () => addGroupMemberSuccess(token, actionSuccess),
    onFailure: (error: any) => getGroupByIdFailure(error),
    accessToken: token,
    data: payload,
  });
};

const addGroupMemberSuccess = (
  token: TFetchToken,
  action?: () => void
): GroupsAction => {
  getGroups(token);

  if (action) {
    action();
  }

  return {
    type: ADD_GROUP_MEMBER_SUCCESS,
  };
};

export const getGroupActivities = (
  token: TFetchToken,
  groupId: number,
  {
    page = 1,
    limit = 10,
    dateFilter,
  }: {
    page?: number;
    limit?: number;
    dateFilter?: DateFilter;
  },
  action?: () => void
) =>
  fetchApi({
    url: `${API_ENDPOINT}groups/${groupId}/logs`,
    data: {
      page,
      page_size: limit,
      start_date: dateFilter?.start_date,
      end_date: dateFilter?.end_date,
    },
    label: GET_GROUP_ACTIVITIES,
    accessToken: token,
    onSuccess: (result: GroupActivities) =>
      getGroupActivitiesSuccess(result, action),
    onFailure: getGroupActivitiesFailure,
  });

const getGroupActivitiesSuccess = (
  payload: GroupActivities,
  action?: () => void
): GroupsAction => {
  console.log('GET GROUP ACTIVIITES SUCCESS ', payload);

  if (action) {
    action();
  }

  return {
    type: GET_GROUP_ACTIVITIES_SUCCESS,
    payload,
  };
};

export const getGroupActivitiesFailure = ({ response }: any): GroupsAction => {
  console.log('GET BVET Feedback FAILURE : ', response);

  let errorMessage = 'Oops somethin wrong !';

  if (response?.data?.data?.error) {
    errorMessage = response.data.data.error;
  }

  return {
    type: GET_GROUP_ACTIVITIES_FAILURE,
    payload: {
      error: errorMessage,
    },
  };
};
