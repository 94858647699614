import css from '@styled-system/css';
import React from 'react';
import styled from 'styled-components';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  compose,
  grid,
  gridArea,
  GridAreaProps,
  GridProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow,
  ShadowProps,
  space,
  SpaceProps,
} from 'styled-system';

export type ElementStyleProps =
  | SpaceProps
  | ColorProps
  | BorderProps
  | LayoutProps
  | PositionProps
  | GridAreaProps
  | GridProps
  | ShadowProps;

export type BoxStyleProps = ElementStyleProps & {
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  gapX?: string;
  gapY?: string;
};

const Box = styled.div<BoxStyleProps>`
  box-sizing: border-box;

  ${({ gapX }) =>
    gapX &&
    css({
      '> *': {
        marginRight: gapX,
      },
      '> *:last-child': {
        marginRight: 0,
      },
    })}

  ${({ gapY }) =>
    gapY &&
    css({
      '> *': {
        marginBottom: gapY,
      },
      '> *:last-child': {
        marginBottom: 0,
      },
    })}

  ${css({})}

  ${compose(space, color, border, layout, position, grid, gridArea, shadow)}
` as React.FC<BoxStyleProps>;

export default Box;
