import { API_ENDPOINT } from 'configs/constant';
import fetchApi from 'redux/api/apiAction';
import { User } from 'redux/user/userTypes';
import {
  Auth,
  AuthAction,
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  REGISTER,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from './authTypes';

export interface ILoginProps {
  username: string;
  password: string;
}

export interface IRegisterProps {
  email: string;
  password: string;
  confirm_password: string;
}

export interface IForgotPasswordProps {
  email: string;
}

export interface IResetPasswordProps {
  password: string;
  password_confirm: string;
}

export const login = (payload: ILoginProps) => {
  return fetchApi({
    url: `${API_ENDPOINT}accounts/login/`,
    method: 'POST',
    label: LOGIN,
    onSuccess: loginSuccess,
    onFailure: loginFailure,
    data: payload,
  });
};

export const loginSuccess = (payload: Auth): AuthAction => {
  localStorage.setItem('token', payload.access_token);
  localStorage.setItem('user', JSON.stringify(payload.user));

  return {
    type: LOGIN_SUCCESS,
    payload,
  };
};

export const loginFailure = ({ response }: any): AuthAction => {
  console.log('ERROR LOGIN : ', response?.data);

  return {
    type: LOGIN_FAILURE,
    payload: {
      error: response?.data?.data?.detail || 'Oops somethin wrong !',
    },
  };
};

export const register = (payload: IRegisterProps, action?: () => void) => {
  return fetchApi({
    url: `${API_ENDPOINT}accounts/register/`,
    method: 'POST',
    label: REGISTER,
    onSuccess: (result: User) => registerSuccess(result, action),
    onFailure: registerFailure,
    data: payload,
  });
};

export const registerSuccess = (
  payload: User,
  action?: () => void
): AuthAction => {
  console.log('ACTION REGISTER', action);

  if (action) {
    action();
  }

  return {
    type: REGISTER_SUCCESS,
    payload,
  };
};

export const registerFailure = ({ response }: any) => {
  console.log('ERROR REGISTER : ', response.data);

  let errorMessage;

  if (response.data.data.email) {
    errorMessage = response.data.data.email[0];
  }

  if (response.data.data.confirm_password) {
    errorMessage = response.data.data.confirm_password[0];
  }

  return {
    type: REGISTER_FAILURE,
    payload: {
      error: errorMessage || 'Oops somethin wrong !',
    },
  };
};

export const logout = (action?: () => void): AuthAction => {
  if (action) {
    action();
  }

  localStorage.removeItem('token');
  localStorage.removeItem('user');

  return {
    type: LOGOUT,
  };
};
