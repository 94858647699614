import ForgotPassword from 'pages/Auth/ForgotPassoword';
import Login from 'pages/Auth/Login';
import Logout from 'pages/Auth/Logout';
import Register from 'pages/Auth/Register';
import ResetPassword from 'pages/Auth/ResetPassword';
import Demo from 'pages/Demo/Demo';
import GetLocalStorage from 'pages/GetLocalStorage';
import Home from 'pages/Home/Home';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export interface IRouteProps {
  component: React.FC<RouteComponentProps>;
  path: string;
  exact?: boolean;
}

const routes: {
  private: IRouteProps[];
  public: IRouteProps[];
  default: IRouteProps[];
} = {
  public: [
    {
      exact: true,
      path: '/login',
      component: Login,
    },
    {
      exact: true,
      path: '/register',
      component: Register,
    },
    {
      exact: true,
      path: '/forgot',
      component: ForgotPassword,
    },
    {
      exact: true,
      path: '/reset',
      component: ResetPassword,
    },
  ],
  private: [
    {
      path: '/logout',
      component: Logout,
    },
  ],
  default: [
    {
      path: '/demo',
      component: Demo,
    },
    {
      exact: true,
      path: '/get-storage',
      component: GetLocalStorage,
    },
    {
      path: '/',
      component: Home,
    },
  ],
};

export default routes;
