import { IRouteProps } from 'routes/routes';
import css from '@styled-system/css';
import Grid, { GridStyleProps } from 'components/atoms/Grid';
import { Switch } from 'react-router-dom';
import PrivateRoute from 'routes/PrivateRoute';
import Drawer, { DrawerRoutesProps } from 'components/organisms/Drawer/drawer';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsDrawerOpen } from 'redux/global/globalAction';
import { GlobalState } from 'redux/global/globalReducer';
import { AppState } from 'redux/store';
import styled from 'styled-components';
import Box from 'components/atoms/Box';
import Header from 'components/organisms/Header';
import Maps from 'components/organisms/Maps';
import useScreeType from 'hooks/useScreenType';
import DataPageView from 'components/organisms/DataPageView';

export interface IMainTemplateProps {
  drawerRoutes: DrawerRoutesProps;
  routes: IRouteProps[];
}

const MainViewStyle = styled(Grid)<GridStyleProps>`
  ${css({
    height: '100vh',
    gridTemplateColumns: ['1fr', 'auto auto 1fr'],
    gridTemplateAreas: ['"Header""Main"', '"Drawer Main Maps"'],
    gridTemplateRows: ['46px 1fr', '100vh'],
    overflow: ['', 'hidden'],
  })}
`;

const MainTemplate: React.FC<IMainTemplateProps> = ({
  drawerRoutes,
  routes,
  children,
  ...rest
}) => {
  const screenType = useScreeType();
  const dispatch = useDispatch();

  const { isDrawerOpen, isFeedbackFormOpen } = useSelector<
    AppState,
    GlobalState
  >(({ global }) => global);

  const handleDrawerToogle: (
    event: React.MouseEvent<HTMLButtonElement>,
    value?: boolean
  ) => void = useCallback(
    (_event, value) => {
      dispatch(setIsDrawerOpen(value !== undefined ? value : !isDrawerOpen));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDrawerOpen]
  );

  return (
    <MainViewStyle overflow={isFeedbackFormOpen ? 'hidden' : ''} {...rest}>
      <Drawer
        gridArea="Drawer"
        routes={drawerRoutes}
        isOpen={isDrawerOpen}
        setIsOpen={handleDrawerToogle}
      />

      {screenType === 'mobile' ? (
        <>
          <Header
            gridArea="Header"
            isOpen={isDrawerOpen}
            setIsOpen={handleDrawerToogle}
          />
          <Box gridArea="Main" position="relative">
            <Box width="100%" height="75vh" position="fixed" top={['46px', 0]}>
              <Maps />
            </Box>

            <Switch>
              {routes.map((result) => (
                <PrivateRoute key={result.path} {...result} />
              ))}
            </Switch>
          </Box>
        </>
      ) : (
        <>
          <Switch>
            {routes.map((result) => (
              <PrivateRoute key={result.path} {...result} />
            ))}
          </Switch>
          <Box gridArea="Maps">
            <Maps />
            <DataPageView />
          </Box>
        </>
      )}
      {children}
    </MainViewStyle>
  );
};

export default MainTemplate;
