import React from 'react';
import Flex from '../Flex';
import { Caption } from '../Typography';
import SwitchStyle, { SwitchStyleProps } from './switch.style';

export type SwitchProps = SwitchStyleProps &
  React.InputHTMLAttributes<HTMLInputElement>;

const Switch: React.ForwardRefRenderFunction<unknown, SwitchProps> = (
  { ...rest },
  ref
) => (
  <Flex alignItems="center" height="40px" width="fit-content">
    <Caption as="span" paddingRight="small">
      Tidak
    </Caption>
    <SwitchStyle
      as="input"
      type="checkbox"
      ref={
        ref as React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement>
      }
      {...rest}
    />
    <Caption as="span" paddingLeft="small">
      Ya
    </Caption>
  </Flex>
);

export default React.forwardRef<unknown, SwitchProps>(Switch);
