import React from 'react';
import css from '@styled-system/css';
import styled from 'styled-components';
import {
  border,
  color,
  compose,
  flexbox,
  FlexboxProps,
  gridArea,
  layout,
  shadow,
  space,
} from 'styled-system';
import Box, { BoxStyleProps } from '../Box';

export type FlexStyleProps = BoxStyleProps & FlexboxProps;

const Flex = styled(Box)<FlexStyleProps>(
  css({
    display: 'flex',
  }),
  compose(space, color, border, layout, flexbox, gridArea, shadow)
) as React.FC<FlexStyleProps>;

export default Flex;
