import React, { useMemo, useEffect, useRef } from 'react';
import Flex from 'components/atoms/Flex';
import Heading from 'components/atoms/Heading';
import ButtonCopy from 'components/molecules/ButtonCopy';
import { CircleMarker as CircleMarkerLeaflet } from 'leaflet';
import { CircleMarker, Popup, Tooltip } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { useSelector } from 'react-redux';
import { matchPath, useHistory } from 'react-router-dom';
import { AnotationState } from 'redux/anotation/anotationReducer';
import { GroupState } from 'redux/group/groupReducer';
import { AppState } from 'redux/store';
import ListData from 'components/molecules/ListData';

const AnotationMarker: React.FC = () => {
  const { push } = useHistory();
  const markerAnotationRef = useRef<CircleMarkerLeaflet>(null);

  const match = matchPath<{ groupId: string }>(window.location.pathname, {
    path: ['/group/:groupId', '/analytic/:groupId'],
  });

  const { anotations, anotationById } = useSelector<AppState, AnotationState>(
    (state) => state.anotation
  );
  const { groupById, groupKeys } = useSelector<AppState, GroupState>(
    (state) => state.group
  );

  const normalDataAnotation = useMemo(() => {
    const rowData: any = {
      Tanggal: anotationById?.created_at,
      id: anotationById?.id,
      Nama: anotationById?.attributes.namaField,
      Alamat: anotationById?.features.reversegeocode?.result['#text'],
      Koordinat: anotationById?.features.geometry.coordinates.toString(),
      data: [],
    };

    anotationById?.attributes.data.forEach((attribute, index) => {
      console.log('ATTRIBUTE', attribute);

      return rowData.data.push({
        data_ke: index + 1,
        ...attribute,
      });
    });

    return rowData;
  }, [anotationById]);

  // Handle Popup default open
  useEffect(() => {
    markerAnotationRef.current?.openPopup();
  }, [anotationById]);

  return (
    <>
      {anotationById && (
        <CircleMarker
          ref={markerAnotationRef}
          center={anotationById.features.geometry.coordinates}
          pathOptions={{ color: 'blue' }}
        >
          <Popup>
            <Flex
              justifyContent="space-between"
              alignItems="center"
              gapX="small"
            >
              <Heading as="span" truncate={2}>
                {normalDataAnotation.Nama}
              </Heading>
              <ButtonCopy id="content_anotation" />
            </Flex>

            <ListData
              id="content_anotation"
              data={normalDataAnotation}
              maxHeight="300px"
              overflow="auto"
              marginTop="normal"
              columnArray={groupKeys}
            />
          </Popup>
        </CircleMarker>
      )}

      {match?.params.groupId && (
        <MarkerClusterGroup key={new Date().valueOf().toString()}>
          {anotations?.map((result) => (
            <CircleMarker
              key={result.id}
              center={result.features.geometry.coordinates}
              pathOptions={{ color: 'red' }}
              eventHandlers={{
                click: () =>
                  push({
                    pathname: `/group/${groupById?.id}/anotation/${result.id}`,
                    state: {
                      result,
                    },
                  }),
              }}
            >
              <Tooltip>{result.attributes.namaField}</Tooltip>
            </CircleMarker>
          ))}
        </MarkerClusterGroup>
      )}
    </>
  );
};

export default AnotationMarker;
