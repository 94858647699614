import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import BVETApp from 'pages/BVET/BVETApp';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components';
import themes from './themes/theme';
import { GlobalStyles } from './themes/GlobalStyle';
import { Provider } from 'react-redux';
import { store } from 'redux/store';

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={themes}>
      <GlobalStyles />
      <Provider store={store}>
        <App />
        {/* <BVETApp /> */}
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
