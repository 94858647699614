import css from '@styled-system/css';
import styled from 'styled-components';
import {
  compose,
  space,
  color,
  border,
  layout,
  flexbox,
  gridArea,
  variant,
  TypographyProps,
  typography,
  shadow,
} from 'styled-system';
import { FlexStyleProps } from '../Flex';

export type IconStyleProps = FlexStyleProps & TypographyProps;

export type IconVariantProps = 'primary' | 'secondary' | 'error';

const variants: { [key in IconVariantProps]: object } = {
  primary: {
    color: 'primary',
  },
  secondary: {
    color: 'secondary',
  },
  error: {
    color: 'error',
  },
};

const IconStyle = styled.div<IconStyleProps>(
  css({
    width: 'auto',
    height: 'auto',
    fontSize: '24px',
  }),
  variant({ variants }),
  compose(space, color, typography, border, layout, flexbox, gridArea, shadow)
);

export default IconStyle;
