export const IS_DRAWER_OPEN = 'IS_DRAWER_OPEN';
export const SET_MODAL_CLOSE = 'SET_MODAL_CLOSE';
export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';
export const SET_YEAR = 'SET_YEAR';
export const IS_FEEDBACK_FORM_OPEN = 'IS_FEEDBACK_FORM_OPEN';
export const ADD_FEEDBACK = 'ADD_FEEDBACK';
export const ADD_FEEDBACK_SUCCESS = 'ADD_FEEDBACK_SUCCESS';
export const ADD_FEEDBACK_FAILURE = 'ADD_FEEDBACK_FAILURE';
export const GET_BVET_FEEDBACK = 'GET_BVET_FEEDBACK';
export const GET_BVET_FEEDBACK_SUCCESS = 'GET_BVET_FEEDBACK_SUCCESS';
export const GET_BVET_FEEDBACK_FAILURE = 'GET_BVET_FEEDBACK_FAILURE';
export const ADD_BVET_FEEDBACK = 'ADD_BVET_FEEDBACK';
export const ADD_BVET_FEEDBACK_SUCCESS = 'ADD_BVET_FEEDBACK_SUCCESS';
export const ADD_BVET_FEEDBACK_FAILURE = 'ADD_BVET_FEEDBACK_FAILURE';

export interface IModalState {
  isOpen?: boolean;
  title: string;
  description: string | JSX.Element;
  action?: () => void;
  actionText?: string;
  secondAction?: boolean;
  secondActionText?: string;
}

export interface Feedback {
  message: string;
}

export interface BVETFeedback extends Feedback {
  email: string;
  fullname: string;
  phone?: string;
  is_helpful: boolean;
  is_useful: boolean;
}

export type BVETFeedbacksResults = BVETFeedback & {
  id: string;
  created_at: string;
  updated_at: string;
};

export type BVETFeedbacks = {
  count: number;
  next: string | null;
  previous: string | null;
  results: BVETFeedbacksResults[];
};

interface IsDrawerOpenAction {
  type: typeof IS_DRAWER_OPEN;
  payload: boolean;
}

interface SetModalOpenAction {
  type: typeof SET_MODAL_OPEN;
  payload: IModalState;
}

interface SetModalCloseAction {
  type: typeof SET_MODAL_CLOSE;
}

interface SetYear {
  type: typeof SET_YEAR;
  payload: number;
}

interface IsFeedbackFormOpen {
  type: typeof IS_FEEDBACK_FORM_OPEN;
  payload: boolean;
}

interface AddFeedbackSuccess {
  type: typeof ADD_FEEDBACK_SUCCESS;
  payload: Feedback;
}

interface AddFeedbackFailure {
  type: typeof ADD_FEEDBACK_FAILURE;
  payload: {
    error: string;
  };
}

interface GetBVETFeedbacksSuccess {
  type: typeof GET_BVET_FEEDBACK_SUCCESS;
  payload: BVETFeedbacks;
}

interface GetBVETFeedbacksFailure {
  type: typeof GET_BVET_FEEDBACK_FAILURE;
  payload: {
    error: string;
  };
}

interface AddBVETFeedbackSuccess {
  type: typeof ADD_BVET_FEEDBACK_SUCCESS;
  payload: BVETFeedback;
}

interface AddBVETFeedbackFailure {
  type: typeof ADD_BVET_FEEDBACK_FAILURE;
  payload: {
    error: string;
  };
}

export type GlobalAction =
  | IsDrawerOpenAction
  | SetModalOpenAction
  | SetModalCloseAction
  | SetYear
  | IsFeedbackFormOpen
  | AddFeedbackSuccess
  | AddFeedbackFailure
  | GetBVETFeedbacksSuccess
  | GetBVETFeedbacksFailure
  | AddBVETFeedbackSuccess
  | AddBVETFeedbackFailure;
