export const GET_METHODS = 'GET_METHODS';
export const GET_METHODS_SUCCESS = 'GET_METHODS_SUCCESS';
export const GET_METHODS_FAILURE = 'GET_METHODS_FAILURE';
export const GET_PRESERVATIVES = 'GET_PRESERVATIVES';
export const GET_PRESERVATIVES_SUCCESS = 'GET_PRESERVATIVES_SUCCESS';
export const GET_PRESERVATIVES_FAILURE = 'GET_PRESERVATIVES_FAILURE';
export const GET_SPECIES = 'GET_SPECIES';
export const GET_SPECIES_SUCCESS = 'GET_SPECIES_SUCCESS';
export const GET_SPECIES_FAILURE = 'GET_SPECIES_FAILURE';
export const GET_SPECIMENS = 'GET_SPECIMENS';
export const GET_SPECIMENS_SUCCESS = 'GET_SPECIMENS_SUCCESS';
export const GET_SPECIMENS_FAILURE = 'GET_SPECIMENS_FAILURE';
export const SET_DESEASE_TYPE = 'SET_DESEASE_TYPE';

export type TDeseaseType =
  | 'methods'
  | 'preservatives'
  | 'species'
  | 'specimens';

export interface Desease {
  id: string;
  created_at: string;
  updated_at: string;
  name: string;
}

export interface Deseases {
  count: number;
  next: string | null;
  previous: string | null;
  results: Desease[];
}

export type DeseaseType = 'AI' | 'Anthrax' | 'Brucella' | 'Rabies';

interface GetMethodSuccess {
  type: typeof GET_METHODS_SUCCESS;
  payload: Deseases;
}

interface GetMethodFailure {
  type: typeof GET_METHODS_FAILURE;
  payload: {
    error: string;
  };
}

interface GetPreservativesSuccess {
  type: typeof GET_PRESERVATIVES_SUCCESS;
  payload: Deseases;
}

interface GetPreservativesFailure {
  type: typeof GET_PRESERVATIVES_FAILURE;
  payload: {
    error: string;
  };
}

interface GetSpeciesSuccess {
  type: typeof GET_SPECIES_SUCCESS;
  payload: Deseases;
}

interface GetSpeciesFailure {
  type: typeof GET_SPECIES_FAILURE;
  payload: {
    error: string;
  };
}

interface GetSpecimensSuccess {
  type: typeof GET_SPECIMENS_SUCCESS;
  payload: Deseases;
}

interface GetSpecimensFailure {
  type: typeof GET_SPECIMENS_FAILURE;
  payload: {
    error: string;
  };
}

interface SetDeseaseType {
  type: typeof SET_DESEASE_TYPE;
  payload: DeseaseType;
}

export type DeseasesAction =
  | GetMethodSuccess
  | GetMethodFailure
  | GetPreservativesSuccess
  | GetPreservativesFailure
  | GetSpeciesSuccess
  | GetSpeciesFailure
  | GetSpecimensSuccess
  | GetSpecimensFailure
  | SetDeseaseType;
