import css from '@styled-system/css';
import Box, { BoxStyleProps } from 'components/atoms/Box';
import Tooltips from 'components/atoms/Tooltips';
import { Caption } from 'components/atoms/Typography';
import React from 'react';
import styled from 'styled-components';

export type IFieldProps = BoxStyleProps & {
  label?: string;
  error?: string;
  tooltips?: string;
};

const FieldStyle = styled(Box)<BoxStyleProps>(
  css({
    display: 'block',
    marginBottom: 'medium',
  })
);

const Field: React.FC<IFieldProps> = ({
  label,
  error,
  tooltips,
  children,
  ...rest
}) => {
  return (
    <FieldStyle as="label" {...rest}>
      {label && tooltips && (
        <Tooltips text={tooltips}>
          <Caption marginBottom="small">{label}</Caption>
        </Tooltips>
      )}

      {label && !tooltips && <Caption marginBottom="small">{label}</Caption>}

      {children}

      {error && (
        <Caption color="error" marginY="small">
          *) {error}
        </Caption>
      )}
    </FieldStyle>
  );
};

export default Field;
