import React from 'react';
import HeadingStyle, {
  HeadingStyleProps,
  HeadingVariantProps,
} from './heading.style';

export type HeadingProps = HeadingStyleProps & {
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  variant?: HeadingVariantProps;
  children: React.ReactNode;
};

const Heading: React.FC<HeadingProps> = ({
  as = 'h1',
  children,
  variant = 'title',
  ...rest
}) => (
  <HeadingStyle as={as} variant={variant} {...rest}>
    {children}
  </HeadingStyle>
);

export default Heading;
