import React from 'react';
import css from '@styled-system/css';
import Flex from 'components/atoms/Flex';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { AppState } from 'redux/store';
import { IModalState } from 'redux/global/globalTypes';
import useClickOutside from 'hooks/useClickOutside';
import { useCallback } from 'react';
import { setModalClose } from 'redux/global/globalAction';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Box from 'components/atoms/Box';
import Heading from 'components/atoms/Heading';
import { Paragraph } from 'components/atoms/Typography';

const ModalStyle = styled(Flex)(
  css({
    position: 'fixed',
    justifyContent: 'center',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background: 'rgba(0,0,0,0.3)',
    zIndex: 100,
  })
);

const CardModal = styled.div(
  css({
    position: 'relative',
    width: ['100%', '450px'],
    margin: 'large',
    top: '25%',
    height: 'fit-content',
    backgroundColor: 'background',
    borderRadius: 'medium',
  })
);

const Modal: React.FC = () => {
  const dispatch = useDispatch();
  const wrapper = useRef<HTMLDivElement>(null);

  const {
    isOpen,
    title,
    description,
    action,
    actionText,
    secondAction,
    secondActionText,
  } = useSelector<AppState, IModalState>(({ global }) => global.modal);

  const closeModal = useCallback(() => {
    if (isOpen) {
      dispatch(setModalClose());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useClickOutside(wrapper, () => closeModal());

  return (
    <ModalStyle>
      <CardModal ref={wrapper}>
        {secondAction && (
          <Box right="normal" top="normal" position="absolute">
            <Button
              variant="secondary"
              size="20px"
              minHeight="20px !important"
              minWidth="20px !important"
              padding="small"
              rounded={true}
              onClick={closeModal}
            >
              <Icon fontSize="small">close</Icon>
            </Button>
          </Box>
        )}

        <Box marginRight="large" padding="large">
          <Heading>{title}</Heading>
          {typeof description === 'string' ? (
            <Paragraph>{description}</Paragraph>
          ) : (
            description
          )}
        </Box>

        <Flex
          justifyContent="flex-end"
          alignItems="center"
          gapX="normal"
          padding="medium"
          paddingTop="none"
        >
          {(action || actionText) && (
            <Button
              variant="primary"
              size="auto"
              paddingY="medium"
              onClick={() => {
                if (action) {
                  action();
                }
                closeModal();
              }}
            >
              {actionText}
            </Button>
          )}

          {secondAction && (
            <Button
              variant="transparent"
              size="auto"
              paddingY="medium"
              border="thin"
              borderColor="secondaryLighten"
              onClick={closeModal}
            >
              {secondActionText}
            </Button>
          )}
        </Flex>
      </CardModal>
    </ModalStyle>
  );
};

export default Modal;
