import Input from 'components/atoms/Input';
import { ControllerRenderProps } from 'react-hook-form';
import ReactSelect from 'react-select';
import { IKeyFieldProps } from 'redux/group/groupTypes';

const OptionalInput: React.FC<{
  data: IKeyFieldProps;
  field: ControllerRenderProps<Record<string, any>, string>;
  error: boolean;
}> = ({ data, field: { value, ...field }, error = false }) => {
  switch (data.type) {
    case 'select':
      const _options = data.option?.map((result) => ({
        value: result,
        label: result,
      }));

      return (
        <ReactSelect
          {...field}
          options={_options}
          placeholder={data.name}
          defaultValue={_options![0]}
          onChange={(e) => field.onChange(e?.value)}
        />
      );

    case 'textarea':
      return (
        <Input
          {...field}
          as="textarea"
          error={error}
          placeholder={data.name}
          defaultValue={value}
        />
      );

    default:
      return (
        <Input
          {...field}
          type={data.type}
          error={error}
          defaultValue={value}
          placeholder={data.name}
        />
      );
  }
};

export default OptionalInput;
