import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

const size = {
  mobile: 768,
  desktop: 992,
  wideScreen: 1200,
};

const useScreeType = () => {
  const isMobile = useMediaQuery({ maxWidth: size.mobile });
  const isDekstop = useMediaQuery({
    minWidth: size.mobile,
    maxWidth: size.desktop,
  });
  const isWideScreen = useMediaQuery({ minWidth: size.wideScreen });

  const [media, setMedia] = useState<string>('widescreen');

  useEffect(() => {
    if (isWideScreen) {
      setMedia('wideScreen');
    }

    if (isDekstop) {
      setMedia('dekstop');
    }

    if (isMobile) {
      setMedia('mobile');
    }

    return () => {
      setMedia('wideScreen');
    };
  }, [isMobile, isDekstop, isWideScreen]);

  return media;
};

export default useScreeType;
