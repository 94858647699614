import { ApiAction, API_END, API_START } from './apiTypes';

export interface ApiState {
  isLoadingData: boolean;
}

const initialState: ApiState = {
  isLoadingData: false,
};

const apiReducer = (
  state: ApiState = initialState,
  action: ApiAction
): ApiState => {
  // console.log('action type => ', action.type);

  switch (action.type) {
    case API_START:
      return {
        ...state,
        isLoadingData: true,
      };

    case API_END:
      return {
        ...state,
        isLoadingData: false,
      };

    default:
      return state;
  }
};

export default apiReducer;
