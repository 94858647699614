import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Spinner from 'components/atoms/Spinner';
import { Caption } from 'components/atoms/Typography';
import Field from 'components/molecules/Field';
import PopUpView from 'components/organisms/PopUpView';
import { PHONE_NUMBER_REGEX } from 'configs/constant';
import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { ApiState } from 'redux/api/apiReducer';
import { AppState } from 'redux/store';
import {
  getProfile,
  IUpdateProfile,
  updateProfile,
} from 'redux/user/userAction';
import { UserState } from 'redux/user/userReducer';
import { TInstitute } from 'redux/user/userTypes';

const instituteOption: { value: TInstitute; label: string }[] = [
  { value: 'Clinic', label: 'Klinik' },
  { value: 'University', label: 'Universitas' },
  { value: 'Laboratory', label: 'Laboratorium' },
];

const Profile: React.FC = () => {
  const dispatch = useDispatch();

  const token = localStorage.getItem('token');

  const { userById, errorGetUserById, errorUpdateUser } = useSelector<
    AppState,
    UserState
  >((state) => state.user);

  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  const { handleSubmit, control, setValue } = useForm<IUpdateProfile>();

  // Set Value Form When Get Profile Success
  useEffect(() => {
    if (userById) {
      setValue('telephone', userById.telephone!);
      setValue('institute', userById.institute!);
      setValue('inst_name', userById.inst_name!);
      setValue('address', userById.address!);
    }
  });

  // Handel Submit for Update Profile
  const onSubmit: SubmitHandler<IUpdateProfile> = useCallback(
    (data) => {
      dispatch(updateProfile(token, data));
    },
    [token]
  );

  useEffect(() => {
    dispatch(getProfile(token));
  }, [token]);

  return (
    <PopUpView title="Profil" width={['', '500px']}>
      <Box paddingX="medium" paddingY="large">
        {isLoadingData && (
          <Spinner
            spinnerSize={50}
            position="absolute"
            backgroundColor="#ffffff80"
            top="0"
            left="0"
            right="0"
            bottom="0"
            borderRadius="8px"
          />
        )}

        <Box
          gapY="normal"
          border="thin"
          borderColor="secondaryLighten"
          borderRadius="8px"
          padding="large"
        >
          <Field label="Email">
            <Input disabled={true} value={userById?.user.email} />
          </Field>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="telephone"
              rules={{
                required: {
                  value: userById?.telephone ? true : false,
                  message: 'Kolom ini tidak boleh kosong !',
                },
                pattern: {
                  value: PHONE_NUMBER_REGEX,
                  message: 'Masukan nomor telepon dengan benar',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Field label="No Telepon" error={error?.message}>
                  <Input
                    {...field}
                    error={!!error}
                    placeholder="Masukan Nomor Telepon ..."
                  />
                </Field>
              )}
            />

            <Controller
              control={control}
              name="institute"
              rules={{
                required: {
                  value: userById?.institute ? true : false,
                  message: 'Kolom ini tidak boleh kosong !',
                },
              }}
              render={({
                field: { onChange, value, ...rest },
                fieldState: { error },
              }) => (
                <Field label="Institusi" error={error?.message}>
                  <Select
                    options={instituteOption}
                    isSearchable={false}
                    placeholder="Pilih Institusi..."
                    onChange={(e) => onChange(e?.value)}
                    value={instituteOption.find(
                      (result) => result.value === value
                    )}
                    {...rest}
                  />
                </Field>
              )}
            />

            <Controller
              control={control}
              name="inst_name"
              rules={{
                required: {
                  value: userById?.inst_name ? true : false,
                  message: 'Kolom ini tidak boleh kosong !',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Field label="Nama Institusi" error={error?.message}>
                  <Input
                    {...field}
                    error={!!error}
                    placeholder="Masukan Nama Institusi ..."
                  />
                </Field>
              )}
            />

            <Controller
              control={control}
              name="address"
              rules={{
                required: {
                  value: userById?.address ? true : false,
                  message: 'Kolom ini tidak boleh kosong !',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <Field label="Alamat Lengkap" error={error?.message}>
                  <Input
                    {...field}
                    error={!!error}
                    placeholder="Masukan Alamat Lengkap ..."
                  />
                </Field>
              )}
            />

            {(errorGetUserById || !errorUpdateUser) && (
              <Caption variant="error">
                {errorGetUserById}
                {errorUpdateUser}
              </Caption>
            )}

            <Button type="submit" width="auto" marginTop="large">
              Update Profil
            </Button>
          </form>
        </Box>
      </Box>
    </PopUpView>
  );
};

export default Profile;
