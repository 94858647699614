import { DateFilter } from 'components/molecules/DateRangePicker';
import { API_ENDPOINT } from 'configs/constant';
import fetchApi from 'redux/api/apiAction';
import { TFetchToken } from 'redux/api/apiTypes';
import {
  ADD_BVET_FEEDBACK,
  ADD_BVET_FEEDBACK_FAILURE,
  ADD_BVET_FEEDBACK_SUCCESS,
  ADD_FEEDBACK,
  ADD_FEEDBACK_FAILURE,
  ADD_FEEDBACK_SUCCESS,
  BVETFeedback,
  BVETFeedbacks,
  Feedback,
  GET_BVET_FEEDBACK,
  GET_BVET_FEEDBACK_FAILURE,
  GET_BVET_FEEDBACK_SUCCESS,
  GlobalAction,
  IModalState,
  IS_DRAWER_OPEN,
  IS_FEEDBACK_FORM_OPEN,
  SET_MODAL_CLOSE,
  SET_MODAL_OPEN,
  SET_YEAR,
} from './globalTypes';

export const setIsDrawerOpen = (payload: boolean): GlobalAction => ({
  type: IS_DRAWER_OPEN,
  payload,
});

export const setModalOpen = (payload: IModalState): GlobalAction => ({
  type: SET_MODAL_OPEN,
  payload,
});

export const setModalClose = (): GlobalAction => ({
  type: SET_MODAL_CLOSE,
});

export const setYear = (payload: number): GlobalAction => ({
  type: SET_YEAR,
  payload,
});

export const setIsFeedbackFormOpen = (payload: boolean): GlobalAction => ({
  type: IS_FEEDBACK_FORM_OPEN,
  payload,
});

export const addFeedback = (
  token: TFetchToken,
  payload: Feedback,
  action: () => void
) => {
  console.log('ADD FEEDBACK ACTION PAYLOAD : ', payload);

  return fetchApi({
    url: `${API_ENDPOINT}feedbacks/platform/`,
    method: 'POST',
    label: ADD_FEEDBACK,
    onSuccess: (result: Feedback) => addFeedbackSuccess(result, action),
    onFailure: addFeedbackFailure,
    data: payload,
    accessToken: token,
  });
};

const addFeedbackSuccess = (
  payload: Feedback,
  action?: () => void
): GlobalAction => {
  console.log('ADD FEEDBACK SUCCESS : ', payload);

  if (action) {
    action();
  }

  return {
    type: ADD_FEEDBACK_SUCCESS,
    payload,
  };
};

export const addFeedbackFailure = ({ response }: any): GlobalAction => {
  console.log('ADD FEEDBACK FAILURE : ', response);

  let errorMessage = 'Oops somethin wrong !';

  if (response?.data?.data?.error) {
    errorMessage = response.data.data.error;
  }

  return {
    type: ADD_FEEDBACK_FAILURE,
    payload: {
      error: errorMessage,
    },
  };
};

export const GetFeedbackPublics = (
  page = 1,
  dateFilter?: DateFilter,
  action?: () => void
) => {
  console.log('GET FEEDBACK BVET ACTION PAYLOAD : ');

  return fetchApi({
    url: `${API_ENDPOINT}feedbacks/public`,
    data: {
      start_date: dateFilter?.start_date.toISOString().substr(0, 10),
      end_date: dateFilter?.end_date.toISOString().substr(0, 10),
    },
    label: GET_BVET_FEEDBACK,
    onSuccess: (result: BVETFeedbacks) =>
      getBVETFeedbackSuccess(result, action),
    onFailure: getBVETFeedbackFailure,
  });
};

const getBVETFeedbackSuccess = (
  payload: BVETFeedbacks,
  action?: () => void
): GlobalAction => {
  console.log('ADD BVET FEEDBACK SUCCESS : ', payload);

  if (action) {
    action();
  }

  return {
    type: GET_BVET_FEEDBACK_SUCCESS,
    payload,
  };
};

export const getBVETFeedbackFailure = ({ response }: any): GlobalAction => {
  console.log('GET BVET Feedback FAILURE : ', response);

  let errorMessage = 'Oops somethin wrong !';

  if (response?.data?.data?.error) {
    errorMessage = response.data.data.error;
  }

  return {
    type: GET_BVET_FEEDBACK_FAILURE,
    payload: {
      error: errorMessage,
    },
  };
};

export const addBVETFeedback = (payload: BVETFeedback, action: () => void) => {
  console.log('ADD FEEDBACK BVET ACTION PAYLOAD : ', payload);

  return fetchApi({
    url: `${API_ENDPOINT}feedbacks/public/`,
    method: 'POST',
    label: ADD_BVET_FEEDBACK,
    onSuccess: (result: BVETFeedback) => addBVETFeedbackSuccess(result, action),
    onFailure: addBVETFeedbackFailure,
    data: payload,
  });
};

const addBVETFeedbackSuccess = (
  payload: BVETFeedback,
  action?: () => void
): GlobalAction => {
  console.log('ADD BVET FEEDBACK SUCCESS : ', payload);

  if (action) {
    action();
  }

  return {
    type: ADD_BVET_FEEDBACK_SUCCESS,
    payload,
  };
};

export const addBVETFeedbackFailure = ({ response }: any): GlobalAction => {
  console.log('ADD BVET Feedback FAILURE : ', response);

  let errorMessage = 'Oops somethin wrong !';

  if (response?.data?.data?.error) {
    errorMessage = response.data.data.error;
  }

  return {
    type: ADD_BVET_FEEDBACK_FAILURE,
    payload: {
      error: errorMessage,
    },
  };
};
