import Modal from 'components/organisms/Modal';
import { useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { IModalState } from 'redux/global/globalTypes';
import { AppState } from 'redux/store';
import PrivateRoute from 'routes/PrivateRoute';
import PublicRoute from 'routes/PublicRoute';
import routes from 'routes/routes';

function App() {
  const { isOpen } = useSelector<AppState, IModalState>(
    ({ global }) => global.modal
  );

  return (
    <BrowserRouter>
      <Switch>
        {routes.private.map((result) => (
          <PrivateRoute key={result.path} {...result} />
        ))}

        {routes.public.map((result) => (
          <PublicRoute key={result.path} {...result} />
        ))}

        {routes.default.map((result) => (
          <Route key={result.path} {...result} />
        ))}
      </Switch>

      {isOpen && <Modal />}
    </BrowserRouter>
  );
}

export default App;
