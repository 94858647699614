import { LatLngExpression } from 'leaflet';
import {
  IMapLocationState,
  MapActionTypes,
  SET_ANOTATION_LOCATION,
  SET_HIDE_INPUT,
  SET_HIDE_TIPS,
  SET_LOCATION,
  SET_SHOW_INPUT,
  SET_SHOW_TIPS,
} from './mapTypes';

export const setLocation = (payload: IMapLocationState): MapActionTypes => ({
  type: SET_LOCATION,
  payload,
});

export const setAnotationLocation = (
  payload: LatLngExpression
): MapActionTypes => ({
  type: SET_ANOTATION_LOCATION,
  payload,
});

export const resetAnotationLocation = (): MapActionTypes => ({
  type: SET_ANOTATION_LOCATION,
  payload: null,
});

export const setShowTips = (): MapActionTypes => ({
  type: SET_SHOW_TIPS,
});

export const setHideTips = (): MapActionTypes => ({
  type: SET_HIDE_TIPS,
});

export const setShowInput = (): MapActionTypes => ({
  type: SET_SHOW_INPUT,
});
export const setHideInput = (): MapActionTypes => ({
  type: SET_HIDE_INPUT,
});
