import {
  ADD_GROUP_FAILLURE,
  DELETE_GROUP_FAILURE,
  GET_GROUPS_FAILURE,
  GET_GROUPS_SUCCESS,
  GET_GROUP_ACTIVITIES_FAILURE,
  GET_GROUP_ACTIVITIES_SUCCESS,
  GET_GROUP_BY_ID_FAILURE,
  GET_GROUP_BY_ID_SUCCESS,
  GET_GROUP_KEY_FAILURE,
  GET_GROUP_KEY_SUCCESS,
  GET_INVITE_BY_LINK_GROUP_FAILURE,
  Group,
  GroupActivities,
  Groups,
  GroupsAction,
  IKeyFieldProps,
  RESET_GROUP_KEY,
  UPDATE_GROUP_FAILURE,
} from './groupTypes';

export interface GroupState {
  groups: Groups;
  groupById: Group | null;
  groupKeys: IKeyFieldProps[] | null;
  groupActivities: GroupActivities | null;
  errorGetGroups: string | null;
  errorGetGroupById: string | null;
  errorGroupKey: string | null;
  errorAddGroup: string | null;
  errorUpdateGroup: string | null;
  errorDeleteGroup: string | null;
  errorGetInviteByLink: string | null;
  errorGetGroupActivities: string | null;
}

const initialState: GroupState = {
  groups: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  groupKeys: [],
  groupById: null,
  groupActivities: null,
  errorGetGroups: null,
  errorGetGroupById: null,
  errorGroupKey: null,
  errorAddGroup: null,
  errorUpdateGroup: null,
  errorDeleteGroup: null,
  errorGetInviteByLink: null,
  errorGetGroupActivities: null,
};

const groupReducer = (
  state = initialState,
  action: GroupsAction
): GroupState => {
  switch (action.type) {
    case GET_GROUPS_SUCCESS:
      return {
        ...state,
        groups: action.payload,
        errorGetGroups: null,
        errorDeleteGroup: null,
      };

    case GET_GROUPS_FAILURE:
      return {
        ...state,
        errorGetGroups: action.payload.error,
      };

    case GET_GROUP_BY_ID_SUCCESS:
      return {
        ...state,
        groupById: action.payload,
        errorGetGroupById: null,
        errorGetGroups: null,
        errorAddGroup: null,
        errorDeleteGroup: null,
        errorGetInviteByLink: null,
      };
    case GET_GROUP_BY_ID_FAILURE:
      return {
        ...state,
        groupById: initialState.groupById,
        errorGetGroupById: action.payload.error,
      };

    case GET_GROUP_KEY_SUCCESS:
      return {
        ...state,
        groupKeys: action.payload,
        errorGroupKey: null,
      };

    case GET_GROUP_KEY_FAILURE:
      return {
        ...state,
        errorGroupKey: action.payload.error,
      };

    case RESET_GROUP_KEY:
      return {
        ...state,
        groupKeys: initialState.groupKeys,
      };

    case ADD_GROUP_FAILLURE:
      return {
        ...state,
        errorAddGroup: action.payload.error,
      };

    case UPDATE_GROUP_FAILURE:
      return {
        ...state,
        errorUpdateGroup: action.payload.error,
      };

    case DELETE_GROUP_FAILURE:
      return {
        ...state,
        errorDeleteGroup: action.payload.error,
      };

    case GET_INVITE_BY_LINK_GROUP_FAILURE:
      return {
        ...state,
        errorGetInviteByLink: action.payload.error,
      };

    case GET_GROUP_ACTIVITIES_SUCCESS:
      return {
        ...state,
        groupActivities: action.payload,
        errorGetGroupActivities: null,
      };

    case GET_GROUP_ACTIVITIES_FAILURE:
      return {
        ...state,
        errorGetGroupActivities: action.payload.error,
        groupActivities: null,
      };

    default:
      return state;
  }
};

export default groupReducer;
