import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import { Caption } from 'components/atoms/Typography';
import CardList from 'components/molecules/CardList';
import useCheckLocationPermisison from 'hooks/useCheckLocationPermisison';
import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setHideInput, setHideTips, setShowTips } from 'redux/map/mapAction';

const AnotationSetLocation: React.FC = () => {
  const dispatch = useDispatch();
  const { getLocationPermission } = useCheckLocationPermisison();
  const { goBack } = useHistory();

  const handleSetLocationByMap = useCallback(() => {
    dispatch(setShowTips());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setHideInput());
      dispatch(setHideTips());
    };
  }, []);

  return (
    <Heading as="h2" marginBottom="normal">
      <Button
        onClick={goBack}
        paddingX="2px"
        marginY="normal"
        variant="transparent"
        width="auto"
        minHeight="0 !important"
      >
        <Icon fontSize="paragraph" marginRight="small">
          arrow_back
        </Icon>
        <Caption>Kembali</Caption>
      </Button>
      <CardList
        title="Tentukan Lokasimu"
        description=" Anda dapat memilih untuk memilih lokasi saat ini atau memilih dari peta"
        icon="map"
      >
        <Button
          variant="transparent"
          border="thin"
          borderColor="secondaryLighten"
          onClick={handleSetLocationByMap}
        >
          Tentukan Lokasi dari Peta
        </Button>

        <Button
          variant="primary"
          marginTop="large"
          onClick={() => getLocationPermission(goBack)}
        >
          Berdasarkan Lokasimu
        </Button>
      </CardList>
    </Heading>
  );
};

export default AnotationSetLocation;
