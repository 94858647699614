import { URL_KESMAVET } from 'configs/constant';
import { useEffect } from 'react';

const createHost = require('cross-domain-storage/host');

const storageHost = createHost([
  {
    origin: URL_KESMAVET,
    allowedMethods: ['get', 'set', 'remove'],
  },
  {
    origin: URL_KESMAVET,
    allowedMethods: ['get', 'set', 'remove'],
  },
]);

const GetLocalStorage = () => {
  useEffect(() => {
    return storageHost;
  }, []);

  return null;
};

export default GetLocalStorage;
