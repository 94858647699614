import React from 'react';
import ButtonStyle, {
  ButtonStyleProps,
  ButtonVariantProps,
} from './button.style';

export type ButtonProps = ButtonStyleProps &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    as?: React.ElementType | keyof JSX.IntrinsicElements;
    variant?: ButtonVariantProps;
    children?: React.ReactNode;
  };

const Button: React.ForwardRefRenderFunction<unknown, ButtonProps> = (
  { as = 'button', variant = 'primary', rounded = false, children, ...rest },
  ref
) => {
  return (
    <ButtonStyle
      as={as}
      variant={variant}
      ref={ref as React.MutableRefObject<HTMLButtonElement>}
      rounded={rounded}
      {...rest}
    >
      {children}
    </ButtonStyle>
  );
};

export default React.forwardRef<unknown, ButtonProps>(Button);
