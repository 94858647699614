import React from 'react';
import css from '@styled-system/css';
import Box, { BoxStyleProps } from 'components/atoms/Box';
import Brand from 'components/atoms/Brand';
import Button from 'components/atoms/Button';
import Flex from 'components/atoms/Flex';
import ListLink from 'components/molecules/ListLink';
import styled from 'styled-components';
import Footer from '../../molecules/Footer';

export type DrawerStyleProps = BoxStyleProps & { isOpen: boolean };
export type DrawerRoutesProps = {
  path: string;
  text: string;
  iconLeft?: string;
  iconRight?: string;
  bottom?: boolean;
}[];

export type DrawerProps = DrawerStyleProps & {
  setIsOpen: (event: any, value?: boolean) => void;
  routes: DrawerRoutesProps;
};

const DrawerStyle = styled(Box)<DrawerStyleProps>`
  ${css({
    height: '100%',
    backgroundColor: 'muted',
    top: 0,
    zIndex: 20,
    boxShadow: ['large', 'none'],
    position: ['fixed', 'relative'],
    overflow: ['auto', 'hidden'],
    paddingBottom: '47px',
  })};

  ${({ isOpen }) =>
    isOpen
      ? css({
          left: 0,
          width: ['200px', '200px'],
        })
      : css({
          left: ['-200px', 0],
          width: ['200px', '56px'],
        })}
`;

const Drawer: React.FC<DrawerProps> = ({
  isOpen,
  setIsOpen,
  routes,
  ...rest
}) => {
  return (
    <DrawerStyle isOpen={isOpen} {...rest}>
      <Flex
        height="64px"
        alignItems="center"
        justifyContent="center"
        borderBottom="thin"
        borderColor="secondary"
      >
        <Button variant="transparent" onClick={setIsOpen}>
          <Brand
            type={!isOpen ? 'icon' : 'text'}
            width={!isOpen ? '40px' : '150px'}
          />
        </Button>
      </Flex>

      <Flex
        as="nav"
        justifyContent="space-between"
        flexDirection="column"
        height="calc(100% - 64px)"
      >
        <Box>
          {routes &&
            routes
              .filter((result) => !result.bottom)
              .map((route) => (
                <ListLink
                  key={route.path}
                  to={route.path}
                  action={(event: any) => setIsOpen(event, false)}
                  iconLeft={route.iconLeft}
                  iconRight={route.iconRight}
                >
                  {route.text}
                </ListLink>
              ))}
        </Box>

        <Box borderY="thin" borderColor="secondary" marginTop="medium">
          {routes &&
            routes
              .filter((result) => result.bottom)
              .map((route) => (
                <ListLink
                  key={route.path}
                  to={route.path}
                  action={(event: any) => setIsOpen(event, false)}
                  iconLeft={route.iconLeft}
                  iconRight={route.iconRight}
                >
                  {route.text}
                </ListLink>
              ))}
        </Box>
      </Flex>

      {isOpen && <Footer />}
    </DrawerStyle>
  );
};

export default Drawer;
