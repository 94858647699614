import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Switch from 'components/atoms/Switch';
import Input from 'components/atoms/Input';
import Spinner from 'components/atoms/Spinner';
import { Caption } from 'components/atoms/Typography';
import Field from 'components/molecules/Field';
import PopUpView from 'components/organisms/PopUpView';
import React from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ApiState } from 'redux/api/apiReducer';
import { getGroupById, updateGroup } from 'redux/group/groupAction';
import { GroupState } from 'redux/group/groupReducer';
import { Group, GroupUpdateProps } from 'redux/group/groupTypes';
import { AppState } from 'redux/store';

const GroupUpdate: React.FC = () => {
  const dispatch = useDispatch();
  const {
    goBack,
    location: { state: groupState },
  }: {
    goBack: any;
    location: {
      state: Group;
    };
  } = useHistory();

  const { groupId }: any = useParams();

  const { groupById, errorUpdateGroup } = useSelector<AppState, GroupState>(
    ({ group }) => group
  );

  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  const token = localStorage.getItem('token');

  const { handleSubmit, control } = useForm<GroupUpdateProps>({
    defaultValues: {
      name: groupState.name || groupById?.name || '',
      description: groupState.description || groupById?.description || '',
      is_public: groupState.is_public || groupById?.is_public || false,
    },
  });

  const onSubmit: SubmitHandler<GroupUpdateProps> = useCallback(
    (data) => {
      dispatch(updateGroup(token, groupId, data, goBack));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupId, token]
  );

  useEffect(() => {
    if (!groupState) {
      dispatch(getGroupById(token, groupId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, groupState, token]);

  return (
    <PopUpView width={['', '500px']} title={`Update ${groupById?.name}`}>
      <Box paddingX="large" paddingY="large" gapY="large">
        {isLoadingData && (
          <Spinner
            spinnerSize={50}
            position="absolute"
            backgroundColor="#ffffff80"
            top="0"
            left="0"
            right="0"
            bottom="0"
          />
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: 'Kolom ini tidak boleh kosong !',
              minLength: {
                value: 8,
                message: 'Minimal 8 karakter',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <Field label="Nama Proyek" error={error?.message}>
                <Input {...field} error={!!error} placeholder="Nama Proyek" />
              </Field>
            )}
          />

          <Controller
            name="description"
            control={control}
            rules={{ required: 'Kolom ini tidak boleh kosong !' }}
            render={({ field, fieldState: { error } }) => (
              <Field label="Deskripsi Proyek" error={error?.message}>
                <Input
                  {...field}
                  as="textarea"
                  rows={3}
                  error={!!error}
                  placeholder="Deskripsi Proyek"
                />
              </Field>
            )}
          />

          <Field label="Publik ?">
            <Controller
              name="is_public"
              control={control}
              render={({ field: { name, onBlur, value, onChange, ref } }) => (
                <Switch
                  name={name}
                  defaultChecked={value}
                  onBlur={onBlur}
                  onChange={onChange}
                  ref={ref}
                />
              )}
            />
          </Field>

          {errorUpdateGroup && (
            <Caption color="error">{errorUpdateGroup}</Caption>
          )}

          <Button
            marginTop="large"
            as="input"
            type="submit"
            value="Update Proyek"
          />
        </form>
      </Box>
    </PopUpView>
  );
};

export default GroupUpdate;
