import { DefaultTheme } from 'styled-components';
import {
  borders,
  BorderAlias,
  BreakpointAlias,
  breakpoints,
  ColorAlias,
  colors,
  fontFamily,
  FontFamilyAlias,
  FontSizeAlias,
  fontSizes,
  FontWeightAlias,
  fontWeights,
  LineHeightAlias,
  lineHeights,
  SizeAlias,
  space,
  ThemeScale,
  BorderRadiusAlias,
  borderRadius,
  boxShadow,
  SpaceAlias,
  mediaQueries,
} from './config';

// Type Theme Declaration
declare module 'styled-components' {
  export interface DefaultTheme {
    colors: { [key in ColorAlias]: string };
    breakpoints: ThemeScale<string, BreakpointAlias>;
    mediaQueries: { [key in BreakpointAlias]: string };
    space: ThemeScale<string, SpaceAlias>;
    fontFamily: { [key in FontFamilyAlias]: string };
    fontSizes: ThemeScale<string, FontSizeAlias>;
    fontWeights: ThemeScale<number, FontWeightAlias>;
    lineHeights: ThemeScale<number, LineHeightAlias>;
    borders: ThemeScale<string, BorderAlias>;
    borderRadius: ThemeScale<string, BorderRadiusAlias>;
    boxShadow: ThemeScale<string, SizeAlias>;
  }
}

// Theme Configuration
const theme: DefaultTheme = {
  colors,
  breakpoints,
  mediaQueries,
  space,
  fontFamily,
  fontSizes,
  fontWeights,
  lineHeights,
  borders,
  borderRadius,
  boxShadow,
};

// Breakpoint Aliases
[
  theme.breakpoints.mobile,
  theme.breakpoints.dekstop,
  theme.breakpoints.wideScreen,
] = theme.breakpoints;

// Space Aliases
theme.space.none = theme.space[0];
theme.space.small = theme.space[1];
theme.space.normal = theme.space[2];
theme.space.medium = theme.space[3];
theme.space.large = theme.space[4];

// FontSize Aliases
[
  theme.fontSizes.caption,
  theme.fontSizes.paragraph,
  theme.fontSizes.title,
  theme.fontSizes.heading,
  theme.fontSizes.brand,
] = theme.fontSizes;

// FontWeight Aliases
[theme.fontWeights.thin, theme.fontWeights.regular, theme.fontWeights.bold] =
  theme.fontWeights;

// LineHeight Aliases
[theme.lineHeights.body, theme.lineHeights.heading] = theme.lineHeights;

// Border Aliases
[theme.borders.thin, theme.borders.bold] = theme.borders;

// BorderRadius Aliases
[
  theme.borderRadius.small,
  theme.borderRadius.medium,
  theme.borderRadius.large,
  theme.borderRadius.rounded,
] = theme.borderRadius;

// BoxShadow Aliases
[theme.boxShadow.small, theme.boxShadow.medium, theme.boxShadow.large] =
  theme.boxShadow;

export default theme;
