import { createGlobalStyle } from 'styled-components';
import graphikRegular from 'assets/fonts/GraphikLCWeb-Regular.woff';
import graphikMedium from 'assets/fonts/GraphikLCWeb-Medium.woff';
import graphikBold from 'assets/fonts/GraphikLCWeb-Bold.woff';

export const GlobalStyles = createGlobalStyle`
  @font-face{
    font-family:"Graphik";
    src:url(${graphikRegular}) format("woff");
    font-weight:${({ theme }) => theme.fontWeights.thin};
    font-style:normal;
    font-display:swap
  }

  @font-face{font-family:"Graphik";
    src:url(${graphikMedium}) format("woff");
    font-weight:${({ theme }) => theme.fontWeights.regular};
    font-style:normal;
    font-display:swap
  }

  @font-face{font-family:"Graphik";
    src:url(${graphikBold}) format("woff");
    font-weight:${({ theme }) => theme.fontWeights.bold};
    font-style:normal;
    font-display:swap
  }

  body {
    margin: 0;
    padding: 0;
    background: ${(props) => props.theme.colors.background};
    font-family: ${({ theme }) => theme.fontFamily.primary};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
    transition: all 0.2s ease;
  }

  body, input, button, select, textarea {
    font-family: ${({ theme }) => theme.fontFamily.primary};
    outline: none;
  }

  body, main, div, section, ul {
    &::-webkit-scrollbar {
      width: 14px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #d6dee1;
      border-radius: 20px;
      border: 4px solid transparent;
      background-clip: content-box;

      &:hover {
        background-color: #a8bbbf;
      }
    }
  }

  button, a {
    cursor: pointer;
  }

  button,
  textarea,
  input[type=text],
  input[type=button],
  input[type=email],
  input[type=file],
  input[type=image],
  input[type=number],
  input[type=password],
  input[type=reset],
  input[type=submit],
  input[type=tel],
  input[type=text] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  a {
    text-decoration: none;
    color:  ${({ theme }) => theme.colors.accent};

    &:hover {
    color:  ${({ theme }) => theme.colors.primary};
    }
  }

  li {
    list-style: none;
  }

  hr {
    background-color:  ${({ theme }) => theme.colors.accent};
  }
`;
