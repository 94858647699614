import css from '@styled-system/css';
import Brand from 'components/atoms/Brand';
import Button from 'components/atoms/Button';
import Flex, { FlexStyleProps } from 'components/atoms/Flex';
import Icon from 'components/atoms/Icon';
import React from 'react';
import styled from 'styled-components';

export type HeaderStyleProps = FlexStyleProps & {
  isOpen: boolean;
  setIsOpen: React.MouseEventHandler<HTMLButtonElement>;
};

const HeaderStyle = styled(Flex)<HeaderStyleProps>(
  css({
    backgroundColor: 'background',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    width: '100%',
    height: '46px',
    paddingX: 'small',
    boxShadow: 'small',
    zIndex: 10,
  })
);

const Header: React.FC<HeaderStyleProps> = ({ isOpen, setIsOpen, ...rest }) => {
  return (
    <HeaderStyle {...rest}>
      <Brand type="icon" width="40px" marginX="normal" />
      <Button
        variant="transparent"
        width="35px"
        onClick={setIsOpen}
        rounded={true}
      >
        <Icon>{!isOpen ? 'menu' : 'close'} </Icon>
      </Button>
    </HeaderStyle>
  );
};

export default Header;
