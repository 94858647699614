import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import { Caption, Paragraph } from 'components/atoms/Typography';
import Field from 'components/molecules/Field';
import PopUpView from 'components/organisms/PopUpView';
import React, { useCallback, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch as SwitchRouter,
  useHistory,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import { IAnotationAtribute } from 'redux/anotation/anotationTypes';
import { getGroupKey, resetGroupKey } from 'redux/group/groupAction';
import { GroupState } from 'redux/group/groupReducer';
import { AppState } from 'redux/store';
import AnotationSetLocation from 'pages/Anotation/AnotationSetLocation';
import useCheckLocationPermisison from 'hooks/useCheckLocationPermisison';
import { IMapState } from 'redux/map/mapTypes';
import { ApiState } from 'redux/api/apiReducer';
import Spinner from 'components/atoms/Spinner';
import AsyncReactSelect from 'react-select/async';
import Select from 'react-select';
import { Desease } from 'redux/desease/deseaseTypes';
import {
  getMethods,
  getPreservatives,
  getSpecies,
  getSpecimens,
} from 'redux/desease/deseaseAction';
import { DeseaseState } from 'redux/desease/deseaseReducer';
import { addAnotation } from 'redux/anotation/anotationAction';
import { setModalOpen } from 'redux/global/globalAction';
import axios from 'axios';
import { API_ENDPOINT } from 'configs/constant';
import OptionalInput from 'components/molecules/OptionalInput';
import Flex from 'components/atoms/Flex';
import { SelectOptionProps } from 'hooks/SelectOption';

const getDesase = async (
  deseaseName: string,
  token: string,
  query: string = ''
) => {
  try {
    const response = await axios.get(`${API_ENDPOINT}data/${deseaseName}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
      params: {
        page: 1,
        search: query,
        page_size: 10,
      },
    });

    let data = response.data.data.results;

    return data.map((result: Desease) => ({
      value: result.id,
      label: result.name,
    }));
  } catch (error) {}
};

const AnotationForm: React.FC = () => {
  const dispatch = useDispatch();
  const { groupId }: any = useParams();
  const { url, path } = useRouteMatch();
  const { push, goBack } = useHistory();
  const { getLocationPermission } = useCheckLocationPermisison();

  const token = localStorage.getItem('token');

  const { groupKeys } = useSelector<AppState, GroupState>(({ group }) => group);
  const { location, showTips, showInput } = useSelector<AppState, IMapState>(
    ({ map }) => map
  );
  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IAnotationAtribute>({
    defaultValues: {
      namaField: '',
      data: [{}],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'data',
    control,
    keyName: 'uniqID',
  });

  // Handle Button Set Location
  const handleButtonLocationPage = useCallback(() => {
    if (window.location.pathname === `${url}`) {
      push(`${url}/setLocation`);
    } else {
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const showModalLocationNotSet = useCallback(() => {
    dispatch(
      setModalOpen({
        title: 'Lokasi belum ditentukan',
        description: 'Anda harus menentukan lokasi sebelum menginput data',
        actionText: 'Tentukan lokasi ?',
        secondActionText: 'Batal',
        action: () => getLocationPermission(),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (data: IAnotationAtribute) => {
    if (!location.lat && !location.lng) {
      return showModalLocationNotSet();
    }

    return dispatch(
      addAnotation(
        token,
        {
          features: {
            geometry: {
              coordinates: [location.lat, location.lng],
              type: 'point',
            },
          },
          attributes: {
            ...data,
          },
          group: parseFloat(groupId),
        },
        () => push(`/group/${groupId}`)
      )
    );
  };

  useEffect(() => {
    if (!location.lat || !location.lng) {
      getLocationPermission();
    }

    dispatch(getGroupKey(token, groupId));

    return () => {
      dispatch(resetGroupKey());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, groupId]);

  return (
    <PopUpView
      width={['', '400px']}
      top={
        showTips || showInput
          ? ['420px !important', '0 !important']
          : ['3px', 0]
      }
      title="Input Data"
    >
      <Box paddingX="large" paddingY="large" gapY="large">
        {isLoadingData && (
          <Spinner
            spinnerSize={50}
            position="absolute"
            backgroundColor="#ffffff80"
            top="0"
            left="0"
            right="0"
            bottom="0"
          />
        )}
        <Caption paddingX="4px">Lokasi</Caption>

        <Button
          variant="transparent"
          width="auto"
          marginBottom="small"
          paddingX="4px"
          onClick={handleButtonLocationPage}
        >
          <Heading variant="title" as="p">
            {location.description}
          </Heading>

          <Icon>arrow_drop_down</Icon>
        </Button>

        <SwitchRouter>
          <Route
            path={`${path}/setLocation`}
            component={AnotationSetLocation}
          />

          <Route exact={true} path={path}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Field label="Nama" error={errors.namaField?.message}>
                <Controller
                  name="namaField"
                  control={control}
                  rules={{ required: 'Kolom ini tidak boleh kosong !' }}
                  render={({ field, fieldState: { error } }) => (
                    <Input {...field} error={!!error} placeholder="Nama" />
                  )}
                />
              </Field>

              <Heading
                as="h2"
                marginBottom="normal"
                marginTop="large"
                paddingX="4px"
              >
                Informasi Data
              </Heading>

              {fields.map((item, index) => (
                <Box
                  key={item.uniqID}
                  px="medium"
                  py="normal"
                  mb="normal"
                  border="thin"
                  borderColor="secondary"
                  borderRadius="4px"
                  gapY="small"
                >
                  <Paragraph
                    fontWeight="regular"
                    borderBottom="thin"
                    borderColor="secondaryLighten"
                  >
                    - Data {index + 1}
                  </Paragraph>

                  {groupKeys?.map((key, keyIndex) => (
                    <Controller
                      key={`${item.uniqID}.${key.id}.${keyIndex}`}
                      control={control}
                      name={`data.${index}.${key.id}`}
                      defaultValue={
                        key.type === 'select' ? key.option && key.option[0] : ''
                      }
                      rules={{
                        required: {
                          value: key.is_required,
                          message: 'Kolom ini tidak boleh kosong !',
                        },
                      }}
                      render={({ field, fieldState: { error } }) => {
                        switch (key.type) {
                          case 'methods':
                          case 'preservatives':
                          case 'species':
                          case 'specimens':
                            return (
                              <Field label={key.name} error={error?.message}>
                                <AsyncReactSelect
                                  {...field}
                                  cacheOptions
                                  defaultOptions
                                  placeholder={`Cari ${key.name}...`}
                                  loadOptions={(inputValue) =>
                                    getDesase(key.type, token!, inputValue)
                                  }
                                  value={
                                    field.value && {
                                      label: field.value,
                                      value: field.value,
                                    }
                                  }
                                  onChange={(e) => field.onChange(e?.label)}
                                />
                              </Field>
                            );

                          default:
                            return (
                              <Field label={key.name} error={error?.message}>
                                <OptionalInput
                                  data={key}
                                  field={field}
                                  error={!!error}
                                />
                              </Field>
                            );
                        }
                      }}
                    />
                  ))}

                  <Flex justifyContent="flex-end">
                    <Button
                      type="button"
                      variant="error"
                      rounded
                      size="40px"
                      disabled={fields.length === 1}
                      onClick={() => remove(index)}
                    >
                      <Icon>delete</Icon>
                    </Button>
                  </Flex>
                </Box>
              ))}

              <Button
                variant="transparent"
                border="thin"
                borderColor="secondaryLighten"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  append({});
                }}
              >
                <Icon marginRight="normal">add</Icon> Tambah Data
              </Button>

              <Button
                marginTop="large"
                as="input"
                type="submit"
                value="Submit"
              />
            </form>
          </Route>
        </SwitchRouter>
      </Box>
    </PopUpView>
  );
};

export default AnotationForm;
