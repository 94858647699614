import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { logout } from 'redux/auth/authAction';

const Logout: React.FC<{}> = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const accessFrom = localStorage.getItem('access');

  const handleLogout = useCallback(() => {
    if (accessFrom) {
      window.location.replace(accessFrom);
      localStorage.removeItem('access');
    } else {
      push('/login');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessFrom]);

  useEffect(() => {
    dispatch(logout(handleLogout));
  }, []);

  return null;
};

export default Logout;
