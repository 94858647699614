import React from 'react';
import { BorderProps } from 'styled-system';
import {
  TyphographyVariantProps,
  TypographyStyle,
  TypographyStyleProps,
} from './typography.style';

export type TypographyProps = TypographyStyleProps &
  BorderProps &
  React.LabelHTMLAttributes<HTMLLabelElement> & {
    as?: React.ElementType | keyof JSX.IntrinsicElements;
    variant?: TyphographyVariantProps;
    children: React.ReactNode;
  };

export const Paragraph: React.FC<TypographyProps> = ({
  as = 'p',
  variant = 'body',
  truncate = false,
  children,
  ...rest
}) => (
  <TypographyStyle
    as={as}
    variant={variant}
    truncate={truncate}
    fontSize="paragraph"
    fontWeight="thin"
    lineHeight="heading"
    marginY="normal"
    {...rest}
  >
    {children}
  </TypographyStyle>
);

export const Caption: React.FC<TypographyProps> = ({
  as = 'p',
  variant = 'body',
  truncate = false,
  children,
  ...rest
}) => (
  <TypographyStyle
    as={as}
    variant={variant}
    truncate={truncate}
    fontSize="caption"
    fontWeight="thin"
    lineHeight="body"
    wordWrap="break-word"
    margin={0}
    {...rest}
  >
    {children}
  </TypographyStyle>
);
