import {
  ADD_GROUP_MEMBER_FAILURE,
  ADD_GROUP_MEMBER_SUCCESS,
  DELETE_GROUP_MEMBER_FAILURE,
  DELETE_GROUP_MEMBER_SUCCESS,
  MembersAction,
  UPDATE_GROUP_MEMBER_FAILURE,
  UPDATE_GROUP_MEMBER_SUCCESS,
} from './memberTypes';

export interface MemberState {
  errorAddGroupMember: string | null;
  errorUpdateGroupMember: string | null;
  errorDeleteGroupMember: string | null;
}

const initialState: MemberState = {
  errorAddGroupMember: null,
  errorUpdateGroupMember: null,
  errorDeleteGroupMember: null,
};

const memberReducer = (
  state = initialState,
  action: MembersAction
): MemberState => {
  switch (action.type) {
    case ADD_GROUP_MEMBER_SUCCESS:
      return {
        ...state,
        errorAddGroupMember: null,
      };

    case ADD_GROUP_MEMBER_FAILURE:
      return {
        ...state,
        errorAddGroupMember: action.payload.error,
      };

    case UPDATE_GROUP_MEMBER_SUCCESS:
      return {
        ...state,
        errorUpdateGroupMember: null,
      };

    case UPDATE_GROUP_MEMBER_FAILURE:
      return {
        ...state,
        errorUpdateGroupMember: action.payload.error,
      };

    case DELETE_GROUP_MEMBER_SUCCESS:
      return {
        ...state,
        errorDeleteGroupMember: null,
      };

    case DELETE_GROUP_MEMBER_FAILURE:
      return {
        ...state,
        errorDeleteGroupMember: null,
      };

    default:
      return state;
  }
};

export default memberReducer;
