import { ChartOptions } from 'chart.js';
import Box from 'components/atoms/Box';
import Flex from 'components/atoms/Flex';
import Grid from 'components/atoms/Grid';
import Heading from 'components/atoms/Heading';
import Spinner from 'components/atoms/Spinner';
import { Caption, Paragraph } from 'components/atoms/Typography';
import PopUpView from 'components/organisms/PopUpView';
import useScreeType from 'hooks/useScreenType';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { ApiState } from 'redux/api/apiReducer';
import { GetFeedbackPublics } from 'redux/global/globalAction';
import { GlobalState } from 'redux/global/globalReducer';
import { BVETFeedbacksResults } from 'redux/global/globalTypes';
import { AppState } from 'redux/store';
import 'react-calendar/dist/Calendar.css';
import DateRangePicker, {
  DateFilter,
} from 'components/molecules/DateRangePicker';

type OptionFilterDate = DateFilter & {
  name: string;
};

// Total Cart option data
const totalDataChartOption: ChartOptions = {
  interaction: {
    mode: 'index',
    intersect: false,
  },
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.8)',
    },
    legend: { display: false },
    title: {
      display: true,
      text: 'Jumlah Feedback',
    },
  },
};

// Column of feedback table
const columns = [
  {
    name: 'Tanggal',
    selector: (row: any) => row.created_at,
    sortable: true,
  },
  {
    name: 'Email',
    selector: (row: any) => row.email,
  },
  {
    name: 'Nama',
    selector: (row: any) => row.fullname,
  },
  {
    name: 'Telepon',
    selector: (row: any) => row.phone,
  },
  {
    name: 'Sangat Membantu',
    selector: (row: any) => row.is_helpful,
  },
  {
    name: 'Sangat Bermanfaat',
    selector: (row: any) => row.is_useful,
  },
];

// Feedback Chart Component
const FeedbackChart: React.FC<{
  screenType: string;
  data: BVETFeedbacksResults[];
  dateFilter: DateFilter;
  setDateFilter: React.Dispatch<React.SetStateAction<DateFilter>>;
}> = ({ screenType, data, dateFilter, setDateFilter, ...rest }) => {
  // Handle get start date of week
  const startOfWeek = useCallback((date: Date) => {
    const diff =
      date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

    return new Date(date.setDate(diff));
  }, []);

  // Handle get end date of week
  const endOfWeek = useCallback((date: Date) => {
    const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? 1 : 7);

    return new Date(date.setDate(diff));
  }, []);

  // Option for filter date by link
  const optionFilterDate: OptionFilterDate[] = useMemo(() => {
    const dateNow = new Date();
    const yyyy = dateNow.getFullYear();
    const mm = dateNow.getMonth();
    // const dd = dateNow.getDate();

    return [
      {
        name: 'Hari ini',
        start_date: dateNow,
        end_date: dateNow,
      },
      {
        name: 'Minggu ini',
        start_date: startOfWeek(new Date()),
        end_date: endOfWeek(new Date()),
      },
      {
        name: 'Bulan ini',
        start_date: new Date(yyyy, mm, 1),
        end_date: new Date(yyyy, mm + 1, 0),
      },
      {
        name: dateNow.toLocaleDateString('id-ID', {
          year: 'numeric',
        }),
        start_date: new Date(yyyy, 0, 1),
        end_date: new Date(yyyy, 12, 0),
      },
    ];
  }, [endOfWeek, startOfWeek]);

  // Memoize feedback chart data
  const feebackChartData = useMemo(() => {
    const count: number[] = [];
    const label: string[] = [];

    data.forEach((filtered, index) => {
      const tempLabel = new Date(filtered.created_at).toLocaleDateString(
        'id-ID',
        {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        }
      );

      count.push(index + 1);
      label.push(tempLabel);
    });

    return {
      labels: label,
      datasets: [
        {
          data: count,
          borderColor: 'rgb(154, 210, 245)',
          backgroundColor: 'rgba(154, 210, 245,0.4)',
          pointBackgroundColor: '#fff',
          fill: true,
        },
      ],
    };
  }, [data]);

  return (
    <Grid
      width="100%"
      gridTemplateColumns={['100%', '1fr']}
      gridTemplateRows={['1fr auto', '1fr']}
      gridColumnGap="large"
      gridRowGap="large"
      marginRight={['', '40px']}
      gridTemplateAreas={['"A" "B"', '"A B"']}
      {...rest}
    >
      <Box
        border="thin"
        borderColor="secondaryLighten"
        borderRadius="4px"
        p={['', 'normal']}
        gridArea={['B', 'A']}
      >
        <Box position="relative" minHeight="300px" maxHeight="400px">
          <Line
            type="line"
            data={feebackChartData}
            options={totalDataChartOption}
          />
        </Box>

        <Flex flexWrap="wrap" justifyContent="space-evenly" mt="small">
          {optionFilterDate.map((result) => (
            <Caption
              key={result.name}
              as="button"
              variant="link"
              border="none"
              backgroundColor="transparent"
              onClick={() => {
                setDateFilter({
                  start_date: result.start_date,
                  end_date: result.end_date,
                });
              }}
            >
              {result.name}
            </Caption>
          ))}
        </Flex>
      </Box>

      <Box gapY="large" gridArea={['A', 'B']}>
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          border="thin"
          borderColor="secondaryLighten"
          borderRadius="4px"
          padding="large"
        >
          <Paragraph fontWeight="regular" fontSize={['56px', '72px']}>
            {data.length}
          </Paragraph>
          <Caption>Total Feedback</Caption>
        </Flex>

        <DateRangePicker
          label="Tanggal"
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          border="thin"
          borderColor="secondaryLighten"
          borderRadius="4px"
          p="large"
        />
      </Box>
    </Grid>
  );
};

const Analytic: React.FC = () => {
  const screenType = useScreeType();
  const dispatch = useDispatch();

  const { feedbackPublics, errorGetFeedbackPublics } = useSelector<
    AppState,
    GlobalState
  >((state) => state.global);

  const { isLoadingData } = useSelector<AppState, ApiState>(
    (state) => state.api
  );

  const [dateFilter, setDateFilter] = useState<DateFilter>({
    start_date: new Date('01/01/2021'),
    end_date: new Date(),
  });

  const handleGetFeedbackPublics = useCallback(
    ({ page, range }: { page?: number; range?: DateFilter }) => {
      return dispatch(GetFeedbackPublics(page, range));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const filteredFeedbackData = useMemo(
    (value?: string[]) => {
      const data = feedbackPublics?.results.filter((feedback) => {
        if (value) {
          return value.some(
            (someRes: any) =>
              someRes.value === feedback.fullname ||
              someRes.value === feedback.email ||
              someRes.value === feedback.message
          );
        } else {
          return feedback;
        }
      });

      const sortData = data?.sort((first, second) => {
        return (
          new Date(second.created_at).getTime() -
          new Date(first.created_at).getTime()
        );
      });

      return sortData?.map((result: BVETFeedbacksResults) => ({
        ...result,
        created_at: new Date(result.created_at).toLocaleString('id-Id', {
          day: 'numeric',
          month: 'numeric',
          year: '2-digit',
        }),
        is_helpful: result.is_helpful ? 'Ya' : 'Tidak',
        is_useful: result.is_useful ? 'Ya' : 'Tidak',
      }));
    },
    [feedbackPublics?.results]
  );

  useEffect(() => {
    console.log('DATE FILTER = ', dateFilter);

    handleGetFeedbackPublics({
      range: dateFilter,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter]);

  const DataView = useMemo(
    () => (
      <Box
        overflowX="auto"
        marginTop="large"
        border="thin"
        borderColor="secondaryLighten"
        borderRadius="4px"
      >
        {filteredFeedbackData && (
          <DataTable
            title=""
            columns={columns}
            theme="default"
            highlightOnHover={true}
            pointerOnHover={true}
            pagination={true}
            persistTableHead={true}
            expandableRows={true}
            expandableRowsComponent={({ data }) => (
              <Paragraph px="large">
                <b>Pesan : </b>
                {data.message}
              </Paragraph>
            )}
            data={filteredFeedbackData}
          />
        )}
      </Box>
    ),
    [filteredFeedbackData]
  );

  return (
    <PopUpView title="Analytic" width={['', 'calc(100vw - 56px)']}>
      {isLoadingData && (
        <Spinner
          spinnerSize={50}
          position="absolute"
          backgroundColor="#ffffff80"
          top="0"
          left="0"
          right="0"
          bottom="0"
        />
      )}

      <Box overflowX="auto" padding="large">
        <Heading marginBottom="large">Feedback Pengunjung</Heading>

        {errorGetFeedbackPublics && (
          <Caption variant="error">{errorGetFeedbackPublics}</Caption>
        )}

        {feedbackPublics?.results && (
          <FeedbackChart
            data={feedbackPublics?.results}
            screenType={screenType}
            dateFilter={dateFilter}
            setDateFilter={setDateFilter}
          />
        )}
        {DataView}
      </Box>
    </PopUpView>
  );
};

export default Analytic;
