import React from 'react';
import { Caption } from '../Typography';
import { StyledTooltips, StyledTooltipsProps } from './tooltips.style';

export type TooltipType = 'right' | 'left' | 'top' | 'bottom';

export type TooltipProps = StyledTooltipsProps & {
  text: string;
};

const Tooltips: React.FC<TooltipProps> = ({
  text,
  position = 'right',
  children,
  ...rest
}) => {
  return (
    <StyledTooltips position={position} {...rest}>
      {children}
      <Caption className="tooltips-text">{text}</Caption>
    </StyledTooltips>
  );
};

export default Tooltips;
