import {
  ADD_BVET_FEEDBACK_FAILURE,
  ADD_BVET_FEEDBACK_SUCCESS,
  ADD_FEEDBACK_FAILURE,
  ADD_FEEDBACK_SUCCESS,
  BVETFeedbacks,
  GET_BVET_FEEDBACK_FAILURE,
  GET_BVET_FEEDBACK_SUCCESS,
  GlobalAction,
  IModalState,
  IS_DRAWER_OPEN,
  IS_FEEDBACK_FORM_OPEN,
  SET_MODAL_CLOSE,
  SET_MODAL_OPEN,
  SET_YEAR,
} from './globalTypes';

export interface GlobalState {
  isDrawerOpen: boolean;
  modal: IModalState;
  year: number;
  isFeedbackFormOpen: boolean;
  feedbackPublics: BVETFeedbacks | null;
  errorAddFeedback: string | null;
  errorGetFeedbackPublics: string | null;
  errorAddBVETFeedback: string | null;
}

const defaultState: GlobalState = {
  isDrawerOpen: false,
  modal: {
    isOpen: false,
    title: 'Modal Title',
    description: 'Description Modal',
    action: undefined,
    actionText: undefined,
    secondAction: true,
    secondActionText: undefined,
  },
  year: new Date().getFullYear(),
  isFeedbackFormOpen: false,
  feedbackPublics: null,
  errorAddFeedback: null,
  errorGetFeedbackPublics: null,
  errorAddBVETFeedback: null,
};

const globalReducer = (
  state: GlobalState = defaultState,
  action: GlobalAction
) => {
  switch (action.type) {
    case IS_DRAWER_OPEN:
      return {
        ...state,
        isDrawerOpen: action.payload,
      };

    case SET_MODAL_OPEN:
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.payload,
          isOpen: true,
        },
      };

    case SET_MODAL_CLOSE:
      return {
        ...state,
        modal: {
          ...defaultState.modal,
          isOpen: false,
        },
      };

    case SET_YEAR:
      return {
        ...state,
        year: action.payload,
      };

    case IS_FEEDBACK_FORM_OPEN:
      return {
        ...state,
        isFeedbackFormOpen: action.payload,
      };

    case ADD_FEEDBACK_SUCCESS:
      return {
        ...state,
        errorAddFeedback: null,
      };

    case ADD_FEEDBACK_FAILURE:
      return {
        ...state,
        errorAddFeedback: action.payload.error,
      };

    case GET_BVET_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackPublics: action.payload,
        errorGetFeedbackPublics: null,
      };

    case GET_BVET_FEEDBACK_FAILURE:
      return {
        ...state,
        errorGetFeedbackPublics: action.payload.error,
      };

    case ADD_BVET_FEEDBACK_SUCCESS:
      return {
        ...state,
        errorAddBVETFeedback: null,
      };

    case ADD_BVET_FEEDBACK_FAILURE:
      return {
        ...state,
        errorAddBVETFeedback: action.payload.error,
      };

    default:
      return state;
  }
};

export default globalReducer;
