import React from 'react';
import SelectStyle, {
  SelectStyleProps,
  SelectVariantProps,
} from './select.style';

export type SelectProps = SelectStyleProps &
  React.SelectHTMLAttributes<HTMLSelectElement> &
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    variant?: SelectVariantProps;
    optionData: string[];
    error?: boolean;
  };

const Select: React.ForwardRefRenderFunction<unknown, SelectProps> = (
  { optionData, variant = 'secondary', error, ...rest },
  ref
) => (
  <SelectStyle
    as="select"
    variant={variant}
    error={error}
    ref={ref as React.MutableRefObject<HTMLSelectElement | HTMLTextAreaElement>}
    {...rest}
  >
    {optionData?.map((result) => (
      <option key={result} value={result}>
        {result}
      </option>
    ))}
  </SelectStyle>
);

export default React.forwardRef<unknown, SelectProps>(Select);
