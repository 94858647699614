export const FETCH_ARTICLE_DETAILS = 'FETCH_ARTICLE_DETAILS';
export const SET_ARTICLE_DETAILS = 'SET_ARTICLE_DETAILS';

export const API = 'API';
export const API_START = 'API_START';
export const API_END = 'API_END';
export const ACCESS_DENIED = 'ACCESS_DENIED';
export const API_ERROR = 'API_ERROR';

export type TFetchToken = string | null;

export interface FetchApi {
  // type?: string;
  url?: string;
  method?: any;
  data?: any | null;
  accessToken?: TFetchToken;
  onSuccess?: any;
  onFailure?: any;
  label?: string;
  headersOverride?: any;
}

// Action
interface ApiStart {
  type: typeof API_START;
  payload: string;
}

interface ApiEnd {
  type: typeof API_END;
  payload: string;
}

interface ApiError {
  type: typeof API_ERROR;
  error: any;
}

interface AccessDenied {
  type: typeof ACCESS_DENIED;
  payload: {
    url: string;
  };
}

export type ApiAction = ApiStart | ApiEnd | ApiError | AccessDenied;
