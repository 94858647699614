export interface SelectOptionProps {
  value: any;
  label: string;
}

export function getSelectValues(
  options: SelectOptionProps[] | any[]
): string[] {
  return options.map((option) => option.value);
}

export function getSelectOptions(options: string[]): SelectOptionProps[] {
  return options.map((option) => ({
    label: option,
    value: option,
  }));
}
