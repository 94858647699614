import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Flex from 'components/atoms/Flex';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import Spinner from 'components/atoms/Spinner';
import Tooltips from 'components/atoms/Tooltips';
import { Caption, Paragraph } from 'components/atoms/Typography';
import DropdownMenu, {
  TDropdownListDataProps,
} from 'components/molecules/DropdownMenu';
import ItemList from 'components/organisms/ItemList';
import PopUpView from 'components/organisms/PopUpView';
import { URL_PLATFORM } from 'configs/constant';
import useCheckMemberLevel from 'hooks/checkLevelMember';
import React, { useCallback, useState } from 'react';
import { memo } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ApiState } from 'redux/api/apiReducer';
import { setModalOpen } from 'redux/global/globalAction';
import { deleteGroup, getGroupById, getGroups } from 'redux/group/groupAction';
import { GroupState } from 'redux/group/groupReducer';
import { Group, Member } from 'redux/group/groupTypes';
import {
  deleteGroupMember,
  updateGroupMember,
} from 'redux/member/memberAction';
import { MemberLevel } from 'redux/member/memberTypes';
import { AppState } from 'redux/store';

const InviteLinkContent: React.FC<{ groupById: Group }> = memo(
  ({ groupById }) => (
    <>
      <Paragraph>Salin tautan di bawah</Paragraph>
      <Input
        as="textarea"
        rows={3}
        readOnly={true}
        defaultValue={`${URL_PLATFORM}/join/${groupById.token_invitation}`}
      />

      <Paragraph>Token Proyek</Paragraph>
      <Input
        readOnly={true}
        as="textarea"
        rows={2}
        defaultValue={groupById.token_invitation}
      />
    </>
  )
);

const GroupDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { groupId }: any = useParams();

  const token = localStorage.getItem('token');

  const { groupById } = useSelector<AppState, GroupState>(
    (props) => props.group
  );

  const memberLevel: MemberLevel = useCheckMemberLevel(groupId);

  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  const [isMemberEdit, setIsMemberEdit] = useState(false);

  // Function for action after delete group success
  const deleteGroupSuccessAction = useCallback(() => {
    dispatch(getGroups(token));

    push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  // Handle open modal if delete gorup
  const handleDeleteGroup = useCallback(
    (id: number, groupName?: string) => {
      dispatch(
        setModalOpen({
          isOpen: true,
          title: `Hapus ${groupName}`,
          description: 'Apakah anda yakin untuk menghapus proyek ?',
          actionText: 'Hapus',
          secondActionText: 'Batal',
          action: () =>
            dispatch(deleteGroup(token, id, () => deleteGroupSuccessAction())),
        })
      );
    },
    [deleteGroupSuccessAction, dispatch, token]
  );

  // Handle open modal for link invite member
  const handleGetLinkInviteMember = useCallback(() => {
    dispatch(
      setModalOpen({
        title: 'Link invite',
        description: groupById ? (
          <InviteLinkContent groupById={groupById} />
        ) : (
          <Caption variant="error">"Opps Something Wrong !"</Caption>
        ),
        secondActionText: 'Tutup',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // List dropdown action handle
  const memberDropdownList: TDropdownListDataProps = useMemo(
    () => [
      {
        text: 'Tambah Anggota',
        link: {
          to: `/group/${groupId}/invite`,
          iconLeft: 'group_add',
        },
      },
      {
        text: 'Undang Via Tautan',
        link: {
          iconLeft: 'insert_link',
          action: handleGetLinkInviteMember,
        },
      },
      {
        text: 'Aktivitas Anggota',
        link: {
          to: `/group/${groupId}/activity`,
          iconLeft: 'schedule',
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupId]
  );

  // Handle delete Member
  const handleDeleteMember = useCallback(
    (id: number, username?: string) => {
      dispatch(
        setModalOpen({
          isOpen: true,
          title: `Hapus ${username}`,
          description: `Apakah anda yakin untuk menghapus ${username} ?`,
          actionText: 'Hapus',
          secondActionText: 'Batal',
          action: () => dispatch(deleteGroupMember(token, groupId, id)),
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupId, token]
  );

  // Handle update member level
  const handleUpdateMemberLevel = useCallback(
    (id, level: MemberLevel) => {
      dispatch(updateGroupMember(token, groupId, id, { level }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupId, token]
  );

  useEffect(() => {
    if (!groupById || groupById.id !== parseFloat(groupId)) {
      dispatch(getGroupById(token, groupId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, groupId]);

  const MemberListView = useMemo(
    () =>
      groupById?.members.map((result: Member) => (
        <ItemList
          key={result.person.username}
          title={result.person.email}
          borderTop="none !important"
          borderX="none !important"
          borderRadius="0 !important"
          hoverScale={false}
          left={
            !isMemberEdit ? (
              <Caption
                border="thin"
                borderRadius="4px"
                padding="small"
                fontSize="10px"
                backgroundColor="primary"
                color="white"
              >
                {result.level}
              </Caption>
            ) : (
              <Flex alignItems="center" gapX="small">
                <DropdownMenu
                  icon="arrow_drop_down"
                  width="auto"
                  height="18px !important"
                  padding="small"
                  iconSize="10px"
                  rounded={false}
                  listData={[
                    {
                      text: 'admin',
                      link: {
                        action: () =>
                          handleUpdateMemberLevel(result.id, 'admin'),
                      },
                    },
                    {
                      text: 'member',
                      link: {
                        action: () =>
                          handleUpdateMemberLevel(result.id, 'member'),
                      },
                    },
                    {
                      text: 'guest',
                      link: {
                        action: () =>
                          handleUpdateMemberLevel(result.id, 'guest'),
                      },
                    },
                  ]}
                >
                  {result.level}
                </DropdownMenu>

                <Button
                  variant="transparent"
                  rounded={true}
                  width="24px"
                  height="24px"
                  minWidth="24px !important"
                  minHeight="24px !important"
                  onClick={() =>
                    handleDeleteMember(result.id, result.person.username)
                  }
                >
                  <Icon color="error" fontSize="20px">
                    clear
                  </Icon>
                </Button>
              </Flex>
            )
          }
          leftPosition="center"
        />
      )),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupById?.members, isMemberEdit]
  );

  const DetailView = useMemo(
    () => (
      <>
        <Caption color="primary" fontWeight="bold">
          Deskripsi
        </Caption>

        <Paragraph marginBottom="medium" marginTop="small">
          {groupById?.description}
        </Paragraph>

        <Box border="thin" borderColor="secondaryLighten" borderRadius="8px">
          <Flex
            justifyContent="space-between"
            alignItems="center"
            paddingY="small"
            paddingRight="small"
          >
            <Heading as="h2" variant="title" paddingX="normal" margin="0">
              Anggota ({groupById?.members && groupById.members.length + 1})
            </Heading>

            {groupById && (memberLevel === 'owner' || memberLevel === 'admin') && (
              <Flex>
                <Tooltips text="Edit Anggota">
                  <Button
                    size={['35px', '40px']}
                    variant="transparent"
                    rounded={true}
                    onClick={() => setIsMemberEdit(!isMemberEdit)}
                  >
                    <Icon
                      fontSize="20px"
                      color={!isMemberEdit ? 'text' : 'error'}
                    >
                      manage_accounts
                    </Icon>
                  </Button>
                </Tooltips>

                <DropdownMenu
                  variant="transparent"
                  icon="more_horiz"
                  size="30px"
                  padding="0px"
                  listData={memberDropdownList}
                />
              </Flex>
            )}
          </Flex>

          <ItemList
            title={groupById?.owner.email}
            borderX="none !important"
            borderBottom="none !important"
            borderRadius="0 !important"
            hoverScale={false}
            left={
              <Caption
                border="thin"
                borderRadius="4px"
                padding="small"
                fontSize="10px"
                backgroundColor="primary"
                color="white"
              >
                Owner
              </Caption>
            }
            leftPosition="center"
          />

          {MemberListView}
        </Box>

        {memberLevel === 'owner' && (
          <Button
            variant="error"
            onClick={() =>
              handleDeleteGroup(groupId || groupById?.id, groupById?.name)
            }
          >
            <Icon color="white" marginRight="normal">
              delete
            </Icon>
            Hapus Proyek
          </Button>
        )}
      </>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      MemberListView,
      groupById,
      groupId,
      isMemberEdit,
      memberDropdownList,
      memberLevel,
    ]
  );

  return (
    <PopUpView
      title={isLoadingData ? '...' : groupById?.name}
      width={['', '400px']}
      rightIcon={
        memberLevel === 'owner' && (
          <Button
            size={['35px', '40px']}
            variant="transparent"
            rounded={true}
            onClick={() =>
              push({
                pathname: `/group/${groupId}/update`,
                state: groupById,
              })
            }
          >
            <Icon fontSize="20px">edit</Icon>
          </Button>
        )
      }
    >
      <Box gapY="large" paddingX="large" paddingY="large">
        {isLoadingData && (
          <Spinner
            spinnerSize={50}
            position="absolute"
            backgroundColor="#ffffff80"
            top="0"
            left="0"
            right="0"
            bottom="0"
          />
        )}

        {DetailView}
      </Box>
    </PopUpView>
  );
};

export default GroupDetail;
