import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';
import { AppState } from 'redux/store';
import { IRouteProps } from './routes';

const PublicRoute = ({
  component: Component,
  path,
  exact = false,
  ...rest
}: IRouteProps): JSX.Element => {
  const isAuth = localStorage.getItem('token');
  const { push, location }: any = useHistory();
  const token = useSelector<AppState>(({ auth }) => auth.token);

  useEffect(() => {
    if (isAuth) {
      push(location.state?.fromPath! ? location.state.fromPath.pathname : '/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={(props) => <Component {...props} />}
    />
  );
};

export default PublicRoute;
