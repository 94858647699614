import React from 'react';
import BrandText from 'assets/icons/Zoonostic_black.svg';
import BrandIcon from 'assets/icons/Icon_Zoonostic.png';
import BrandTextWhite from 'assets/icons/Zoonostic_white.svg';
import BrandIconWhite from 'assets/icons/Icon_Zoonostic_white.png';
import styled from 'styled-components';
import Flex, { FlexStyleProps } from '../Flex';
import css from '@styled-system/css';

export type BrandProps = FlexStyleProps & {
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  type?: 'text' | 'icon';
  iconColor?: 'white' | 'black';
};

const BrandStyle = styled(Flex)<FlexStyleProps>(
  css({
    transition: 'none',
  })
);

const Brand: React.FC<BrandProps> = ({
  as = 'img',
  type = 'text',
  iconColor = 'black',
  children,
  ...rest
}) => (
  <BrandStyle
    as={as}
    alt="Zoonostic Brand"
    src={
      type === 'text'
        ? iconColor === 'black'
          ? BrandText
          : BrandTextWhite
        : iconColor === 'black'
        ? BrandIcon
        : BrandIconWhite
    }
    width={type === 'text' ? 'auto' : 44}
    height="auto"
    {...rest}
  >
    {children}
  </BrandStyle>
);

export default Brand;
