import { combineReducers } from 'redux';
import anotationReducer from './anotation/anotationReducer';
import apiReducer from './api/apiReducer';
import authReducer from './auth/authReducer';
import { LOGOUT } from './auth/authTypes';
import deseaseReducer from './desease/deseaseReducer';
import formReducer from './form/formReducer';
import globalReducer from './global/globalReducer';
import groupReducer from './group/groupReducer';
import mapReducer from './map/mapReducer';
import memberReducer from './member/memberReducer';
import { AppState } from './store';
import userReducer from './user/userReducer';

const appReducer = combineReducers({
  global: globalReducer,
  api: apiReducer,
  auth: authReducer,
  user: userReducer,
  group: groupReducer,
  form: formReducer,
  anotation: anotationReducer,
  map: mapReducer,
  desease: deseaseReducer,
  member: memberReducer,
});

const rootReducer: any = (state: AppState, action: any) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
