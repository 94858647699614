import React, { useState, useEffect, useCallback, useMemo } from 'react';
import css from '@styled-system/css';
import Box, { BoxStyleProps } from 'components/atoms/Box';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Heading from 'components/atoms/Heading';
import { Bar } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { AnotationState } from 'redux/anotation/anotationReducer';
import Flex, { FlexStyleProps } from 'components/atoms/Flex';
import useScreeType from 'hooks/useScreenType';
import { GlobalState } from 'redux/global/globalReducer';
import { setIsFeedbackFormOpen } from 'redux/global/globalAction';

export type TDataPageViewProps = BoxStyleProps & {};

export type TNormalData = {
  count: number;
  label: string;
}[];

const blue = 'rgba(43, 191, 254,  0.7)';

const DataPageViewStyle = styled(Flex)<FlexStyleProps>`
  ${css({
    flexDirection: 'column',
    width: ['auto', '350px'],
    position: ['', 'fixed'],
    backgroundColor: 'background',
    height: '100%',
    borderRadius: ['', '8px 0 0 8px '],
    top: ['70vh', 0],
    boxShadow: ['', 'medium'],
    paddingX: 'large',
    paddingY: 'large',
  })}
`;

const totalDataChartOption: ChartOptions = {
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: 'Data Input',
    },
  },
};

const DataPageView: React.FC<TDataPageViewProps> = ({ children, ...rest }) => {
  const dispatch = useDispatch();
  const screenType = useScreeType();

  const [isDataViewOpen, setIsDataViewOpen] = useState<boolean>(false);

  const [label, setLabel] = useState<string[]>([]);
  const [comulativeData, setComulativeData] = useState<number[]>([]);

  const { anotations } = useSelector<AppState, AnotationState>(
    (state) => state.anotation
  );

  const { isFeedbackFormOpen } = useSelector<AppState, GlobalState>(
    (state) => state.global
  );

  const generateData = useCallback((normalData: TNormalData) => {
    let total: number = 0;

    const totalData = normalData.map((item, index) => {
      setLabel((prevProps) => [...prevProps, item.label]);
      total += item.count;
      return total;
    });

    setComulativeData(totalData);
  }, []);

  const totalDataChart = useMemo(
    () => ({
      labels: label,
      datasets: [
        {
          type: 'bar',
          label: 'Count',
          backgroundColor: blue,
          data: comulativeData,
        },
      ],
    }),
    [comulativeData, label]
  );

  const generateNormalData = useCallback((): TNormalData => {
    const normalData: TNormalData = [];

    anotations?.forEach(({ attributes, created_at }) => {
      const tempLabel = new Date(created_at).toLocaleDateString('id-ID', {
        month: 'long',
        year: 'numeric',
      });

      const indexNormalData = normalData.findIndex(
        (result) => result.label === tempLabel
      );

      if (indexNormalData > -1) {
        return (normalData[indexNormalData] = {
          ...normalData[indexNormalData],
          count: normalData[indexNormalData].count + attributes.data.length,
        });
      }

      return normalData.push({
        count: attributes.data.length,
        label: tempLabel,
      });
    });

    return normalData;
  }, [anotations]);

  const resetData = useCallback(() => {
    setLabel([]);
    setComulativeData([]);
  }, []);

  useEffect(() => {
    resetData();

    generateData(generateNormalData());
  }, [anotations]);

  return (
    <DataPageViewStyle
      as="section"
      right={isDataViewOpen ? '0' : '-350px'}
      {...rest}
    >
      {screenType !== 'mobile' && (
        <Heading variant="heading" marginY="normal">
          Informasi Data
        </Heading>
      )}

      <Box gapY="large" overflowY="auto">
        <Box
          border="thin"
          borderColor="secondaryLighten"
          borderRadius="4px"
          padding="normal"
        >
          <Bar
            height={screenType !== 'mobile' ? 220 : 200}
            type="bar"
            data={totalDataChart}
            options={totalDataChartOption}
          />
        </Box>

        <Button
          variant="transparent"
          border="thin"
          borderColor="secondaryLighten"
          onClick={() => dispatch(setIsFeedbackFormOpen(!isFeedbackFormOpen))}
        >
          <Icon marginRight="normal">chat</Icon>Feedback
        </Button>
      </Box>

      <Button
        position="absolute"
        left="-40px"
        top="100px"
        borderTopRightRadius="0"
        borderBottomRightRadius="0"
        width="38px"
        minWidth="38px"
        onClick={() => setIsDataViewOpen(!isDataViewOpen)}
      >
        <Icon>{isDataViewOpen ? 'chevron_right' : 'chevron_left'}</Icon>
      </Button>
    </DataPageViewStyle>
  );
};

export default DataPageView;
