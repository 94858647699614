import css from '@styled-system/css';
import Box from 'components/atoms/Box';
import Grid, { GridStyleProps } from 'components/atoms/Grid';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import { Paragraph } from 'components/atoms/Typography';
import styled from 'styled-components';

export type TCardListProps = GridStyleProps & {
  title: string;
  icon?: string;
  description?: string;
};

const CardListStyle = styled(Grid)<GridStyleProps>(
  css({
    padding: 'large',
    border: 'thin',
    borderColor: 'secondaryLighten',
    borderRadius: 'medium',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateRows: '1fr',
  })
);

const CardList: React.FC<TCardListProps> = ({
  title,
  description,
  icon,
  children,
}) => (
  <CardListStyle as="article" gapX="medium">
    {icon && <Icon fontSize="50px">{icon}</Icon>}

    <Box>
      <Heading variant="brand">{title}</Heading>

      {description && (
        <Paragraph marginTop="normal" marginBottom="large">
          {description}
        </Paragraph>
      )}

      {children}
    </Box>
  </CardListStyle>
);

export default CardList;
