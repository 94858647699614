import { API_ENDPOINT } from 'configs/constant';
import fetchApi from 'redux/api/apiAction';
import { TFetchToken } from 'redux/api/apiTypes';
import { getGroupById, getGroupByIdSuccess } from 'redux/group/groupAction';
import { Group } from 'redux/group/groupTypes';
import {
  ADD_GROUP_MEMBER,
  ADD_GROUP_MEMBER_FAILURE,
  ADD_GROUP_MEMBER_SUCCESS,
  DELETE_GROUP_MEMBER,
  DELETE_GROUP_MEMBER_FAILURE,
  MemberLevel,
  MembersAction,
  UPDATE_GROUP_MEMBER,
  UPDATE_GROUP_MEMBER_FAILURE,
} from './memberTypes';

export interface IGroupMemberProps {
  emails: string[];
  level: MemberLevel;
}

export const addGroupMember = (
  token: TFetchToken,
  groupId: number,
  payload: IGroupMemberProps,
  actionSuccess?: () => void
) => {
  return fetchApi({
    url: `${API_ENDPOINT}groups/${groupId}/invite/`,
    label: ADD_GROUP_MEMBER,
    method: 'POST',
    onSuccess: (group: Group) => addGroupMemberSuccess(group, actionSuccess),
    onFailure: (error: any) => addGroupMemberFailure(error),
    accessToken: token,
    data: payload,
  });
};

const addGroupMemberSuccess = (
  payload: Group,
  action?: () => void
): MembersAction => {
  getGroupByIdSuccess(payload, action);

  return {
    type: ADD_GROUP_MEMBER_SUCCESS,
  };
};

const addGroupMemberFailure = ({ response }: any): MembersAction => {
  let errorMessage = 'Oops somethin wrong !';

  if (response?.data?.data?.error) {
    errorMessage = response.data.data.error;
  }

  return {
    type: ADD_GROUP_MEMBER_FAILURE,
    payload: {
      error: errorMessage,
    },
  };
};

export const updateGroupMember = (
  token: TFetchToken,
  groupId: number,
  userId: number,
  payload: {
    level: MemberLevel;
  },
  actionSuccess?: () => void
) => {
  return fetchApi({
    url: `${API_ENDPOINT}groups/${groupId}/members/${userId}/`,
    label: UPDATE_GROUP_MEMBER,
    method: 'PUT',
    onSuccess: (group: Group) => getGroupByIdSuccess(group, actionSuccess),
    onFailure: (error: any) => updateGroupMemberFailure(error),
    accessToken: token,
    data: payload,
  });
};

const updateGroupMemberFailure = ({ response }: any): MembersAction => {
  let errorMessage = 'Oops somethin wrong !';

  if (response?.data?.data?.error) {
    errorMessage = response.data.data.error;
  }

  return {
    type: UPDATE_GROUP_MEMBER_FAILURE,
    payload: {
      error: errorMessage,
    },
  };
};

export const deleteGroupMember = (
  token: TFetchToken,
  groupId: number,
  userId: number
) => {
  return fetchApi({
    url: `${API_ENDPOINT}groups/${groupId}/members/${userId}`,
    label: DELETE_GROUP_MEMBER,
    method: 'DELETE',
    onSuccess: () => getGroupById(token, groupId),
    onFailure: (error: any) => deleteGroupMemberFailure(error),
    accessToken: token,
  });
};

const deleteGroupMemberFailure = ({ response }: any): MembersAction => {
  console.log('ERROR ', response);

  let errorMessage = 'Oops somethin wrong !';

  if (response?.data?.data?.error) {
    errorMessage = response.data.data.error;
  }

  return {
    type: DELETE_GROUP_MEMBER_FAILURE,
    payload: {
      error: errorMessage,
    },
  };
};
