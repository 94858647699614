import css from '@styled-system/css';
import styled from 'styled-components';
import {
  border,
  color,
  ColorProps,
  compose,
  layout,
  LayoutProps,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';

export type TypographyStyleProps = ColorProps &
  TypographyProps &
  SpaceProps &
  LayoutProps & {
    truncate?: number;
  };

export type TyphographyVariantProps = 'body' | 'error' | 'muted' | 'link';

const variants: { [key in TyphographyVariantProps]: object } = {
  body: {
    color: 'text',
  },
  error: {
    color: 'error',
  },
  muted: {
    color: 'secondary',
  },
  link: {
    color: 'accent',
    '&:hover': {
      color: 'accentLighten',
    },
    cursor: 'pointer',
  },
};

export const truncateStyle = css``;

export const TypographyStyle = styled.p<TypographyStyleProps>`
  ${({ truncate }) =>
    truncate &&
    `
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: ${truncate}; /* number of lines to show */
      -webkit-box-orient: vertical;
      `}

  ${variant({ variants })}
  ${compose(color, typography, space, layout, border)}
`;
