import css from '@styled-system/css';
import styled from 'styled-components';
import {
  border,
  color,
  compose,
  gridArea,
  layout,
  shadow,
  space,
  typography,
  TypographyProps,
} from 'styled-system';
import Box, { BoxStyleProps } from '../Box';

export type SwitchStyleProps = BoxStyleProps & TypographyProps;

const SwitchStyle = styled(Box)<SwitchStyleProps>(
  css({
    width: '32px',
    height: '16px',
    position: 'relative',
    appearance: 'none',
    backgroundColor: 'secondary',
    borderRadius: '16px',
    transition: '0.2s',

    '&:checked': {
      backgroundColor: 'primary',

      '&::before': {
        left: '16px',
      },
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      backgroundColor: 'background',
      width: '16px',
      height: '16px',
      top: 0,
      left: 0,
      borderRadius: '16px',
      transform: 'scale(1.1)',
      boxShadow: 'small',
      transition: '0.2s',
    },

    '&:disable': {
      opacity: 0.6,
      filter: 'saturate(60%)',
    },
  })
);
compose(space, color, border, layout, gridArea, shadow, typography);

export default SwitchStyle;
