import React from 'react';
import InputStyle, { InputStyleProps, InputVariantProps } from './input.style';

export type InputProps = InputStyleProps &
  (
    | React.InputHTMLAttributes<HTMLInputElement>
    | React.TextareaHTMLAttributes<HTMLTextAreaElement>
  ) & {
    as?: React.ElementType | keyof JSX.IntrinsicElements;
    variant?: InputVariantProps;
    error?: boolean;
  };

const Input: React.ForwardRefRenderFunction<unknown, InputProps> = (
  { as = 'input', variant = 'secondary', error, ...rest },
  ref
) => (
  <InputStyle
    as={as}
    variant={variant}
    error={error}
    ref={ref as React.MutableRefObject<HTMLInputElement | HTMLTextAreaElement>}
    {...rest}
  />
);

export default React.forwardRef<unknown, InputProps>(Input);
