import { User } from 'redux/user/userTypes';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const LOGOUT = 'LOGOUT';

export interface Auth {
  access_token: string;
  user: User;
}

// Action
interface LoginAction {
  type: typeof LOGIN_SUCCESS;
  payload: Auth;
}

interface LoginFailure {
  type: typeof LOGIN_FAILURE;
  payload: {
    error: string;
  };
}

interface RegisterAction {
  type: typeof REGISTER_SUCCESS;
  payload: User;
}

interface RegisterFailure {
  type: typeof REGISTER_FAILURE;
  payload: {
    error: string;
  };
}

interface LogoutAction {
  type: typeof LOGOUT;
}

export type AuthAction =
  | LoginAction
  | LoginFailure
  | RegisterAction
  | RegisterFailure
  | LogoutAction;
