import React, { useCallback } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from 'components/atoms/Button';
import { IResetPasswordProps } from 'redux/auth/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { Caption } from 'components/atoms/Typography';
import { AppState } from 'redux/store';
import AuthTemplate, {
  TAuthTemplateLink,
} from 'components/templates/AuthTemplate';
import Field from 'components/molecules/Field';
import useQuery from 'hooks/useQuery';
import { resetPassword } from 'redux/user/userAction';
import { IResetPassword } from 'redux/user/userTypes';
import { UserState } from 'redux/user/userReducer';
import { ApiState } from 'redux/api/apiReducer';
import { setModalOpen } from 'redux/global/globalAction';
import { useHistory } from 'react-router-dom';
import InputPassword from 'components/atoms/Input/InputPassword/inputPassword';

const linkResetPassword: TAuthTemplateLink = [
  { path: '/login', text: 'Kembali ke halaman login' },
];

const ResetPassword: React.FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const query = useQuery();

  const { handleSubmit, control } = useForm<IResetPasswordProps>({
    defaultValues: {
      password: '',
      password_confirm: '',
    },
    mode: 'onChange',
  });

  const { errorResetPassword } = useSelector<AppState, UserState>(
    ({ user }) => user
  );
  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  // Handle Success Reset Password
  const handleSuccessResetPassword = useCallback(() => {
    dispatch(
      setModalOpen({
        title: 'Password Berhasil Diubah',
        description:
          'Password kamu berhasil dirubah, setalah mengklik tombol OK kamu akan dialihkan ke halaman Login',
        action: () => push('/login'),
        actionText: 'OK',
        secondAction: false,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Form Submit
  const onSubmit: SubmitHandler<IResetPasswordProps> = (data) => {
    const payload: IResetPassword = {
      uidb64: query.get('uidb64')!,
      token: query.get('token')!,
      ...data,
    };
    dispatch(resetPassword(payload, handleSuccessResetPassword));
  };

  return (
    <AuthTemplate
      title="Reset Password"
      link={linkResetPassword}
      loading={isLoadingData}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="password"
          rules={{
            required: 'Kolom ini tidak boleh kosong !',
            minLength: {
              value: 8,
              message: 'Minimal 8 karakter',
            },
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Field label="Password" error={error?.message}>
              <InputPassword
                input={{ ...field, error: !!error, placeholder: 'Password' }}
              />
            </Field>
          )}
        />

        <Controller
          name="password_confirm"
          rules={{
            required: 'Kolom ini tidak boleh kosong !',
            minLength: {
              value: 8,
              message: 'Minimal 8 karakter',
            },
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Field label="Konfirmasi Password" error={error?.message}>
              <InputPassword
                input={{
                  ...field,
                  error: !!error,
                  placeholder: 'Konfirmasi Password',
                }}
              />
            </Field>
          )}
        />

        {errorResetPassword && (
          <Caption color="error">{errorResetPassword}</Caption>
        )}

        <Button marginY="large" as="input" type="submit" value="Reset" />
      </form>
    </AuthTemplate>
  );
};

export default ResetPassword;
