import React from 'react';
import Box, { BoxStyleProps } from 'components/atoms/Box';
import Toolbar, { ToolbarProps } from 'components/molecules/Toolbar';
import useScreeType from 'hooks/useScreenType';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { GlobalState } from 'redux/global/globalReducer';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import { useCallback } from 'react';
import { setIsDrawerOpen } from 'redux/global/globalAction';
import SideViewStyle from './sideView.style';
import ScrollToogle from 'components/atoms/ScrollToogle';

export type TSideViewProps = BoxStyleProps &
  ToolbarProps & {
    rightIcon?: React.ReactNode;
    back?: boolean;
  };

const SideView: React.FC<TSideViewProps> = ({
  title,
  description,
  rightIcon,
  children,
  back = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const media = useScreeType();

  const { isDrawerOpen } = useSelector<AppState, GlobalState>(
    ({ global }) => global
  );

  const handleDrawerToogle = useCallback(() => {
    dispatch(setIsDrawerOpen(!isDrawerOpen));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen]);

  return (
    <SideViewStyle as="section" gridArea="Main" {...rest}>
      {media === 'mobile' && <ScrollToogle />}

      <Toolbar
        as="header"
        title={title}
        description={description}
        back={back}
        left={
          media !== 'mobile' &&
          !back && (
            <Button
              size={['35px', '40px']}
              variant="transparent"
              rounded={true}
              onClick={handleDrawerToogle}
            >
              <Icon>{isDrawerOpen ? 'close' : 'menu'}</Icon>
            </Button>
          )
        }
        right={rightIcon}
      />

      <Box height="100%" overflowY="auto">
        {children}
      </Box>
    </SideViewStyle>
  );
};

export default SideView;
