import React, { useCallback } from 'react';
import Field from 'components/molecules/Field';
import { EMAIL_REGEX } from 'configs/constant';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import { IForgotPasswordProps } from 'redux/auth/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { Caption } from 'components/atoms/Typography';
import { AppState } from 'redux/store';
import AuthTemplate, {
  TAuthTemplateLink,
} from 'components/templates/AuthTemplate';
import { requestResetPassword } from 'redux/user/userAction';
import { setModalOpen } from 'redux/global/globalAction';
import { UserState } from 'redux/user/userReducer';
import { ApiState } from 'redux/api/apiReducer';

const linkForgotPassword: TAuthTemplateLink = [
  { path: '/login', text: 'Kembali ke halaman login' },
];

const ForgotPassword: React.FC = () => {
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm<IForgotPasswordProps>({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
  });

  const { errorRequestResetPassword } = useSelector<AppState, UserState>(
    ({ user }) => user
  );
  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  const handleNotifyCheckEmail = useCallback(() => {
    dispatch(
      setModalOpen({
        title: 'Permintaan Dikirim',
        description:
          'Kami akan mengirimkan anda email untuk mengganti password. Jika email tidak ada di kotak masuk harap cek pada bagian Promosi',
        actionText: 'Tutup',
        secondAction: false,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<IForgotPasswordProps> = (data) => {
    dispatch(requestResetPassword(data, handleNotifyCheckEmail));
  };

  return (
    <AuthTemplate
      title="Lupa Password"
      link={linkForgotPassword}
      loading={isLoadingData}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          rules={{
            required: 'Kolom ini tidak boleh kosong !',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Email tidak valid !',
            },
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Field label="Email" error={error?.message}>
              <Input {...field} error={!!error} placeholder="Email" />
            </Field>
          )}
        />

        {errorRequestResetPassword && (
          <Caption color="error">{errorRequestResetPassword}</Caption>
        )}

        <Button
          marginY="large"
          as="input"
          type="submit"
          value="Kirim Permintaan"
        />
      </form>
    </AuthTemplate>
  );
};

export default ForgotPassword;
