import React from 'react';
import css from '@styled-system/css';
import styled from 'styled-components';
import {
  border,
  color,
  compose,
  flexbox,
  FlexboxProps,
  grid,
  GridProps as SystemGridProps,
  layout,
  shadow,
  space,
} from 'styled-system';
import Box, { BoxStyleProps } from '../Box';

export type GridStyleProps = BoxStyleProps &
  SystemGridProps &
  FlexboxProps & {
    id?: string;
  };

const Grid = styled(Box)<GridStyleProps>(
  css({
    display: 'grid',
  }),
  compose(space, color, border, layout, grid, flexbox, shadow)
) as React.FC<GridStyleProps>;

export default Grid;
