import { API_ENDPOINT } from 'configs/constant';
import fetchApi from 'redux/api/apiAction';
import { TFetchToken } from 'redux/api/apiTypes';
import {
  GET_USERS,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  GET_USER_BY_ID,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_SUCCESS,
  Profile,
  REQUEST_RESET_PASSWORD,
  REQUEST_RESET_PASSWORD_FAILURE,
  REQUEST_RESET_PASSWORD_SUCCESS,
  IResetPassword,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  Users,
  UsersAction,
  UPDATE_USER,
  UPDATE_USER_FAILURE,
  TInstitute,
} from './userTypes';

export const getUsers = (token: TFetchToken, query?: string) => {
  return fetchApi({
    url: `${API_ENDPOINT}accounts/users?page_size=10&search=${query}`,
    label: GET_USERS,
    onSuccess: getUserSuccess,
    onFailure: getUserFailure,
    accessToken: token,
  });
};

const getUserSuccess = (payload: Users): UsersAction => {
  console.log('GET USER SUCCESS : ', payload);

  return {
    type: GET_USERS_SUCCESS,
    payload,
  };
};

export const getUserFailure = ({ response }: any): UsersAction => {
  console.log('GET USER FAILURE : ', response);

  return {
    type: GET_USERS_FAILURE,
    payload: {
      error: response?.data?.data?.detail || 'Oops somethin wrong !',
    },
  };
};

export const getUserById = (token: TFetchToken, id: number) => {
  return fetchApi({
    url: `${API_ENDPOINT}users/${id}/`,
    label: GET_USER_BY_ID,
    onSuccess: getUserByIdSuccess,
    onFailure: getUserByIdFailure,
    accessToken: token,
  });
};

const getUserByIdSuccess = (
  payload: Profile,
  action?: () => void
): UsersAction => {
  console.log('GET USER BY ID SUCCESS : ', payload);

  if (action) {
    action();
  }

  return {
    type: GET_USER_BY_ID_SUCCESS,
    payload,
  };
};

const getUserByIdFailure = ({ response }: any): UsersAction => {
  console.log('GET USER BY ID FAILURE : ', response);

  return {
    type: GET_USER_BY_ID_FAILURE,
    payload: {
      error: response?.data?.data?.detail || 'Oops somethin wrong !',
    },
  };
};

// GET PROFILE
export const getProfile = (token: TFetchToken) => {
  return fetchApi({
    url: `${API_ENDPOINT}accounts/profile/`,
    label: GET_USER_BY_ID,
    onSuccess: getUserByIdSuccess,
    onFailure: getUserByIdFailure,
    accessToken: token,
  });
};

// REQUEST RESET PASSWORD
export const requestResetPassword = (
  payload: { email: string },
  action?: () => void
) => {
  return fetchApi({
    url: `${API_ENDPOINT}accounts/password/reset/`,
    method: 'POST',
    label: REQUEST_RESET_PASSWORD,
    onSuccess: (result: { success: string }) =>
      requestResetPasswordSuccess(result, action),
    onFailure: requestResetPasswordFailure,
    data: payload,
  });
};

const requestResetPasswordSuccess = (
  payload: { success: string },
  action?: () => void
): UsersAction => {
  if (action) {
    action();
  }

  return {
    type: REQUEST_RESET_PASSWORD_SUCCESS,
    payload,
  };
};

const requestResetPasswordFailure = ({ response }: any): UsersAction => {
  let errorMessage = 'Oops somethin wrong !';

  if (response.data.data.email) {
    errorMessage = response.data.data.email;
  }

  return {
    type: REQUEST_RESET_PASSWORD_FAILURE,
    payload: {
      error: errorMessage,
    },
  };
};

// RESET PASSWORD
export const resetPassword = (payload: IResetPassword, action?: () => void) => {
  /**
   * Fetch reset password
   *
   * @remarks
   * This Method for confirm reset password
   *
   * @param payload - The payload is part of the {@link IResetPassword  }
   * @param action - for function after success reset password
   */

  return fetchApi({
    url: `${API_ENDPOINT}accounts/password/reset/confirm/`,
    method: 'POST',
    label: RESET_PASSWORD,
    onSuccess: (result: { message: string }) =>
      resetPasswordSuccess(result, action),
    onFailure: resetPasswordFailure,
    data: payload,
  });
};

const resetPasswordSuccess = (
  payload: { message: string },
  action?: () => void
): UsersAction => {
  if (action) {
    action();
  }

  return {
    type: RESET_PASSWORD_SUCCESS,
    payload,
  };
};

const resetPasswordFailure = ({ response }: any): UsersAction => {
  let errorMessage = 'Oops somethin wrong !';

  if (response.data.data.password_confirm) {
    errorMessage = response.data.data.password_confirm[0];
  }

  return {
    type: RESET_PASSWORD_FAILURE,
    payload: {
      error: errorMessage,
    },
  };
};

// Update User

export interface IUpdateProfile {
  avatar?: string;
  telephone?: string;
  institute?: TInstitute;
  inst_name?: string;
  address?: string;
  longitude?: number | null;
  latitude?: number | null;
}

export const updateProfile = (
  token: TFetchToken,
  payload: IUpdateProfile,
  action?: () => void
) => {
  /**
   * Fetch for Update Profile
   *
   * @remarks
   * This Method for Updating Profile
   *
   * @param payload - The payload is part of the {@link IUpdateProfile  }
   * @param action - for function after success update profile
   */

  return fetchApi({
    url: `${API_ENDPOINT}accounts/profile/`,
    method: 'PUT',
    label: UPDATE_USER,
    onSuccess: (result: Profile) => getUserByIdSuccess(result, action),
    onFailure: updateProfileFailure,
    data: payload,
    accessToken: token,
  });
};

const updateProfileFailure = ({ response }: any): UsersAction => {
  let errorMessage = 'Oops somethin wrong !';

  if (response.data.data.password_confirm) {
    errorMessage = response.data.data.password_confirm[0];
  }

  return {
    type: UPDATE_USER_FAILURE,
    payload: {
      error: errorMessage,
    },
  };
};
