export type ThemeScale<Type, Aliases extends string> = Type[] &
  Record<Aliases, Type>;

/**
 * Type Style Configuration
 */
export type ColorAlias =
  | 'text'
  | 'background'
  | 'primary'
  | 'primaryLighten'
  | 'secondary'
  | 'secondaryLighten'
  | 'error'
  | 'errorLighten'
  | 'accent'
  | 'accentLighten'
  | 'muted';
export type BreakpointAlias = 'mobile' | 'dekstop' | 'wideScreen';
export type SizeAlias = 'small' | 'medium' | 'large';
export type SpaceAlias = SizeAlias | 'none' | 'normal';
export type FontFamilyAlias = 'primary' | 'secondary';
export type FontSizeAlias =
  | 'caption'
  | 'paragraph'
  | 'title'
  | 'heading'
  | 'brand';
export type FontWeightAlias = 'thin' | 'regular' | 'bold';
export type LineHeightAlias = 'body' | 'heading';
export type BorderAlias = 'thin' | 'bold';
export type BorderRadiusAlias = SizeAlias | 'rounded';

/**
 * Style Configuration
 */
export const colors: { [key in ColorAlias]: string } = {
  text: '#3d3d3d',
  background: 'white',
  primary: '#10132C',
  primaryLighten: '#272843',
  secondary: '#B0B0B0',
  secondaryLighten: '#D0D0D0',
  error: '#EA3B3B',
  errorLighten: '#F74E4A',
  accent: '#007AFF',
  accentLighten: '#0036A9',
  muted: '#EEEEEE',
};

export const breakpoints = ['768px', '992px', '1200px'] as ThemeScale<
  string,
  BreakpointAlias
>;

export const mediaQueries: { [key in BreakpointAlias]: string } = {
  mobile: `@media screen and (min-width: ${breakpoints[0]})`,
  dekstop: `@media screen and (min-width: ${breakpoints[1]})`,
  wideScreen: `@media screen and (min-width: ${breakpoints[2]})`,
};

export const space = [
  0,
  '0.25rem', // 4px
  '0.5rem', // 8px
  '0.75rem', // 12px
  '1rem', // 16px
  '2rem', // 32px
  '4rem', // 64px
  '8rem', // 128px
  '16rem', // 256px
] as ThemeScale<string, SpaceAlias>;

export const fontFamily: { [key in FontFamilyAlias]: string } = {
  primary:
    '"Graphik",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  secondary:
    '"Graphik",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
};

export const fontSizes = [
  '.83rem',
  '.98rem',
  '1.1rem',
  '1.3rem',
  '1.5rem',
] as ThemeScale<string, FontSizeAlias>;

export const fontWeights = [400, 500, 700] as ThemeScale<
  number,
  FontWeightAlias
>;

export const lineHeights = [1, 1.3] as ThemeScale<number, LineHeightAlias>;

export const borders = ['1px solid', '2px solid'] as ThemeScale<
  string,
  BorderAlias
>;

export const borderRadius = ['4px', '8px', '16px', '50%'] as ThemeScale<
  string,
  BorderRadiusAlias
>;

export const boxShadow = [
  '0px 0px 8px 2px rgb(0 0 0 / 15%)',
  '0px 0px 12px 6px rgb(0 0 0 / 15%)',
  '0px 0px 16px 10px rgb(0 0 0 / 15%)',
] as ThemeScale<string, SizeAlias>;
