import React, { useCallback, useState } from 'react';
import Button, { ButtonProps } from 'components/atoms/Button';
import Flex, { FlexStyleProps } from 'components/atoms/Flex';
import Icon from 'components/atoms/Icon';
import Input from '..';
import { InputProps } from '../input';

export type InputPasswordProps = FlexStyleProps & {
  input?: InputProps;
  button?: ButtonProps;
};

const InputPassword: React.FC<InputPasswordProps> = ({
  input,
  button,
  ...rest
}) => {
  // Show Password
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = useCallback(
    (e: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent) => {
      e.preventDefault();
      setShowPassword(!showPassword);
    },
    [showPassword]
  );

  return (
    <Flex position="relative" {...rest}>
      <Input
        autoComplete="password"
        title="Kolom Password"
        paddingRight="40px"
        type={showPassword ? 'text' : 'password'}
        {...input}
      />
      <Button
        as="i"
        tabIndex={0}
        variant="transparent"
        onKeyPress={handleShowPassword}
        onClick={handleShowPassword}
        title={showPassword ? 'Sembunyikan Password' : 'Tampilkan Password'}
        width="40px"
        position="absolute"
        top="0"
        bottom="0"
        right="0"
        {...button}
      >
        <Icon>{showPassword ? 'visibility_off' : 'visibility'}</Icon>
      </Button>
    </Flex>
  );
};

export default InputPassword;
