import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Tooltips from 'components/atoms/Tooltips';
import React, { useCallback } from 'react';

const ButtonCopy: React.FC<{ id: string }> = ({ id }) => {
  // Handle function for copying data to clipboard
  const handleCopyData = useCallback(() => {
    const elm = document.getElementById(id);
    if (elm?.textContent) {
      navigator.clipboard.writeText(elm.textContent);
    }
  }, [id]);

  return (
    <Tooltips text="Copy ke Clipboard">
      <Button
        variant="transparent"
        width="auto"
        minWidth="0 !important"
        height="auto"
        minHeight="0 !important"
        padding="small"
        border="thin"
        borderColor="secondaryLighten"
        onClick={handleCopyData}
      >
        <Icon fontSize="18px">content_copy</Icon>
      </Button>
    </Tooltips>
  );
};

export default ButtonCopy;
