import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';
import Icon from 'components/atoms/Icon';
import useScreeType from 'hooks/useScreenType';
import React, {
  useState,
  useMemo,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { useCallback } from 'react';
import DataTable from 'react-data-table-component';
import Select, { OptionsType } from 'react-select';

export function convertArrayOfObjectsToCSV(data: any[]) {
  let result: string;

  const columnDelimiter = ' ,';
  const lineDelimiter = '\n';
  const keys = Object.keys(data[0]);

  result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach((item: any) => {
    let ctr = 0;

    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      if (typeof key === 'string') {
        result += `"${item[key]}"`;
      } else {
        result += item[key];
      }

      ctr++;
    });

    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(data: any[], fileName: string = 'export') {
  const link = document.createElement('a');
  let csv = convertArrayOfObjectsToCSV(data);
  if (csv == null) return;

  const filename = `${fileName}.csv`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute('href', encodeURI(csv));
  link.setAttribute('download', filename);
  link.click();
}

const ButtonExportToExcel = ({ onExport }: { onExport: any }) => {
  const screenType = useScreeType();

  return (
    <Button
      width="auto"
      onClick={(e: any) => onExport(e.target.value)}
      minHeight="0 !important"
      height="auto"
      fontSize="caption"
      p="normal"
      gapX="small"
    >
      <Icon fontSize="12px" mr="small">
        description
      </Icon>

      {screenType !== 'mobile' && 'Export ke Excel'}
    </Button>
  );
};

const SearchSelect: React.FC<{
  data: any[];
  onFilter: Dispatch<SetStateAction<(string | number)[]>>;
}> = ({ data, onFilter }) => {
  // Handle when onchange select filter
  const handleOnChage = useCallback(
    (
      filter: OptionsType<{
        value: string | number;
        label: string | number;
      }>
    ) => {
      const dataFilter = filter.map((result) => result.value);
      if (dataFilter.length > 0) {
        return onFilter(dataFilter);
      }

      return onFilter(['']);
    },
    [onFilter]
  );

  // Convert Data to option react-select
  const optionsData = useMemo(() => {
    const options: { value: string | number; label: string | number }[] = [];

    data.forEach((result) => {
      Object.keys(result).map((key) => {
        return options.push({
          value: result[key],
          label: result[key],
        });
      });
    });

    return options;
  }, [data]);

  return (
    <Select
      options={optionsData}
      isSearchable={true}
      cacheOptions={true}
      isMulti={true}
      placeholder="Cari..."
      onChange={handleOnChage}
    />
  );
};

const Table: React.FC<{ data: any[] }> = ({ data }) => {
  const screenType = useScreeType();
  const [isTableViewOpen, setIsTableViewOpen] = useState(false);
  const [filterText, setFilterText] = useState<(string | number)[]>(['']);

  // Handle Setting Column Data
  const columnTable = useMemo(() => {
    const tempColumn: string[] = [];

    Object.keys(data[0]).forEach((column) => {
      return tempColumn.push(column);
    });

    return tempColumn.map((result: string) => ({
      name: result,
      selector: (row: any) => row[result],
      sortable: true,
    }));
  }, [data]);

  // Handle Table View Open
  const hanldeTableViewOpen = useCallback(() => {
    setIsTableViewOpen(!isTableViewOpen);
  }, [isTableViewOpen]);

  // Handle Get Data Filtered
  const filteredData = useMemo(
    () =>
      data.filter((result: any) =>
        Object.keys(result).some((key) =>
          filterText.some((filter) => {
            if (result[key]) {
              return result[key]
                .toLowerCase()
                .includes(filter.toString().toLowerCase());
            }
            return false;
          })
        )
      ),
    [data, filterText]
  );

  // Button Export To Excel
  const ActionComponentMemo = useMemo(() => {
    return (
      <Grid
        gridAutoRows="1fr"
        gridTemplateColumns="1fr auto"
        gridTemplateRows="1fr"
        gridColumnGap="large"
        maxWidth="400px"
        ml="auto"
        mb="large"
      >
        <SearchSelect data={data} onFilter={setFilterText} />
        <ButtonExportToExcel onExport={() => downloadCSV(data, 'Data')} />
      </Grid>
    );
  }, [data]);

  useEffect(() => {
    return () => {
      setIsTableViewOpen(false);
    };
  }, []);

  return (
    <Box
      position="fixed"
      top={
        isTableViewOpen ? (screenType !== 'mobile' ? '40%' : '70px') : '100%'
      }
      right="0"
      bottom="0"
      left={['0', '56px']}
      zIndex={100}
      borderTop="thin"
      borderColor="secondaryLighten"
      backgroundColor="white"
      borderTopLeftRadius="8px"
      borderTopRightRadius="8px"
      paddingY="large"
      paddingX="small"
    >
      <Button
        position="absolute"
        top="-18px"
        borderBottomLeftRadius="0"
        borderBottomRightRadius="0"
        width="auto"
        variant="secondary"
        backgroundColor="white"
        border="thin"
        borderColor="secondaryLighten"
        left="50%"
        minHeight="0 !important"
        py={0}
        height="18px"
        style={{ transform: 'translateX(-50%)' }}
        onClick={hanldeTableViewOpen}
      >
        <Icon fontSize="18px">
          {isTableViewOpen ? 'expand_more' : 'expand_less'}
        </Icon>
      </Button>

      <Box position="relative" height="100%">
        {ActionComponentMemo}

        <DataTable
          columns={columnTable}
          data={filteredData}
          pagination={true}
          fixedHeader={true}
          dense={true}
          customStyles={{
            table: {
              style: {
                maxHeight: screenType !== 'mobile' ? '38vh' : '100%',
                height: '100%',
              },
            },
            header: {
              style: {
                minHeight: '0 !mportant',
                marginBottom: '8px',
              },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Table;
