import React from 'react';
import IconStyle, { IconStyleProps, IconVariantProps } from './icons.style';

export type IconProps = IconStyleProps & {
  as?: React.ElementType | keyof JSX.IntrinsicElements;
  variant?: IconVariantProps;
  children: React.ReactNode;
};

const Icon: React.FC<IconProps> = ({
  as = 'div',
  variant,
  children,
  ...rest
}) => (
  <IconStyle as={as} variant={variant} {...rest} className="material-icons">
    {children}
  </IconStyle>
);

export default Icon;
