import React, { useCallback, useMemo, useState } from 'react';
import Flex from 'components/atoms/Flex';
import { TileLayer, useMap } from 'react-leaflet';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import useCheckLocationPermisison from 'hooks/useCheckLocationPermisison';
import styled from 'styled-components';
import css from '@styled-system/css';
import mapTiles from 'configs/mapsTile';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFeedbackFormOpen } from 'redux/global/globalAction';
import { AppState } from 'redux/store';
import { GlobalState } from 'redux/global/globalReducer';
import useScreeType from 'hooks/useScreenType';

const optionControlStyle = {
  button: {
    size: ['35px', '40px'],
    padding: ['0', ''],
    minWidth: '35px',
    minHeight: '35px',
  },
  icon: {
    fontSize: ['16px', '20px'],
  },
};

const LayerControlStyle = styled.div<{ isOpen: boolean }>`
  ${css({
    position: 'absolute',
    left: 'normal',
    marginTop: 'normal',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'primary',
    borderRadius: 'large',
    overflow: 'hidden',
    minWidth: '150px',
  })}

  ${({ isOpen }) =>
    !isOpen
      ? css({
          visibility: 'hidden',
          opacity: 0,
          top: '-40px',
        })
      : css({
          visibility: 'visible',
          opacity: 1,
          top: ['30px', '38px'],
        })}
`;

const MapsControl: React.FC = (ref) => {
  const screenType = useScreeType();
  const dispatch = useDispatch();
  const map = useMap();
  const { getLocationPermission } = useCheckLocationPermisison();
  const { isFeedbackFormOpen } = useSelector<AppState, GlobalState>(
    (state) => state.global
  );

  const [layerOpen, setLayerOpen] = useState(false);
  const [tileLayer, setTileLayer] = useState<string>(mapTiles[0].name);

  const handleZoomIn = useCallback(() => {
    map.zoomIn();
  }, []);

  const handleZoomOut = useCallback(() => {
    map.zoomOut();
  }, []);

  const FindMyLocationControl = useMemo(
    () => (
      <Button
        rounded={true}
        onClick={() => getLocationPermission()}
        {...optionControlStyle.button}
      >
        <Icon {...optionControlStyle.icon}>near_me</Icon>
      </Button>
    ),
    []
  );

  const ZoomControl = useMemo(
    () => (
      <Box>
        <Button
          borderRadius="50% 50% 0 0"
          position="relative"
          onClick={handleZoomIn}
          {...optionControlStyle.button}
        >
          <Icon {...optionControlStyle.icon}>add</Icon>
        </Button>

        <Button
          borderRadius="0 0 50% 50%"
          position="relative"
          onClick={handleZoomOut}
          {...optionControlStyle.button}
        >
          <Icon {...optionControlStyle.icon}>remove</Icon>
        </Button>
      </Box>
    ),
    []
  );

  const LayerControl = useMemo(
    () => (
      <Box position="relative">
        <LayerControlStyle isOpen={layerOpen}>
          {mapTiles.map((result) => (
            <Button
              key={result.url}
              onClick={() => setTileLayer(result.name)}
              variant={tileLayer === result.name ? 'secondary' : 'primary'}
            >
              {result.name}
            </Button>
          ))}
        </LayerControlStyle>

        <Button
          rounded={true}
          {...optionControlStyle.button}
          onClick={() => setLayerOpen(!layerOpen)}
        >
          <Icon {...optionControlStyle.icon}>layers</Icon>
        </Button>
      </Box>
    ),
    [layerOpen, tileLayer]
  );

  const FeedbackButton = useMemo(
    () => (
      <Button
        rounded={true}
        {...optionControlStyle.button}
        variant={isFeedbackFormOpen ? 'secondary' : 'primary'}
        onClick={() => dispatch(setIsFeedbackFormOpen(!isFeedbackFormOpen))}
      >
        <Icon {...optionControlStyle.icon}>chat</Icon>
      </Button>
    ),
    [isFeedbackFormOpen]
  );

  return (
    <>
      <Flex
        justifyContent="space-between"
        zIndex={1}
        position="absolute"
        top={0}
        left={0}
        paddingX="normal"
        paddingY="large"
      >
        <Flex flexDirection="column" gapY="normal" alignItems="center">
          {FindMyLocationControl}
          {ZoomControl}
          {LayerControl}
        </Flex>
      </Flex>

      {mapTiles.map(
        (result) =>
          tileLayer === result.name && (
            <TileLayer
              key={result.url}
              attribution={result.attribution}
              url={result.url}
            />
          )
      )}

      {screenType === 'mobile' && (
        <Flex
          justifyContent="space-between"
          zIndex={1}
          position="absolute"
          top={0}
          right={0}
          paddingX="normal"
          paddingY="large"
        >
          {FeedbackButton}
        </Flex>
      )}
    </>
  );
};

export default MapsControl;
