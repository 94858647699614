import { FormActionTypes, RESET_FORM_DATA, SET_FORM_DATA } from './formTypes';

export interface FormState {
  formData: any;
}

const defaultState: FormState = {
  formData: null,
};

const formReducer = (
  state: FormState = defaultState,
  action: FormActionTypes
) => {
  switch (action.type) {
    case SET_FORM_DATA:
      return {
        ...state,
        formData: action.payload,
      };

    case RESET_FORM_DATA:
      return {
        ...state,
        formData: null,
      };
    default:
      return state;
  }
};

export default formReducer;
