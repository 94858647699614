import css, { CssFunctionReturnType } from '@styled-system/css';
import styled from 'styled-components';
import {
  border,
  color,
  compose,
  gridArea,
  layout,
  shadow,
  space,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';
import Flex, { FlexStyleProps } from '../Flex';

export type ButtonStyleProps = FlexStyleProps &
  TypographyProps & {
    rounded?: boolean;
  };

export type ButtonVariantProps =
  | 'primary'
  | 'secondary'
  | 'error'
  | 'transparent';

const variants: { [key in ButtonVariantProps]: object } = {
  primary: {
    backgroundColor: 'primary',
    '&:hover': {
      backgroundColor: 'primaryLighten',
    },
    color: 'white',
    '.material-icon': {
      color: 'white',
    },
  },
  secondary: {
    backgroundColor: 'secondary',
    '&:hover': {
      backgroundColor: 'secondaryLighten',
    },
    color: 'text',
    '.material-icons': {
      color: 'text',
    },
  },
  error: {
    backgroundColor: 'error',
    '&:hover': {
      backgroundColor: 'errorLighten',
    },
    color: 'white',
    '.material-icons': {
      color: 'white',
    },
  },
  transparent: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'secondaryLighten',
    },
    color: 'text',
  },
};

const roundedStyle: CssFunctionReturnType = css({
  position: 'relative',
  borderRadius: 'rounded',
  padding: 0,

  '&:hover': {
    backgroundColor: 'inital',
  },

  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '0%',
    height: '0%',
    borderRadius: '50%',
    backgroundColor: 'rgb(255 255 255 / 20%)',
    transition: 'all 0.2s ease',
  },

  '&:focus': {
    '&::before': {
      width: '100%',
      height: '100%',
    },
  },
});

const ButtonStyle = styled(Flex)<ButtonStyleProps>`
  ${css({
    cursor: 'pointer',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    minHeight: ['35px', '40px'],
    minWidth: ['35px', '40px'],
    border: 'none',
    fontSize: 'paragraph',
    paddingX: 'large',
    paddingY: 'small',

    '&:disabled': {
      opacity: 0.6,
      filter: 'saturate(60%)',
      cursor: 'not-allowed',
    },
  })}

  ${({ rounded }) =>
    rounded
      ? roundedStyle
      : css({
          borderRadius: 'small',
        })}

  ${variant({ variants })}
  ${compose(space, color, border, layout, gridArea, shadow, typography)}
`;

export default ButtonStyle;
