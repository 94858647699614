import {
  Deseases,
  DeseasesAction,
  DeseaseType,
  GET_METHODS_FAILURE,
  GET_METHODS_SUCCESS,
  GET_PRESERVATIVES_FAILURE,
  GET_PRESERVATIVES_SUCCESS,
  GET_SPECIES_FAILURE,
  GET_SPECIES_SUCCESS,
  GET_SPECIMENS_FAILURE,
  GET_SPECIMENS_SUCCESS,
  SET_DESEASE_TYPE,
} from './deseaseTypes';

export interface DeseaseState {
  deseaseType: DeseaseType;
  methods: Deseases;
  preservatives: Deseases;
  species: Deseases;
  specimens: Deseases;
  errorGetMethods: string | null;
  errorGetPreserVatives: string | null;
  errorGetSpecies: string | null;
  errorGetSpecimens: string | null;
}

const defaultResultState: Deseases = {
  count: 0,
  next: null,
  previous: null,
  results: [],
};

const initialState: DeseaseState = {
  deseaseType: 'AI',
  methods: defaultResultState,
  preservatives: defaultResultState,
  species: defaultResultState,
  specimens: defaultResultState,
  errorGetMethods: null,
  errorGetPreserVatives: null,
  errorGetSpecies: null,
  errorGetSpecimens: null,
};

const deseaseReducer = (
  state = initialState,
  action: DeseasesAction
): DeseaseState => {
  switch (action.type) {
    case SET_DESEASE_TYPE:
      return {
        ...state,
        deseaseType: action.payload,
      };
    case GET_METHODS_SUCCESS:
      return {
        ...state,
        methods: action.payload,
        errorGetMethods: null,
      };

    case GET_METHODS_FAILURE:
      return {
        ...state,
        errorGetMethods: action.payload.error,
        methods: defaultResultState,
      };

    case GET_PRESERVATIVES_SUCCESS:
      return {
        ...state,
        preservatives: action.payload,
        errorGetPreserVatives: null,
      };

    case GET_PRESERVATIVES_FAILURE:
      return {
        ...state,
        errorGetPreserVatives: action.payload.error,
        preservatives: defaultResultState,
      };

    case GET_SPECIES_SUCCESS:
      return {
        ...state,
        species: action.payload,
        errorGetSpecies: null,
      };

    case GET_SPECIES_FAILURE:
      return {
        ...state,
        errorGetSpecies: action.payload.error,
        species: defaultResultState,
      };

    case GET_SPECIMENS_SUCCESS:
      return {
        ...state,
        specimens: action.payload,
        errorGetSpecimens: null,
      };

    case GET_SPECIMENS_FAILURE:
      return {
        ...state,
        errorGetSpecimens: action.payload.error,
        specimens: defaultResultState,
      };

    default:
      return state;
  }
};

export default deseaseReducer;
