import React from 'react';
import Field from 'components/molecules/Field';
import { EMAIL_REGEX } from 'configs/constant';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import { IRegisterProps, register as registerApi } from 'redux/auth/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { Caption } from 'components/atoms/Typography';
import { AppState } from 'redux/store';
import { AuthState } from 'redux/auth/authReducer';
import AuthTemplate, {
  TAuthTemplateLink,
} from 'components/templates/AuthTemplate';
import { useCallback } from 'react';
import { setModalOpen } from 'redux/global/globalAction';
import { useHistory } from 'react-router-dom';
import { ApiState } from 'redux/api/apiReducer';
import InputPassword from 'components/atoms/Input/InputPassword/inputPassword';

const linkRegister: TAuthTemplateLink = [
  { path: '/login', text: 'Sudah punya akun ?' },
];

const Register: React.FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { handleSubmit, control } = useForm<IRegisterProps>({
    defaultValues: {
      email: '',
      password: '',
      confirm_password: '',
    },
    mode: 'onChange',
  });

  const { errorRegister } = useSelector<AppState, AuthState>(
    ({ auth }) => auth
  );
  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  const handleNotifyCheckEmail = useCallback(() => {
    dispatch(
      setModalOpen({
        title: 'Satu Langkah Lagi',
        description:
          'Kami akan mengirimkan anda email untuk mengaktifasi akun anda. Jika email tidak ada di kotak masuk harap cek pada bagian Promosi',
        actionText: 'OK',
        secondAction: false,
        action: () => push('/login'),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<IRegisterProps> = (data) => {
    dispatch(registerApi(data, handleNotifyCheckEmail));
  };

  return (
    <AuthTemplate
      title="Daftar ke Zoonostic"
      link={linkRegister}
      loading={isLoadingData}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          rules={{
            required: 'Kolom ini tidak boleh kosong !',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Email tidak valid !',
            },
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Field label="Email" error={error?.message}>
              <Input {...field} error={!!error} placeholder="Email" />
            </Field>
          )}
        />

        <Controller
          name="password"
          rules={{
            required: 'Kolom ini tidak boleh kosong !',
            minLength: {
              value: 8,
              message: 'Minimal 8 karakter',
            },
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Field label="Password" error={error?.message}>
              <InputPassword
                input={{ ...field, error: !!error, placeholder: 'Password' }}
              />
            </Field>
          )}
        />

        <Controller
          name="confirm_password"
          rules={{
            required: 'Kolom ini tidak boleh kosong !',
            minLength: {
              value: 8,
              message: 'Minimal 8 karakter',
            },
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Field label="Konfirmasi Password" error={error?.message}>
              <InputPassword
                input={{
                  ...field,
                  error: !!error,
                  placeholder: 'Konfirmasi Password',
                }}
              />
            </Field>
          )}
        />

        {errorRegister && (
          <Caption color="error" marginBottom="medium">
            {errorRegister}
          </Caption>
        )}

        <Caption textAlign="center">
          Dengan mengklik Daftar, Anda menyetujui Perjanjian Pengguna, Kebijakan
          Privasi, dan Kebijakan Cookie Zoonostic iSurveillance.
        </Caption>

        <Button marginY="large" as="input" type="submit" value="Daftar" />
      </form>
    </AuthTemplate>
  );
};

export default Register;
