import { User } from 'redux/user/userTypes';

export const GET_GROUPS = 'GET_GROUPS';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';
export const GET_GROUP_BY_ID = 'GET_GROUP_BY_ID';
export const GET_GROUP_BY_ID_SUCCESS = 'GET_GROUP_BY_ID_SUCCESS';
export const GET_GROUP_BY_ID_FAILURE = 'GET_GROUP_BY_ID_FAILURE';
export const GET_GROUP_KEY = 'GET_GROUP_KEY';
export const GET_GROUP_KEY_SUCCESS = 'GET_GROUP_KEY_SUCCESS';
export const GET_GROUP_KEY_FAILURE = 'GET_GROUP_KEY_FAILURE';
export const RESET_GROUP_KEY = 'RESET_GROUP_KEY';
export const ADD_GROUP = 'ADD_GROUP';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAILLURE = 'ADD_GROUP_FAILLURE';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILURE = 'UPDATE_GROUP_FAILURE';
export const DELETE_GROUP = 'DELETE_GROUP';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
export const GET_INVITE_BY_LINK_GROUP = 'GET_INVITE_BY_LINK_GROUP';
export const GET_INVITE_BY_LINK_GROUP_SUCCESS =
  'GET_INVITE_BY_LINK_GROUP_SUCCESS';
export const GET_INVITE_BY_LINK_GROUP_FAILURE =
  'GET_INVITE_BY_LINK_GROUP_FAILURE';
export const ADD_GROUP_MEMBER = 'ADD_GROUP_MEMBER';
export const ADD_GROUP_MEMBER_SUCCESS = 'ADD_GROUP_MEMBER_SUCCESS';
export const ADD_GROUP_MEMBER_FAILURE = 'ADD_GROUP_MEMBER_FAILURE';
export const GET_GROUP_ACTIVITIES = 'GET_GROUP_ACTIVITIES';
export const GET_GROUP_ACTIVITIES_SUCCESS = 'GET_GROUP_ACTIVITIES_SUCCESS';
export const GET_GROUP_ACTIVITIES_FAILURE = 'GET_GROUP_ACTIVITIES_FAILURE';

export type TMemberLevel = 'member' | 'guest' | 'admin';

export interface Member {
  id: number;
  person: User;
  level: TMemberLevel;
  date_joined: string;
}

export interface GroupUpdateProps {
  name: string;
  description: string;
  is_public: boolean;
}

export interface Group {
  id: number;
  name: string;
  description: string;
  is_deleted: boolean;
  is_public: boolean;
  owner: User;
  members: Member[];
  created_at: string;
  updated_at: string;
  type: string;
  token_invitation: string;
}

export interface Groups {
  count: number;
  next: any | null;
  previous: any | null;
  results: Group[];
}

export interface IKeyFieldProps {
  id: string;
  name: string;
  type: string | any;
  is_required: boolean;
  option?: string[];
}

export type GroupActivity = {
  id: number;
  created_at: string;
  update_at: string;
  activity: string;
  user: {
    id: number;
    username: string;
    email: string;
    is_active: boolean;
    last_login: string;
  };
  group: number;
};

export type GroupActivities = {
  count: number;
  next: string | null;
  previous: string | null;
  results: GroupActivity[];
};

// Action
interface GetGroupsAction {
  type: typeof GET_GROUPS_SUCCESS;
  payload: Groups;
}

interface GetGroupFailure {
  type: typeof GET_GROUPS_FAILURE;
  payload: {
    error: string;
  };
}

interface GetGroupByIdAction {
  type: typeof GET_GROUP_BY_ID_SUCCESS;
  payload: Group | null;
}

interface GetGroupByIdFailure {
  type: typeof GET_GROUP_BY_ID_FAILURE;
  payload: {
    error: string;
  };
}

interface GetGroupKeyAction {
  type: typeof GET_GROUP_KEY_SUCCESS;
  payload: IKeyFieldProps[] | null;
}

interface GetGroupKeyFailure {
  type: typeof GET_GROUP_KEY_FAILURE;
  payload: {
    error: string;
  };
}

interface ResetGroupKey {
  type: typeof RESET_GROUP_KEY;
}

// interface AddGroupAction {
//   type: typeof ADD_GROUP_SUCCESS;
//   payload: Group;
// }

interface AddGroupFailure {
  type: typeof ADD_GROUP_FAILLURE;
  payload: {
    error: string;
  };
}

interface UpdateGroupFailure {
  type: typeof UPDATE_GROUP_FAILURE;
  payload: {
    error: string;
  };
}

interface DeleteGroupAction {
  type: typeof DELETE_GROUP_SUCCESS;
}

interface DeleteGroupActionFailure {
  type: typeof DELETE_GROUP_FAILURE;
  payload: {
    error: string;
  };
}

interface GetInviteByLinkFailure {
  type: typeof GET_INVITE_BY_LINK_GROUP_FAILURE;
  payload: {
    error: string;
  };
}

interface AddGroupMemberAction {
  type: typeof ADD_GROUP_MEMBER_SUCCESS;
}

interface AddGroupMemberFailure {
  type: typeof ADD_GROUP_FAILLURE;
  payload: {
    error: string;
  };
}

interface GetGroupActivitiesSuccess {
  type: typeof GET_GROUP_ACTIVITIES_SUCCESS;
  payload: GroupActivities;
}

interface GetGroupActivitiesFailure {
  type: typeof GET_GROUP_ACTIVITIES_FAILURE;
  payload: {
    error: string;
  };
}

export type GroupsAction =
  | GetGroupsAction
  | GetGroupFailure
  | GetGroupByIdAction
  | GetGroupByIdFailure
  | GetGroupKeyAction
  | GetGroupKeyFailure
  | ResetGroupKey
  // | AddGroupAction
  | AddGroupFailure
  | UpdateGroupFailure
  | DeleteGroupAction
  | DeleteGroupActionFailure
  | GetInviteByLinkFailure
  | AddGroupMemberAction
  | AddGroupMemberFailure
  | GetGroupActivitiesSuccess
  | GetGroupActivitiesFailure;
