import React, { lazy, Suspense } from 'react';
import css from '@styled-system/css';
import styled from 'styled-components';
import Spinner from '../../components/atoms/Spinner';
import useScreeType from 'hooks/useScreenType';

const DemoContent = lazy(() => import('./DemoContent'));
const IframeBvet = lazy(() => import('./IframeBVET'));

const MainContainer = styled.main(
  css({
    height: '100vh',
    display: ['', 'grid'],
    gridTemplateColumns: ['', '370px 1fr'],
    gridtemplateRows: ['', '100vh'],
  })
);

const MainLayout = styled.div`
  position: relative;
  top: 70vh;
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 16px 16px 0px 0px;
  z-index: 200;
`;

const Demo: React.FC = () => {
  const screenType = useScreeType();

  return (
    <MainContainer>
      <Suspense fallback={<Spinner />}>
        <IframeBvet />
      </Suspense>

      {screenType !== 'mobile' ? (
        <Suspense fallback={<Spinner />}>
          <DemoContent />
        </Suspense>
      ) : (
        <>
          <MainLayout>
            <Suspense fallback={<Spinner />}>
              <DemoContent />
            </Suspense>
          </MainLayout>
        </>
      )}
    </MainContainer>
  );
};

export default Demo;
