import {
  ADD_ANOTATION_FAILLURE,
  GET_ANOTATIONS_FAILURE,
  GET_ANOTATIONS_SUCCESS,
  GET_ANOTATION_BY_ID_SUCCESS,
  AnotationsAction,
  IAnotations,
  RESET_ANOTATION,
  GET_ANOTATION_BY_ID_FAILURE,
  DELETE_ANOTATION_SUCCESS,
  DELETE_ANOTATION_FAILLURE,
  ADD_ANOTATION_SUCCESS,
} from './anotationTypes';

export interface AnotationState {
  anotations: IAnotations[] | null;
  anotationById: IAnotations | null;
  errorGetAnotations: string | null;
  errorAddAnotation: string | null;
  errorDeleteAnotation: string | null;
}

const initialState: AnotationState = {
  anotations: null,
  anotationById: null,
  errorGetAnotations: null,
  errorAddAnotation: null,
  errorDeleteAnotation: null,
};

const anotationReducer = (
  state = initialState,
  action: AnotationsAction
): AnotationState => {
  switch (action.type) {
    case GET_ANOTATIONS_SUCCESS:
      return {
        ...state,
        anotations: action.payload,
      };

    case GET_ANOTATIONS_FAILURE:
      return {
        ...state,
        errorGetAnotations: action.payload.error,
      };

    case RESET_ANOTATION:
      return {
        ...state,
        anotations: initialState.anotations,
      };

    case GET_ANOTATION_BY_ID_SUCCESS:
      return {
        ...state,
        anotationById: action.payload,
      };

    case GET_ANOTATION_BY_ID_FAILURE:
      return {
        ...state,
        anotationById: null,
      };

    case ADD_ANOTATION_SUCCESS:
      return {
        ...state,
        anotations: [action.payload, ...state.anotations!],
        errorAddAnotation: null,
      };

    case ADD_ANOTATION_FAILLURE:
      return {
        ...state,
        errorAddAnotation: action.payload.error,
      };
    case DELETE_ANOTATION_SUCCESS:
      return {
        ...state,
        anotations:
          state.anotations?.filter(
            (result: IAnotations) => result.id !== action.payload
          ) || null,
        errorDeleteAnotation: null,
      };

    case DELETE_ANOTATION_FAILLURE:
      return {
        ...state,
        errorDeleteAnotation: action.payload.error,
      };

    default:
      return state;
  }
};

export default anotationReducer;
