export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const GET_USER_BY_ID = 'GET_USER_BY_ID';
export const GET_USER_BY_ID_SUCCESS = 'GET_USER_BY_ID_SUCCESS';
export const GET_USER_BY_ID_FAILURE = 'GET_USER_BY_ID_FAILURE';
export const REQUEST_RESET_PASSWORD = 'REQUEST_RESET_PASSWORD';
export const REQUEST_RESET_PASSWORD_SUCCESS = 'REQUEST_RESET_PASSWORD_SUCCESS';
export const REQUEST_RESET_PASSWORD_FAILURE = 'REQUEST_RESET_PASSWORD_FAILURE';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export interface User {
  id: number;
  username: string;
  email: string;
  is_active: boolean;
  last_login: string;
}

export type TInstitute = 'University' | 'Clinic' | 'Laboratory';

export interface Profile {
  id: number;
  user: User;
  avatar: string;
  telephone: string | null;
  institute: TInstitute | null;
  inst_name: string | null;
  address: string | null;
  longitude: number | null;
  latitude: number | null;
  updated_at: string | null;
}

export interface Users {
  count: number;
  next: any | null;
  previous: any | null;
  results: User[];
}

export interface IResetPassword {
  uidb64: string;
  token: string;
  password: string;
  password_confirm: string;
}

// Action
interface GetUsersAction {
  type: typeof GET_USERS_SUCCESS;
  payload: Users;
}

interface GetUsersFailure {
  type: typeof GET_USERS_FAILURE;
  payload: {
    error: string;
  };
}

interface GetUserByIdAction {
  type: typeof GET_USER_BY_ID_SUCCESS;
  payload: Profile | null;
}

interface GetUserByIdFailure {
  type: typeof GET_USER_BY_ID_FAILURE;
  payload: {
    error: string;
  };
}

interface RequestResetPasswordSuccess {
  type: typeof REQUEST_RESET_PASSWORD_SUCCESS;
  payload: {
    success: string;
  };
}

interface RequestResetPasswordFailure {
  type: typeof REQUEST_RESET_PASSWORD_FAILURE;
  payload: {
    error: string;
  };
}

interface ResetPasswordSuccess {
  type: typeof RESET_PASSWORD_SUCCESS;
  payload: {
    message: string;
  };
}

interface ResetPasswordFailure {
  type: typeof RESET_PASSWORD_FAILURE;
  payload: {
    error: string;
  };
}

interface UpdateUserFailure {
  type: typeof UPDATE_USER_FAILURE;
  payload: {
    error: string;
  };
}

export type UsersAction =
  | GetUsersAction
  | GetUsersFailure
  | GetUserByIdAction
  | GetUserByIdFailure
  | RequestResetPasswordSuccess
  | RequestResetPasswordFailure
  | ResetPasswordSuccess
  | ResetPasswordFailure
  | UpdateUserFailure;
