import axios, { AxiosError } from 'axios';
import { URL_PLATFORM } from 'configs/constant';
import { accessDenied, apiEnd, apiError, apiStart } from 'redux/api/apiAction';
import { API, FetchApi } from 'redux/api/apiTypes';

interface ApiProps {
  type: typeof API;
  payload: FetchApi;
}

const apiMiddleware =
  ({ dispatch }: any) =>
  (next: any) =>
  (action: ApiProps) => {
    next(action);

    if (action.type !== API) return;

    const {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride,
    } = action.payload;

    const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || '';
    axios.defaults.headers.common['Content-Type'] = 'application/json';

    if (label) {
      dispatch(apiStart(label));
    }

    let headers;

    if (accessToken) {
      headers = {
        ...headersOverride,
        Authorization: accessToken && `Token ${accessToken}`,
      };
    }

    axios
      .request({
        url,
        method,
        headers,
        [dataOrParams]: data,
      })
      .then(({ data }) => {
        if (data.data) {
          return dispatch(onSuccess(data?.data));
        }

        return dispatch(onSuccess(data));
      })
      .catch((error: AxiosError) => {
        dispatch(apiError(error));
        dispatch(onFailure(error));

        if (error.response && error.response.status === 403) {
          if (error.response.data.detail === 'access_token expired') {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('access');
            window.location.replace(`${URL_PLATFORM}/login`);
          }

          dispatch(accessDenied(window.location.pathname));
        }
      })
      .finally(() => {
        if (label) {
          dispatch(apiEnd(label));
        }
      });
  };

export default apiMiddleware;
