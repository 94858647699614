export const GET_ANOTATIONS = 'GET_ANOTATIONS';
export const GET_ANOTATIONS_SUCCESS = 'GET_ANOTATIONS_SUCCESS';
export const GET_ANOTATIONS_FAILURE = 'GET_ANOTATIONS_FAILURE';
export const GET_ANOTATION_BY_ID = 'GET_ANOTATION_BY_ID';
export const GET_ANOTATION_BY_ID_SUCCESS = 'GET_ANOTATION_BY_ID_SUCCESS';
export const GET_ANOTATION_BY_ID_FAILURE = 'GET_ANOTATION_BY_ID_FAILURE';
export const ADD_ANOTATION = 'ADD_ANOTATION';
export const ADD_ANOTATION_SUCCESS = 'ADD_ANOTATION_SUCCESS';
export const ADD_ANOTATION_FAILLURE = 'ADD_ANOTATION_FAILLURE';
export const RESET_ANOTATION = 'RESET_ANOTATION';
export const DELETE_ANOTATION = 'DELETE_ANOTATION';
export const DELETE_ANOTATION_SUCCESS = 'DELETE_ANOTATION_SUCCESS';
export const DELETE_ANOTATION_FAILLURE = 'DELETE_ANOTATION_FAILLURE';
export const UPDATE_ANOTATION = 'UPDATE_ANOTATION';

export interface IGeometry {
  geometry: {
    coordinates: any;
    type: string;
  };
  reversegeocode?: Record<string, string> & {
    result: Record<string, string>;
    addressparts: {
      postcode: string;
      state: string;
      country_code: string;
      country: string;
      // Kabupaten / Kota
      county?: string;
      city?: string;
      town?: string;
      // Kecamatan
      municipality?: string;
      city_district?: string;
      // Desa
      suburb?: string;
      village?: string;
    };
  };
}

export interface IAnotationAtribute {
  namaField: string;
  data: Record<string, any>[];
}

export interface Anotation {
  features: IGeometry;
  attributes: IAnotationAtribute;
  group: number;
}

export interface IAnotations {
  id: number;
  features: IGeometry;
  attributes: IAnotationAtribute;
  created_at: string;
  updated_at: string;
  group: number;
  user: number;
}

// Action
interface GetAnotationsAction {
  type: typeof GET_ANOTATIONS_SUCCESS;
  payload: IAnotations[];
}

interface ResetAnotations {
  type: typeof RESET_ANOTATION;
}

interface GetAnotationFailure {
  type: typeof GET_ANOTATIONS_FAILURE;
  payload: {
    error: string;
  };
}

interface GetAnotationByIdAction {
  type: typeof GET_ANOTATION_BY_ID_SUCCESS;
  payload: IAnotations | null;
}

interface GetAnotationByIdFailure {
  type: typeof GET_ANOTATION_BY_ID_FAILURE;
}

interface AddAnotationSucces {
  type: typeof ADD_ANOTATION_SUCCESS;
  payload: IAnotations;
}

interface AddAnotationFailure {
  type: typeof ADD_ANOTATION_FAILLURE;
  payload: {
    error: string;
  };
}

export interface AnotationUpdateProps {
  name: string;
  description: string;
}

interface UpdateAnotationAction {
  type: typeof UPDATE_ANOTATION;
  payload: AnotationUpdateProps;
}

interface DeleteAnotationSuccess {
  type: typeof DELETE_ANOTATION_SUCCESS;
  payload: number;
}

interface DeleteAnotationFailure {
  type: typeof DELETE_ANOTATION_FAILLURE;
  payload: {
    error: string;
  };
}

export type AnotationsAction =
  | GetAnotationsAction
  | GetAnotationFailure
  | ResetAnotations
  | GetAnotationByIdAction
  | GetAnotationByIdFailure
  | AddAnotationSucces
  | AddAnotationFailure
  | UpdateAnotationAction
  | DeleteAnotationSuccess
  | DeleteAnotationFailure;
