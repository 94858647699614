import css from '@styled-system/css';
import styled from 'styled-components';
import { FlexStyleProps } from '../Flex';
import { TooltipType } from './tooltips';

export type StyledTooltipsProps = FlexStyleProps & {
  position?: TooltipType;
};

export const StyledTooltips = styled.div<StyledTooltipsProps>`
  ${css({
    position: 'relative',
    display: 'inline-block',
    cursor: 'help',
    '.tooltips-text': {
      width: 'max-content',
      visibility: 'hidden',
      backgroundColor: '#555',
      color: '#fff',
      borderRadius: '6px',
      padding: 'normal',
      position: 'absolute',
      zIndex: 1,
      top: '-50%',
      left: '120%',
      opacity: 0,
      transition: 'opacity 0.3s',
      '&:after': {
        content: "''",
        position: 'absolute',
        top: 'calc(50% - 5px)',
        left: '-5px',
        marginLeft: '-5px',
        borderWidth: '5px',
        borderStyle: 'solid',
        borderColor: 'transparent #555  transparent transparent',
      },
    },
    '&:hover': {
      '.tooltips-text': {
        visibility: 'visible',
        opacity: 1,
      },
    },
  })}
`;
