import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Flex from 'components/atoms/Flex';
import Grid, { GridStyleProps } from 'components/atoms/Grid';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Spinner from 'components/atoms/Spinner';
import React from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFeedbackFormOpen } from 'redux/global/globalAction';
import { GlobalState } from 'redux/global/globalReducer';
import { AppState } from 'redux/store';
import styled from 'styled-components';

export interface IFeedbackFormProps {
  title?: string;
  loading?: boolean;
  disabledAction?: boolean;
  onSubmit: () => void;
}

const FormStyled = styled(Grid)<
  GridStyleProps & React.FormHTMLAttributes<HTMLFormElement>
>({
  gridAutoColumns: '1fr',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 65px',
  backgroundColor: 'white',
  borderBottomRightRadius: '8px',
  borderBottomLeftRadius: '8px',
  overflow: 'hidden',
});

const FeedbackForm: React.FC<IFeedbackFormProps> = ({
  title,
  loading = false,
  onSubmit,
  children,
  disabledAction = false,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { isFeedbackFormOpen } = useSelector<AppState, GlobalState>(
    (state) => state.global
  );

  const handleCloseFeedbackForm = useCallback(() => {
    dispatch(setIsFeedbackFormOpen(false));
  }, []);

  return (
    <Flex
      position="absolute"
      display={isFeedbackFormOpen ? 'flex' : 'none'}
      zIndex={10000}
      top="0"
      bottom="0"
      left="0"
      right="0"
      alignItems="center"
      justifyContent="center"
      backgroundColor="#0008"
      padding="24px"
      height="100vh"
      overflow="hidden"
      {...rest}
    >
      <Grid
        flexDirection="column"
        width={['100%', '500px']}
        height="100%"
        position="relative"
        gridAutoColumns="1fr"
        gridTemplateColumns="1fr"
        gridTemplateRows="47px auto"
      >
        {loading && (
          <Spinner
            position="absolute"
            backgroundColor="#ffffff80"
            top="0"
            left="0"
            right="0"
            bottom="0"
            borderRadius="8px"
            spinnerSize={50}
          />
        )}

        <Flex
          backgroundColor="primary"
          borderTopRightRadius="8px"
          borderTopLeftRadius="8px"
          paddingY="normal"
          paddingX="large"
          position="relative"
          justifyContent="space-between"
          alignItems="center"
        >
          <Heading color="background" variant="brand">
            {title}
          </Heading>

          <Button
            variant="secondary"
            size="20px"
            minHeight={['20px', '20px']}
            minWidth={['20px', '20px']}
            padding="small"
            rounded={true}
            onClick={handleCloseFeedbackForm}
          >
            <Icon fontSize="small">close</Icon>
          </Button>
        </Flex>

        <FormStyled as="form" onSubmit={onSubmit}>
          <Box padding="large" overflow="auto">
            {children}
          </Box>

          <Flex
            borderTop="thin"
            borderColor="secondaryLighten"
            paddingX="large"
            paddingY="medium"
            justifyContent="flex-end"
            gapX="medium"
          >
            <Button
              border="thin"
              borderColor="secondaryLighten"
              variant="transparent"
              width="auto"
              onClick={handleCloseFeedbackForm}
            >
              Batal
            </Button>

            <Button width="auto" type="submit" disabled={disabledAction}>
              Kirim
            </Button>
          </Flex>
        </FormStyled>
      </Grid>
    </Flex>
  );
};

export default FeedbackForm;
