import { API_ENDPOINT } from 'configs/constant';
import fetchApi from 'redux/api/apiAction';
import { TFetchToken } from 'redux/api/apiTypes';
import {
  ADD_ANOTATION,
  ADD_ANOTATION_FAILLURE,
  DELETE_ANOTATION,
  GET_ANOTATIONS,
  GET_ANOTATIONS_SUCCESS,
  GET_ANOTATION_BY_ID,
  GET_ANOTATION_BY_ID_SUCCESS,
  Anotation,
  AnotationsAction,
  AnotationUpdateProps,
  UPDATE_ANOTATION,
  // IAnotations,
  GET_ANOTATIONS_FAILURE,
  RESET_ANOTATION,
  GET_ANOTATION_BY_ID_FAILURE,
  IAnotations,
  DELETE_ANOTATION_FAILLURE,
  DELETE_ANOTATION_SUCCESS,
  ADD_ANOTATION_SUCCESS,
} from './anotationTypes';

export const getAnotations = (token: TFetchToken, groupId: number) => {
  return fetchApi({
    url: `${API_ENDPOINT}annotations/datamap?group_id=${groupId}`,
    label: GET_ANOTATIONS,
    onSuccess: getAnotationsSuccess,
    onFailure: getAnotationsFailure,
    accessToken: token,
  });
};

const getAnotationsSuccess = (payload: any): AnotationsAction => {
  console.log('GET ANOTATION SUCCESS : ', payload);

  return {
    type: GET_ANOTATIONS_SUCCESS,
    payload,
  };
};

const getAnotationsFailure = ({ response }: any): AnotationsAction => {
  console.log('GET ANOTATION FAILURE : ', response);

  return {
    type: GET_ANOTATIONS_FAILURE,
    payload: {
      error: response?.data?.data?.detail || 'Oops somethin wrong !',
    },
  };
};

export const resetAnotations = (): AnotationsAction => ({
  type: RESET_ANOTATION,
});

export const getAnotationById = (
  groupId: number,
  id: number,
  token: TFetchToken,
  action?: (payload: IAnotations) => void
) => {
  return fetchApi({
    url: `${API_ENDPOINT}anotations/datamap/${id}/?group_id=${groupId}`,
    label: GET_ANOTATION_BY_ID,
    onSuccess: (result: any) => console.log('GET ANOTATIION', result),
    onFailure: getAnotationByIdFailure,
    accessToken: token,
  });
};

export const getAnotationByIdSuccess = (
  payload: IAnotations,
  action?: (payload: IAnotations) => void
): AnotationsAction => {
  if (action) {
    action(payload);
  }

  return {
    type: GET_ANOTATION_BY_ID_SUCCESS,
    payload,
  };
};

export const getAnotationByIdFailure = (): AnotationsAction => {
  return {
    type: GET_ANOTATION_BY_ID_FAILURE,
  };
};

export const addAnotation = (
  token: TFetchToken,
  payload: Anotation,
  action?: () => void
) => {
  console.log('ADD ANOTATION PAYLOAD', payload);

  return fetchApi({
    url: `${API_ENDPOINT}annotations/datamap/`,
    method: 'POST',
    label: ADD_ANOTATION,
    onSuccess: (result: IAnotations) => addAnotationSuccess(result, action),
    onFailure: addAnotationFailure,
    data: payload,
    accessToken: token,
  });
};

const addAnotationSuccess = (
  payload: IAnotations,
  action?: () => void
): AnotationsAction => {
  console.log('ADD ANOTATION SUCCESS : ', payload);

  if (action) {
    action();
  }

  return {
    type: ADD_ANOTATION_SUCCESS,
    payload,
  };
};

export const addAnotationFailure = ({ response }: any): AnotationsAction => {
  console.log('ADD ANOTATION FAILURE : ', response);

  let errorMessage = 'Oops somethin wrong !';

  if (response?.data?.data?.group) {
    errorMessage = response.data.data.group[0];
  }

  if (response?.data?.data?.error) {
    errorMessage = response.data.data.error;
  }

  return {
    type: ADD_ANOTATION_FAILLURE,
    payload: {
      error: errorMessage,
    },
  };
};

export const updateAnotation = (
  payload: AnotationUpdateProps
): AnotationsAction => {
  return {
    type: UPDATE_ANOTATION,
    payload,
  };
};

export const deleteAnotation = (
  token: TFetchToken,
  groupId: number,
  id: number,
  action?: () => void
) =>
  fetchApi({
    url: `${API_ENDPOINT}annotations/datamap/${id}`,
    method: 'DELETE',
    label: DELETE_ANOTATION,
    onSuccess: () => deleteAnotationSuccess(id, action),
    onFailure: deleteAnotationFailure,
    data: {
      group_id: groupId,
    },
    accessToken: token,
  });

const deleteAnotationSuccess = (
  id: number,
  action?: () => void
): AnotationsAction => {
  console.log('DELETE ANOTATION SUCCESS : ', id);

  if (action) {
    action();
  }

  return {
    type: DELETE_ANOTATION_SUCCESS,
    payload: id,
  };
};

const deleteAnotationFailure = ({ response }: any): AnotationsAction => {
  console.log('DELETE ANOTATION FAILURE : ', response);

  let errorMessage = 'Oops somethin wrong !';

  if (response?.data?.data?.group) {
    errorMessage = response.data.data.group[0];
  }

  if (response?.data?.data?.error) {
    errorMessage = response.data.data.error;
  }

  return {
    type: DELETE_ANOTATION_FAILLURE,
    payload: {
      error: errorMessage,
    },
  };
};
