import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Input from 'components/atoms/Input';
import Spinner from 'components/atoms/Spinner';
import { Caption, Paragraph } from 'components/atoms/Typography';
import Field from 'components/molecules/Field';
import PopUpView from 'components/organisms/PopUpView';
import React from 'react';
import { useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ApiState } from 'redux/api/apiReducer';
import { getInviteByLink } from 'redux/group/groupAction';
import { GroupState } from 'redux/group/groupReducer';
import { AppState } from 'redux/store';

const GroupJoin: React.FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const { errorGetInviteByLink } = useSelector<AppState, GroupState>(
    ({ group }) => group
  );
  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  const { handleSubmit, control } = useForm<{ token: string }>({
    defaultValues: {
      token: '',
    },
  });

  const onSubmit = useCallback((data: { token: string }) => {
    const token = localStorage.getItem('token');

    dispatch(getInviteByLink(token, data.token, push));
  }, []);

  return (
    <PopUpView title="Gabung Proyek" closePath="/">
      <Box paddingX="medium" paddingY="large">
        {isLoadingData && (
          <>
            <Spinner
              spinnerSize={50}
              position="absolute"
              backgroundColor="#ffffff80"
              top="0"
              left="0"
              right="0"
              bottom="0"
              borderRadius="8px"
            />
            <Paragraph>Menunggu untuk join proyek</Paragraph>
          </>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="token"
            control={control}
            rules={{
              required: 'Kolom ini tidak boleh kosong !',
            }}
            render={({ field, fieldState: { error } }) => (
              <Field label="Token Proyek" error={error?.message}>
                <Input
                  as="textarea"
                  rows={2}
                  placeholder="Masukan token proyek..."
                  {...field}
                  error={!!error}
                />
              </Field>
            )}
          />

          <Button marginTop="large" type="submit">
            Gabung
          </Button>
        </form>
        {errorGetInviteByLink && (
          <Caption variant="error">{errorGetInviteByLink}</Caption>
        )}
      </Box>
    </PopUpView>
  );
};

export default GroupJoin;
