import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Flex from 'components/atoms/Flex';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Spinner from 'components/atoms/Spinner';
import { Caption, Paragraph } from 'components/atoms/Typography';
import ListData from 'components/molecules/ListData';
import ItemList from 'components/organisms/ItemList';
import PopUpView from 'components/organisms/PopUpView';
import Table from 'components/organisms/Table';
import useCheckMemberLevel from 'hooks/checkLevelMember';
import React, { useCallback } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  deleteAnotation,
  getAnotationByIdFailure,
  getAnotationByIdSuccess,
} from 'redux/anotation/anotationAction';
import { IAnotations } from 'redux/anotation/anotationTypes';
import { ApiState } from 'redux/api/apiReducer';
import { setModalOpen } from 'redux/global/globalAction';
import { getGroupKey } from 'redux/group/groupAction';
import { GroupState } from 'redux/group/groupReducer';
import { AppState } from 'redux/store';

const AnotationDetail: React.FC = () => {
  const dispatch = useDispatch();
  const { location, push }: any = useHistory();

  const token = localStorage.getItem('token');

  const { groupId }: any = useParams();
  const memberLevel = useCheckMemberLevel(groupId);

  const groupByIdData: IAnotations = location.state?.result;

  const { groupKeys, errorGroupKey } = useSelector<AppState, GroupState>(
    (props) => props.group
  );

  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  // Handle Data Anotation Table
  const dataTableAnotation = useMemo(() => {
    const tableData: any[] = [];

    const rowData = {
      Tanggal: new Date(groupByIdData.created_at).toLocaleDateString('id-ID', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }),
      id: groupByIdData.id,
      Nama: groupByIdData.attributes.namaField,
      Kabupaten:
        groupByIdData.features.reversegeocode?.addressparts.county ||
        groupByIdData.features.reversegeocode?.addressparts.city ||
        groupByIdData.features.reversegeocode?.addressparts.town,
      Kecamatan:
        groupByIdData.features.reversegeocode?.addressparts.municipality ||
        groupByIdData.features.reversegeocode?.addressparts.city_district,
      Desa:
        groupByIdData.features.reversegeocode?.addressparts.village ||
        groupByIdData.features.reversegeocode?.addressparts.suburb,
      'Kode Pos': groupByIdData.features.reversegeocode?.addressparts.postcode,
      Koordinat: groupByIdData.features.geometry.coordinates.toString(),
    };

    groupByIdData.attributes.data.forEach((atribute) => {
      return tableData.push({
        ...rowData,
        ...atribute,
      });
    });

    return tableData;
  }, [groupByIdData]);

  // Handle open modal if delete anotation
  const handleDeleteAnotation = useCallback(
    (id: number) => {
      dispatch(
        setModalOpen({
          isOpen: true,
          title: `Hapus Data ?`,
          description: 'Apakah anda yakin untuk menghapus data ?',
          actionText: 'Hapus',
          secondActionText: 'Batal',
          action: () =>
            dispatch(
              deleteAnotation(token, groupId, id, () =>
                push(`/group/${groupId}`)
              )
            ),
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [groupId, token]
  );

  useEffect(() => {
    dispatch(getGroupKey(token, groupId));

    dispatch(getAnotationByIdSuccess(groupByIdData));

    return () => {
      dispatch(getAnotationByIdFailure());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    groupByIdData,
    groupId,
    location.state.result.features.geometry.coordinates,
    token,
  ]);

  const DataListView = useMemo(() => {
    return location.state?.result?.attributes.data.map((result: any) => {
      return (
        <ItemList
          key={result.id}
          title={result.id}
          borderX="none !important"
          borderBottom="none !important"
          borderRadius="0 !important"
          hoverScale={false}
          leftPosition="center"
        >
          <ListData
            data={{ data: [result] }}
            columnArray={groupKeys}
            marginTop="small"
          />
        </ItemList>
      );
    });
  }, [groupKeys, location.state?.result?.attributes.data]);

  return (
    <>
      <PopUpView
        title={location.state?.result?.attributes.namaField || '...'}
        width={['', '400px']}
      >
        {isLoadingData ? (
          <Spinner
            spinnerSize={50}
            position="absolute"
            backgroundColor="#ffffff80"
            top="0"
            left="0"
            right="0"
            bottom="0"
          />
        ) : errorGroupKey ? (
          <Paragraph variant="error">{errorGroupKey}</Paragraph>
        ) : (
          <Box gapY="large" paddingX="large" paddingY="large">
            <Caption color="primary" fontWeight="bold">
              Alamat
            </Caption>

            <Paragraph marginBottom="medium" marginTop="small">
              {location.state?.result?.features.reversegeocode.result['#text']}
            </Paragraph>

            <Box
              border="thin"
              borderColor="secondaryLighten"
              borderRadius="8px"
            >
              <Flex
                justifyContent="space-between"
                alignItems="center"
                paddingY="small"
                paddingRight="small"
              >
                <Heading as="h2" variant="title" paddingX="normal" margin="0">
                  Data ({location.state?.result?.attributes.data.length})
                </Heading>
              </Flex>

              {location.state?.result && DataListView}
            </Box>

            {memberLevel !== 'guest' && (
              <Button
                variant="error"
                onClick={() => handleDeleteAnotation(location.state?.result.id)}
              >
                <Icon color="white" marginRight="normal">
                  delete
                </Icon>
                Hapus Data
              </Button>
            )}
          </Box>
        )}
      </PopUpView>

      {dataTableAnotation.length > 0 && <Table data={dataTableAnotation} />}
    </>
  );
};

export default AnotationDetail;
