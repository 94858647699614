import css from '@styled-system/css';
import Box, { BoxStyleProps } from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import { ButtonVariantProps } from 'components/atoms/Button/button.style';
import Icon from 'components/atoms/Icon';
import ListLink, { ListLinkProps } from 'components/molecules/ListLink';
import useClickOutside from 'hooks/useClickOutside';
import useScreeType from 'hooks/useScreenType';
import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';

export type TFloatingListDataProps = {
  text: string;
  link: ListLinkProps;
}[];

export type TFloatingListProps = BoxStyleProps & {
  listData: TFloatingListDataProps;
  icon: string;
  variant?: ButtonVariantProps;
  size?: string | string[];
  padding?: string;
  iconSize?: string | string[];
};

const FloatingButtonStyle = styled(Box)(
  css({
    position: ['fixed', 'absolute'],
    bottom: '0',
    width: ['50px', 'fill-available'],
    right: ['0', ''],
    margin: 'medium',
  })
);

const FloatingListStyle = styled(Box)(
  css({
    position: 'absolute',
    backgroundColor: 'background',
    width: '220px',
    right: 0,
    bottom: '110%',
    margin: 0,
    borderRadius: 'medium',
    boxShadow: 'small',
    zIndex: 20,
    overflow: 'hidden',
  })
);

const FloatingButton: React.FC<TFloatingListProps> = ({
  icon,
  listData,
  variant,
  size,
  padding,
  iconSize,
  children,
  ...rest
}) => {
  const screenType = useScreeType();
  const wrapper = useRef(null);

  const [isFloatingButtonOpen, setIsFloatingButtonOpen] =
    useState<boolean>(false);

  const closeListButton = useCallback((action?: () => void) => {
    setIsFloatingButtonOpen(false);

    if (action) {
      action();
    }
  }, []);

  const handleButtonList = (event: any) => {
    event.preventDefault();
    setIsFloatingButtonOpen(!isFloatingButtonOpen);
  };

  useClickOutside(wrapper, closeListButton);

  return (
    <FloatingButtonStyle ref={wrapper} {...rest}>
      {isFloatingButtonOpen && (
        <FloatingListStyle>
          {listData.map(({ text, link: { to, action, ...linkRest } }) => (
            <ListLink
              key={text}
              to={to}
              action={() => closeListButton(action)}
              children={text}
              {...linkRest}
            />
          ))}
        </FloatingListStyle>
      )}

      <Button
        variant={variant}
        rounded={screenType === 'mobile' && true}
        height={['50px', 'auto']}
        padding={padding}
        onClick={handleButtonList}
      >
        <Icon marginRight={['', 'small']}>{icon}</Icon>
        {screenType !== 'mobile' && children}
      </Button>
    </FloatingButtonStyle>
  );
};

export default FloatingButton;

// <FloatingButtonStyle
//   as={as}
//   variant={variant}
//   ref={ref as React.MutableRefObject<HTMLButtonElement>}
//   rounded={screenType === 'mobile' && true}
//   {...rest}
// >
//   <Icon marginRight={['', 'small']}>{icon}</Icon>

//   {screenType !== 'mobile' && children}
// </FloatingButtonStyle>;
