export const SET_FORM_DATA = 'SET_FORM_DATA';
export const RESET_FORM_DATA = 'RESET_FORM_DATA';

interface IFormDataAction {
  type: typeof SET_FORM_DATA;
  payload: object;
}

interface IResetFormDataAction {
  type: typeof RESET_FORM_DATA;
}

export type FormActionTypes = IFormDataAction | IResetFormDataAction;
