/* eslint-disable react-hooks/exhaustive-deps */
import { Paragraph } from 'components/atoms/Typography';
import { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setModalOpen } from 'redux/global/globalAction';
import { setLocation } from 'redux/map/mapAction';

const navigatorLocationOptions = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const useCheckLocationPermisison = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const ModalLocationDenied = useMemo(
    () => (
      <Paragraph>
        Anda sepertinya tidak mengijinkan kami melihat lokasi anda.
        <br />
        Anda harus mengatur ulang perizinan lokasi untuk memasukan data sesuai
        lokasi anda atau anda dapat mengatur lokasi sesuai peta.
      </Paragraph>
    ),
    []
  );

  const showModalLocationDenied = useCallback(() => {
    dispatch(
      setModalOpen({
        title: 'Lokasi Tidak Diijinkan',
        description: ModalLocationDenied,
        actionText: 'Atur ulang perizinan lokasi ?',
        secondActionText: 'Tutup',
        action: () => push('/help/location-permission'),
      })
    );
  }, []);

  const handleGetMyLocation = useCallback((action?: () => void) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            description: 'Lokasimu saat ini',
          })
        );

        if (action) {
          action();
        }
      },
      (error) => {
        if (error.code === error.PERMISSION_DENIED) {
          showModalLocationDenied();
        }
      },
      navigatorLocationOptions
    );
  }, []);

  const showModalLocationPrompt = useCallback((action?: () => void) => {
    dispatch(
      setModalOpen({
        title: 'Persetujuan Lokasi',
        description:
          'Aktifkan lokasi anda untuk memasukan data sesuai lokasi atau anda dapat menentukan lokasi melalui map',
        actionText: 'Aktifkan Lokasi',
        secondActionText: 'Batal',
        action: () => handleGetMyLocation(action),
      })
    );
  }, []);

  const showBrowserNotSupported = useCallback(() => {
    dispatch(
      setModalOpen({
        title: 'Perangkat kamu tidak disuport',
        description: 'Perangkat kamu tidak bisa mendapatkan lokasi',
        secondActionText: 'Tutup',
      })
    );
  }, []);

  const getLocationPermission = useCallback((action?: () => void) => {
    navigator.permissions
      .query({ name: 'geolocation' })
      .then(({ state }) => {
        switch (state) {
          case 'prompt':
            return showModalLocationPrompt(action);

          case 'denied':
            return showModalLocationDenied();

          default:
            return handleGetMyLocation(action);
        }
      })
      .catch((err) => {
        console.error('Navigator permission query : ', err);
        return showBrowserNotSupported();
      });
  }, []);

  return {
    getLocationPermission,
    showModalLocationPrompt,
    showModalLocationDenied,
  };
};

export default useCheckLocationPermisison;
