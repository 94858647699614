import css from '@styled-system/css';
import Flex from 'components/atoms/Flex';
import Grid, { GridStyleProps } from 'components/atoms/Grid';
import Heading, { HeadingProps } from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import { Caption, Paragraph } from 'components/atoms/Typography';
import { useCallback } from 'react';
import styled from 'styled-components';

export type TItemListProps = GridStyleProps & {
  title?: string;
  subtitle?: string | React.ReactNode;
  description?: string;
  rightIcon?: string;
  left?: React.ReactNode;
  leftPosition?: string;
  hoverScale?: boolean;
};

const ItemListStyle = styled(Grid)<TItemListProps>`
  ${css({
    padding: 'normal',
    border: 'thin',
    borderColor: 'secondaryLighten',
    borderRadius: 'medium',
    gridTemplateColumns: 'auto 1fr auto',
    gridTemplateRows: '1fr',
    columnGap: 'normal',
  })}

  ${({ hoverScale }) =>
    hoverScale &&
    css({
      '&:hover': {
        transform: 'scale(1.03)',
        cursor: 'pointer',
      },
    })}
`;

const Title = styled(Heading)<HeadingProps>(
  css({
    wordBreak: 'break-word',
  })
);

const ItemList: React.FC<TItemListProps> = ({
  title = '',
  subtitle,
  description,
  rightIcon,
  left,
  children,
  leftPosition = 'start',
  hoverScale = true,
  ...rest
}) => {
  const getFirstCaracter = useCallback(({ word }: { word: string }) => {
    const wordResult = word.match(/\b(\w)/g);

    return wordResult && wordResult.join('').toUpperCase().substr(0, 2);
  }, []);

  return (
    <ItemListStyle as="article" hoverScale={hoverScale} {...rest}>
      <Flex
        borderRadius="8px"
        size="50px"
        alignItems="center"
        justifyContent="center"
        padding="normal"
        backgroundColor={rightIcon ? 'primary' : 'secondary'}
      >
        <Heading variant="heading" color="white" as="h2">
          {rightIcon ? (
            <Icon color="white" fontSize="28px" marginTop="small">
              {rightIcon}
            </Icon>
          ) : (
            getFirstCaracter({ word: title })
          )}
        </Heading>
      </Flex>

      <Flex justifyContent="center" flexDirection="column">
        <Title variant="title">{title}</Title>

        {subtitle && typeof subtitle === 'string' ? (
          <Caption
            as="span"
            color="secondary"
            marginBottom="small"
            fontSize="0.75rem"
          >
            {subtitle}
          </Caption>
        ) : (
          subtitle
        )}

        {description && (
          <Paragraph margin={0} truncate={2}>
            {description}
          </Paragraph>
        )}

        {children}
      </Flex>

      {left && <Flex alignItems={leftPosition}>{left}</Flex>}
    </ItemListStyle>
  );
};

export default ItemList;
