import styled from 'styled-components';

export interface StyledSpinnerProps {
  size: number;
}

export const StyledSpinner = styled.img<StyledSpinnerProps>`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;
