import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Flex from 'components/atoms/Flex';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Spinner from 'components/atoms/Spinner';
import { Caption, Paragraph } from 'components/atoms/Typography';
import CardList from 'components/molecules/CardList';
import DropdownMenu, {
  TDropdownListDataProps,
} from 'components/molecules/DropdownMenu';
import ItemList from 'components/organisms/ItemList';
import SideView from 'components/organisms/SideView';
import React from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ApiState } from 'redux/api/apiReducer';
import { getGroups } from 'redux/group/groupAction';
import { GroupState } from 'redux/group/groupReducer';
import { AppState } from 'redux/store';

const groupDropdownList: TDropdownListDataProps = [
  {
    text: 'Buat Proyek',
    link: {
      to: '/group/create',
      iconLeft: 'group_add',
    },
  },
  {
    text: 'Gabung Proyek',
    link: {
      to: '/group/join',
      iconLeft: 'groups',
    },
  },
];

const Group: React.FC = () => {
  const dispatch = useDispatch();

  const { groups, errorGetGroups } = useSelector<AppState, GroupState>(
    ({ group }) => group
  );

  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  useEffect(() => {
    if (groups.results.length === 0) {
      const token = localStorage.getItem('token');
      dispatch(getGroups(token));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GroupDataView = useMemo(() => {
    if (groups.results.length > 0) {
      return groups.results.map((result) => (
        <Box as="li" key={result.id}>
          <Link to={`group/${result.id}`}>
            <ItemList
              title={result.name}
              subtitle={
                <Flex marginBottom="small">
                  <Icon fontSize="0.75rem" marginRight="2px" color="secondary">
                    schedule
                  </Icon>

                  <Caption as="span" color="secondary" fontSize="0.75rem">
                    {new Date(result.created_at).toLocaleDateString('id-ID', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    })}
                  </Caption>
                </Flex>
              }
              description={result.description}
              left={
                <DropdownMenu
                  variant="transparent"
                  icon="more_horiz"
                  size="30px"
                  padding="0px"
                  listData={[
                    {
                      text: 'Info Proyek',
                      link: {
                        to: `group/${result.id}/detail`,
                        iconLeft: 'group_add',
                      },
                    },
                  ]}
                />
              }
            />
          </Link>
        </Box>
      ));
    } else {
      return (
        <CardList
          title="Buat Proyek Pertamamu"
          description="Buat Proyek untuk berkolaborasi bersama Tim-mu"
          icon="group"
        >
          <Link to="/group/create">
            <Button variant="primary">
              <Icon marginRight="normal" color="white">
                group_add
              </Icon>
              Buat Proyek
            </Button>
          </Link>

          <Link to="/group/join">
            <Button
              variant="transparent"
              marginTop="large"
              border="thin"
              borderColor="secondaryLighten"
            >
              <Icon marginRight="normal">groups</Icon>
              Gabung Proyek
            </Button>
          </Link>
        </CardList>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groups.results]);

  return (
    <SideView
      rightIcon={
        <DropdownMenu
          variant="transparent"
          icon="more_vert"
          listData={groupDropdownList}
        />
      }
    >
      <Box as="ul" gapY="normal" margin="0" paddingX="large" paddingY="large">
        <Heading variant="heading" as="h2" marginBottom="normal">
          Proyek
        </Heading>

        {isLoadingData ? (
          <Spinner
            spinnerSize={50}
            position="absolute"
            backgroundColor="#ffffff80"
            top="0"
            left="0"
            right="0"
            bottom="0"
          />
        ) : errorGetGroups ? (
          <Paragraph variant="error">{errorGetGroups}</Paragraph>
        ) : (
          GroupDataView
        )}
      </Box>
    </SideView>
  );
};

export default Group;
