import React from 'react';
import { StyledSpinner } from './styles';
import SpinnerDark from 'assets/icons/Spinner_Black.svg';
import SpinnerLight from 'assets/icons/Spinner_White.svg';
import Flex, { FlexStyleProps } from '../Flex';

export type SpinnerType = 'dark' | 'light';

export type SpinnerProps = {
  spinnerSize?: number;
  type?: SpinnerType;
};

const Spinner: React.FC<SpinnerProps & FlexStyleProps> = ({
  spinnerSize = 30,
  type = 'dark',
  ...rest
}) => {
  return (
    <Flex alignItems="center" justifyContent="center" {...rest} zIndex={1000}>
      <StyledSpinner
        size={spinnerSize}
        src={type === 'dark' ? SpinnerDark : SpinnerLight}
        alt="Loading Spinner Icon"
      />
    </Flex>
  );
};

export default Spinner;
