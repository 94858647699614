import css from '@styled-system/css';
import styled from 'styled-components';
import {
  color,
  compose,
  layout,
  space,
  typography,
  variant,
} from 'styled-system';
import {
  truncateStyle,
  TypographyStyleProps,
} from '../Typography/typography.style';

export type HeadingStyleProps = TypographyStyleProps;

export type HeadingVariantProps = 'brand' | 'heading' | 'title';

const variants: { [key in HeadingVariantProps]: object } = {
  brand: {
    fontSize: 'brand',
    fontWeight: 'bold',
  },
  heading: {
    fontSize: 'heading',
    fontWeight: 'bold',
  },
  title: {
    fontSize: 'title',
    fontWeight: 'bold',
  },
};

const HeadingStyle = styled.h1<HeadingStyleProps>`
  ${css({
    color: 'primary',
    marginY: '0',
    lineHeight: 'heading',
  })}
  ${({ truncate }) => truncate && truncateStyle}

  ${variant({ variants })}
  ${compose(color, typography, space, layout)}
`;

export default HeadingStyle;
