import css from '@styled-system/css';
import styled from 'styled-components';
import {
  border,
  color,
  compose,
  gridArea,
  layout,
  shadow,
  space,
  typography,
  TypographyProps,
  variant,
} from 'styled-system';
import Box, { BoxStyleProps } from '../Box';

export type SelectStyleProps = BoxStyleProps &
  TypographyProps & {
    error?: boolean;
  };

export type SelectVariantProps = 'primary' | 'secondary' | 'transparent';

const variants: { [key in SelectVariantProps]: object } = {
  primary: {
    border: 'thin',
    borderColor: 'primaryLighten',
    '&:focus': {
      borderColor: 'primary',
    },
    color: 'text',
  },
  secondary: {
    border: 'thin',
    borderColor: 'secondaryLighten',
    '&:focus': {
      borderColor: 'secondary',
    },
    color: 'text',
  },
  transparent: {
    border: 'none',
    '&:focus': {
      backgroundColor: 'secondaryLighten',
    },
    color: 'text',
  },
};

const SelectStyle = styled(Box)<SelectStyleProps>`
  min-width: 40px;
  min-height: 40px;

  ${css({
    width: '100%',
    paddingX: 'medium',
    paddingY: 'small',
    lineHeight: 'heading',
    fontSize: 'paragraph',
    borderRadius: 'small',
    '&:disabled': {
      opacity: 0.6,
      filter: 'saturate(60%)',
    },
  })}
  ${variant({ variants })}

  ${({ error }) =>
    error &&
    css({
      border: 'thin',
      borderColor: 'errorLighten',
      '&:focus': {
        borderColor: 'error',
      },
      color: 'error',
    })}

  ${compose(space, color, border, layout, gridArea, shadow, typography)}
`;

export default SelectStyle;
