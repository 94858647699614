import { applyMiddleware, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
import apiMiddleware from './middleware/apiMiddleware';

export type AppState = ReturnType<typeof rootReducer>;

let middleware: any[] = [apiMiddleware, thunk];
if (process.env.NODE_ENV === 'development') {
  middleware = [...middleware, logger];
}

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
