import React from 'react';
import Field from 'components/molecules/Field';
import { EMAIL_REGEX } from 'configs/constant';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from 'components/atoms/Input';
import Button from 'components/atoms/Button';
import { ILoginProps, login } from 'redux/auth/authAction';
import { useDispatch, useSelector } from 'react-redux';
import { Caption } from 'components/atoms/Typography';
import { AppState } from 'redux/store';
import { AuthState } from 'redux/auth/authReducer';
import AuthTemplate, {
  TAuthTemplateLink,
} from 'components/templates/AuthTemplate';
import { ApiState } from 'redux/api/apiReducer';
import InputPassword from 'components/atoms/Input/InputPassword/inputPassword';

const linkLogin: TAuthTemplateLink = [
  { path: '/forgot', text: 'Lupa password ?' },
  { path: '/register', text: 'Belum punya akun ?' },
];

const Login: React.FC = () => {
  const dispatch = useDispatch();

  const { handleSubmit, control } = useForm<ILoginProps>({
    defaultValues: {
      username: '',
      password: '',
    },
    mode: 'onChange',
  });

  const { errorLogin } = useSelector<AppState, AuthState>(({ auth }) => auth);
  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  const onSubmit: SubmitHandler<ILoginProps> = (data) => {
    dispatch(login(data));
  };

  return (
    <AuthTemplate
      title="Masuk ke Zoonostic"
      link={linkLogin}
      loading={isLoadingData}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="username"
          rules={{
            required: 'Kolom ini tidak boleh kosong !',
            pattern: {
              value: EMAIL_REGEX,
              message: 'Email tidak valid !',
            },
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Field label="Email" error={error?.message}>
              <Input
                {...field}
                error={!!error}
                title="Kolom Email"
                placeholder="Email"
              />
            </Field>
          )}
        />

        <Controller
          name="password"
          rules={{
            required: 'Kolom ini tidak boleh kosong !',
            minLength: {
              value: 8,
              message: 'Minimal 8 karakter',
            },
          }}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <Field label="Password" error={error?.message}>
              <InputPassword
                input={{ ...field, error: !!error, placeholder: 'Password' }}
              />
            </Field>
          )}
        />

        {errorLogin && <Caption color="error">{errorLogin}</Caption>}

        <Button marginY="large" as="input" type="submit" value="Masuk" />
      </form>
    </AuthTemplate>
  );
};

export default Login;
