import React, { useState } from 'react';
import Spinner from 'components/atoms/Spinner';
import PopUpView from 'components/organisms/PopUpView';
import { useDispatch, useSelector } from 'react-redux';
import { ApiState } from 'redux/api/apiReducer';
import { AppState } from 'redux/store';
import Box from 'components/atoms/Box';
import { GroupState } from 'redux/group/groupReducer';
import { Caption } from 'components/atoms/Typography';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { getGroupActivities } from 'redux/group/groupAction';
import DataTable, { TableColumn } from 'react-data-table-component';
import { GroupActivity as Activity } from 'redux/group/groupTypes';
import DateRangePicker, {
  DateFilter,
} from 'components/molecules/DateRangePicker';
import { useParams } from 'react-router-dom';
import Heading from 'components/atoms/Heading';
import Flex from 'components/atoms/Flex';

// Column of activity table
const columns: TableColumn<Activity>[] = [
  {
    name: 'Tanggal',
    selector: (row) =>
      new Date(row.created_at).toLocaleString('id-ID', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }),
    sortable: true,
    maxWidth: '150px',
  },
  {
    name: 'Waktu',
    selector: (row) =>
      new Date(row.created_at).toLocaleString('id-ID', {
        hour: '2-digit',
        minute: '2-digit',
      }),
    sortable: true,
    width: '85px',
  },
  {
    name: 'Email',
    selector: (row) => row.user.email,
    width: '150px',
  },
  {
    name: 'Username',
    selector: (row) => row.user.username,
    width: '150px',
  },
  {
    name: 'Aktifitas',
    selector: (row) => row.activity,
    minWidth: '300px',
    maxWidth: '100%',
  },
];

const GroupActivityTable: React.FC<{
  data: Activity[];
  rows: number;
  page: number;
  setPage: (page: number) => void;
  limit: number;
  setLimit: (page: number) => void;
  loading: boolean;
}> = ({ data, rows, page, setPage, limit, setLimit, loading }) => {
  return (
    <DataTable
      title=""
      highlightOnHover
      pointerOnHover
      persistTableHead
      columns={columns}
      theme="default"
      data={data}
      progressPending={loading}
      progressComponent={
        <Spinner spinnerSize={50} backgroundColor="#ffffff80" height="200px" />
      }
      pagination
      paginationServer
      paginationTotalRows={rows}
      paginationDefaultPage={page}
      onChangePage={setPage}
      customStyles={{
        head: {
          style: {
            borderTop: '1px solid #D0D0D0',
          },
        },
      }}
    />
  );
};

const GroupActivity: React.FC = () => {
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const { groupId }: any = useParams();

  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);
  const { groupActivities, errorGetGroupActivities } = useSelector<
    AppState,
    GroupState
  >(({ group }) => group);

  // State for filtering data
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(10);

  const currentDate = new Date();

  const [dateFilter, setDateFilter] = useState<DateFilter>({
    start_date: new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
    end_date: currentDate,
  });

  // Get Filtered Group Activities Data
  const filteredActivities = useMemo(
    (value?: string[]) =>
      groupActivities?.results?.filter((activity) => {
        if (value) {
          return value.some(
            (filter: any) =>
              filter.value === activity.created_at ||
              filter.value === activity.activity ||
              filter.value === activity.user
          );
        }

        return activity;
      }),
    [groupActivities?.results]
  );

  useEffect(() => {
    dispatch(
      getGroupActivities(token, groupId, {
        page,
        limit,
        dateFilter,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFilter, groupId, page, limit, token]);

  return (
    <PopUpView title="Aktifitas Anggota" width={['', 'calc(100vw - 56px)']}>
      <Box overflowX="auto" padding="large">
        {errorGetGroupActivities && (
          <Caption variant="error">{errorGetGroupActivities}</Caption>
        )}

        <Box
          border="thin"
          borderColor="secondaryLighten"
          borderRadius="4px"
          padding="large"
        >
          <Flex alignItems="center" gapX="normal">
            <Heading variant="title" as="span" marginBottom="normal">
              Tanggal :
            </Heading>

            <DateRangePicker
              margin={0}
              dateFilter={dateFilter}
              setDateFilter={setDateFilter}
            />
          </Flex>

          {groupActivities && filteredActivities && (
            <GroupActivityTable
              data={filteredActivities}
              rows={groupActivities.count}
              loading={isLoadingData}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
            />
          )}
        </Box>
      </Box>
    </PopUpView>
  );
};

export default GroupActivity;
