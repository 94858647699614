import React from 'react';
import css from '@styled-system/css';
import Brand from 'components/atoms/Brand';
import Flex, { FlexStyleProps } from 'components/atoms/Flex';
import { Caption } from 'components/atoms/Typography';
import styled from 'styled-components';

export type FooterProps = FlexStyleProps;

const FooterStyle = styled(Flex)<FlexStyleProps>(
  css({
    justifyContent: 'center',
    alignItems: 'flex-end',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
    borderTop: 'thin',
    borderColor: 'secondaryLighten',
    paddingTop: 'medium',
    paddingBottom: 'large',
    paddingLeft: 'medium',
    paddingRight: 'medium',
    whiteSpace: 'nowrap',
  })
);

const Footer: React.FC<FooterProps> = (props) => (
  <FooterStyle as="footer">
    <Caption lineHeight="8px">© 2021 </Caption>
    <Brand type="text" height="13px" marginLeft="small" />
  </FooterStyle>
);

export default Footer;
