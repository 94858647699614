export const PROD_ENDPOINT: string = 'https://api.databiota.com/api/v1/';
export const DEVAPI_ENDPOINT: string = 'https://devapi.databiota.com/api/v1/';

// For Production
export const API_ENDPOINT: string =
  process.env.NODE_ENV === 'production' ? PROD_ENDPOINT : PROD_ENDPOINT;

// For Testing
// export const API_ENDPOINT = DEVAPI_ENDPOINT;

export const URL_PLATFORM_LOCAL: string = 'http://localhost:3000';
export const URL_PLATFORM_PROD: string = 'https://app.zoonostic.com';
export const URL_PLATFORM: string =
  process.env.NODE_ENV === 'production'
    ? URL_PLATFORM_PROD
    : URL_PLATFORM_LOCAL;

export const URL_KESMAVET_LOCAL: string = 'http://localhost:3010';
export const URL_KESMAVET_PROD: string = 'https://kesmavetugm.com';
export const URL_KESMAVET: string =
  process.env.NODE_ENV === 'production'
    ? URL_KESMAVET_PROD
    : URL_KESMAVET_LOCAL;

export const EMAIL_REGEX: any =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const MUST_NUMBER_REGEX: any = /\d*/;

export const PHONE_NUMBER_REGEX: any = /^(\+\d{1,2}\s)?\d*$/;
