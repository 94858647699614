import { Redirect, Route } from 'react-router-dom';
import { IRouteProps } from './routes';

const PrivateRoute = ({
  component: Component,
  path,
  exact = false,
  ...rest
}: IRouteProps): JSX.Element => {
  const isAuth = localStorage.getItem('token');
  const message = 'Please login in to view this page';

  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {
                message,
                requestedPath: path,
                fromPath: props.location,
              },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
