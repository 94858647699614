import { Tab, Tabs } from '@tarragon/swipeable-tabs/dist';
import Box from 'components/atoms/Box';
import FloatingButton, {
  TFloatingListDataProps,
} from 'components/molecules/FloatingButton';
import Heading from 'components/atoms/Heading';
import Spinner from 'components/atoms/Spinner';
import { Paragraph } from 'components/atoms/Typography';
import DropdownMenu, {
  TDropdownListDataProps,
} from 'components/molecules/DropdownMenu';
import DataPageView from 'components/organisms/DataPageView';
import SideView from 'components/organisms/SideView';
import useScreeType from 'hooks/useScreenType';
import React, { useState, useMemo, useEffect, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { getAnotations } from 'redux/anotation/anotationAction';
import { AnotationState } from 'redux/anotation/anotationReducer';
import { ApiState } from 'redux/api/apiReducer';
import { GroupState } from 'redux/group/groupReducer';
import { AppState } from 'redux/store';
import theme from 'themes/theme';
import useCheckMemberLevel from 'hooks/checkLevelMember';
import Table from 'components/organisms/Table';

const AnotationList = React.lazy(() => import('./AnotationList'));

const Anotations: React.FC = () => {
  const dispatch = useDispatch();
  const screenType = useScreeType();

  const token = localStorage.getItem('token');

  const { url } = useRouteMatch();
  const { groupId }: any = useParams();
  const memberLevel = useCheckMemberLevel(groupId);

  const { groupById, errorGetGroupById } = useSelector<AppState, GroupState>(
    (props) => props.group
  );

  const { anotations, errorGetAnotations } = useSelector<
    AppState,
    AnotationState
  >((props) => props.anotation);

  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  const [selectedTab, setSelectedTab] = useState('Informasi Data');

  const groupDropdownList: TDropdownListDataProps = useMemo(() => {
    const menu = [
      {
        text: 'Info Proyek',
        link: {
          to: `${url}/detail`,
          iconLeft: 'info',
        },
      },
    ];

    if (memberLevel !== 'guest') {
      menu.push({
        text: 'Input Data',
        link: {
          to: `${url}/anotation/create`,
          iconLeft: 'add',
        },
      });
    }

    return menu;
  }, [memberLevel, url]);

  // List link data for floating button
  const createAnotationLink: TFloatingListDataProps = useMemo(
    () => [
      {
        text: 'Input Data',
        link: {
          to: `${url}/anotation/create`,
          iconLeft: 'add',
        },
      },
      {
        text: 'Upload File',
        link: {
          to: `${url}/anotation/upload`,
          iconLeft: 'attach_file',
        },
      },
    ],
    [url]
  );

  // Function for change tab
  const changeTab: (selectedTab: {
    label: string;
    key: string | number;
  }) => void = (updatedTab) => {
    setSelectedTab(updatedTab.label);
  };

  useEffect(() => {
    if (groupById?.id) {
      if (groupById?.id !== parseFloat(groupId) || anotations === null) {
        dispatch(getAnotations(token, groupId));
      }
    }
  }, [anotations, groupById?.id, groupId, token]);

  // Handle Data Anotation Table
  const dataTableAnotation = useMemo(() => {
    const tableData: any[] = [];

    anotations?.forEach(
      ({ id, attributes, created_at, features, group, updated_at, user }) => {
        const rowData = {
          Tanggal: new Date(created_at).toLocaleDateString('id-ID', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          id,
          Nama: attributes.namaField,
          Kabupaten:
            features.reversegeocode?.addressparts.county ||
            features.reversegeocode?.addressparts.city ||
            features.reversegeocode?.addressparts.town,
          Kecamatan:
            features.reversegeocode?.addressparts.municipality ||
            features.reversegeocode?.addressparts.city_district ||
            features.reversegeocode?.addressparts.suburb,
          Desa: features.reversegeocode?.addressparts.village,
          'Kode Pos': features.reversegeocode?.addressparts.postcode,
          Koordinat: features.geometry.coordinates.toString(),
        };

        attributes.data.forEach((atribute) => {
          return tableData.push({
            ...rowData,
            ...atribute,
          });
        });

        return;
      }
    );

    return tableData;
  }, [anotations]);

  // Anotation Content Component
  const Content = useMemo(
    () =>
      isLoadingData ? (
        <Spinner
          spinnerSize={50}
          position="absolute"
          backgroundColor="#ffffff80"
          top="0"
          left="0"
          right="0"
          bottom="0"
        />
      ) : errorGetAnotations || errorGetGroupById ? (
        <Paragraph variant="error">
          {errorGetAnotations || errorGetGroupById}
        </Paragraph>
      ) : (
        <Suspense
          fallback={
            <Spinner
              spinnerSize={50}
              position="absolute"
              backgroundColor="#ffffff80"
              top="0"
              left="0"
              right="0"
              bottom="0"
            />
          }
        >
          <AnotationList
            anotations={anotations}
            memberLevel={memberLevel}
            url={url}
          />
        </Suspense>
      ),
    [
      anotations,
      errorGetAnotations,
      errorGetGroupById,
      isLoadingData,
      memberLevel,
      url,
    ]
  );

  return (
    <>
      <SideView
        title={isLoadingData ? '...' : groupById?.name}
        description={isLoadingData ? '...' : groupById?.description}
        back={true}
        paddingBottom={['', '56px']}
        rightIcon={
          <DropdownMenu
            variant="transparent"
            icon="more_vert"
            listData={groupDropdownList}
          />
        }
      >
        {screenType === 'mobile' ? (
          <Tabs
            value={selectedTab}
            onChange={changeTab}
            tabBarCSS={`
          font-size: ${theme.fontSizes[1]};
          font-weight: ${theme.fontWeights.bold};
          font-family: ${theme.fontFamily.primary} !important;
          height:auto;
          `}
            styleProps={{
              barColor: 'transparent',
              selectedHeaderTextColor: theme.colors.accent,
              headerTextColor: theme.colors.secondary,
              activeInkBarColor: theme.colors.accent,
              inkBarColor: theme.colors.accent,
              tabPosition: 'top',
            }}
          >
            <Tab label="Informasi Data" key={0}>
              <Box
                as="ul"
                gapY="normal"
                margin="0"
                paddingX="large"
                paddingY="large"
              >
                {Content}
              </Box>
            </Tab>
            <Tab label="Grafik" key={1}>
              <DataPageView />
            </Tab>
          </Tabs>
        ) : (
          <Box
            as="ul"
            gapY="normal"
            margin="0"
            paddingX="large"
            paddingY="large"
          >
            <Heading variant="heading" as="h2" marginBottom="normal">
              Data
            </Heading>

            {Content}
          </Box>
        )}

        {anotations && memberLevel !== 'guest' && (
          <FloatingButton icon="add" listData={createAnotationLink}>
            Input Data
          </FloatingButton>
        )}
      </SideView>

      {dataTableAnotation.length > 0 && <Table data={dataTableAnotation} />}
    </>
  );
};

export default Anotations;
