import { LatLngExpression } from 'leaflet';

export const SET_SHOW_TIPS = 'SET_SHOW_TIPS';
export const SET_SHOW_INPUT = 'SET_SHOW_INPUT';
export const SET_HIDE_TIPS = 'SET_HIDE_TIPS';
export const SET_HIDE_INPUT = 'SET_HIDE_INPUT';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_ANOTATION_LOCATION = 'SET_ANOTATION_LOCATION';

export interface IMapLocationState {
  lat?: number;
  lng?: number;
  description: string;
}

export interface IMapState {
  location: IMapLocationState;
  anotationLocation: LatLngExpression | null;
  showTips: boolean;
  showInput: boolean;
}

interface SetLocation {
  type: typeof SET_LOCATION;
  payload: IMapLocationState;
}

interface SetAnotationLocation {
  type: typeof SET_ANOTATION_LOCATION;
  payload: LatLngExpression | null;
}

interface SetShowTips {
  type: typeof SET_SHOW_TIPS;
}

interface SetHideTips {
  type: typeof SET_HIDE_TIPS;
}

interface SetShowInput {
  type: typeof SET_SHOW_INPUT;
}

interface SetHideInput {
  type: typeof SET_HIDE_INPUT;
}

export type MapActionTypes =
  | SetLocation
  | SetAnotationLocation
  | SetShowTips
  | SetHideTips
  | SetShowInput
  | SetHideInput;
