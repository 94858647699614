import {
  ACCESS_DENIED,
  API,
  ApiAction,
  API_END,
  API_ERROR,
  API_START,
  FetchApi,
} from './apiTypes';

export const apiStart = (label: string): ApiAction => ({
  type: API_START,
  payload: label,
});

export const apiEnd = (label: string): ApiAction => ({
  type: API_END,
  payload: label,
});

export const apiError = (error: any): ApiAction => ({
  type: API_ERROR,
  error,
});

export const accessDenied = (url: string): ApiAction => ({
  type: ACCESS_DENIED,
  payload: {
    url,
  },
});

const fetchApi = ({
  url = '',
  method = 'GET',
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = '',
  headersOverride = null,
}: FetchApi) => {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      accessToken,
      onSuccess,
      onFailure,
      label,
      headersOverride,
    },
  };
};
export default fetchApi;
