import React from 'react';
import css from '@styled-system/css';
import styled from 'styled-components';
import Box, { BoxStyleProps } from '../Box';

const ScrollToogleStyle = styled(Box)<BoxStyleProps>(
  css({
    height: '5px',
    width: '50px',
    marginX: 'auto',
    marginY: 'small',
    borderRadius: '9999px',
    backgroundColor: 'secondary',
  })
);

const ScrollToogle: React.FC<BoxStyleProps> = (props) => {
  return <ScrollToogleStyle {...props} />;
};

export default ScrollToogle;
