/* eslint-disable no-sparse-arrays */
import React from 'react';
import css from '@styled-system/css';
import Box, { BoxStyleProps } from 'components/atoms/Box';
import Toolbar, { ToolbarProps } from 'components/molecules/Toolbar';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Grid, { GridStyleProps } from 'components/atoms/Grid';
import { useHistory } from 'react-router-dom';

export type TPopUpViewProps = BoxStyleProps &
  ToolbarProps & {
    leftIcon?: React.ReactNode;
    rightIcon?: React.ReactNode;
    closePath?: string;
  };

const PopUpViewStyle = styled(Grid)<GridStyleProps>`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 100%;
  grid-template-rows: 50px 1fr;

  ${css({
    position: 'relative',
    minWidth: ['', '350px'],
    minHeight: ['30vh', '100vh'],
    height: '100%',
    backgroundColor: 'background',
    borderRadius: ['16px 16px 0 0', 'medium'],
    left: 0,
    right: 0,
    boxShadow: 'medium',
    gridArea: [, , 'Main'],
    top: ['3px', 0],
    zIndex: [10, 0],
  })}
`;

const PopUpView: React.FC<TPopUpViewProps> = ({
  title,
  description,
  leftIcon,
  rightIcon,
  closePath,
  back,
  children,
  ...rest
}) => {
  const { goBack, push } = useHistory();

  return (
    <PopUpViewStyle as="section" {...rest}>
      <Toolbar
        as="header"
        left={leftIcon}
        right={
          <>
            {rightIcon}
            <Button
              size={['35px', '40px']}
              variant="transparent"
              rounded={true}
              onClick={() => (closePath ? push(closePath) : goBack())}
            >
              <Icon>close</Icon>
            </Button>
          </>
        }
        title={title}
        description={description}
        back={back}
      />

      <Box height="100%" overflowY="auto">
        {children}
      </Box>
    </PopUpViewStyle>
  );
};

export default PopUpView;
