import {
  IMapState,
  MapActionTypes,
  SET_ANOTATION_LOCATION,
  SET_HIDE_INPUT,
  SET_HIDE_TIPS,
  SET_LOCATION,
  SET_SHOW_INPUT,
  SET_SHOW_TIPS,
} from './mapTypes';

const defaultState: IMapState = {
  location: {
    lat: undefined,
    lng: undefined,
    description: 'Lokasi belum ditentukan',
  },
  anotationLocation: null,
  showInput: false,
  showTips: false,
};

const mapReducer = (
  state: IMapState = defaultState,
  action: MapActionTypes
) => {
  switch (action.type) {
    case SET_LOCATION:
      return {
        ...state,
        location: action.payload,
      };

    case SET_ANOTATION_LOCATION:
      return {
        ...state,
        anotationLocation: action.payload,
      };

    case SET_SHOW_TIPS: {
      return {
        ...state,
        showTips: true,
      };
    }
    case SET_HIDE_TIPS: {
      return {
        ...state,
        showTips: false,
      };
    }

    case SET_SHOW_INPUT:
      return {
        ...state,
        showInput: true,
      };

    case SET_HIDE_INPUT:
      return {
        ...state,
        showInput: false,
      };

    default:
      return state;
  }
};

export default mapReducer;
