import React from 'react';
import css from '@styled-system/css';
import Flex, { FlexStyleProps } from 'components/atoms/Flex';
import Heading from 'components/atoms/Heading';
import { Caption } from 'components/atoms/Typography';
import styled from 'styled-components';
import Icon from 'components/atoms/Icon';
import Button from 'components/atoms/Button';
import { useHistory } from 'react-router-dom';

export type ToolbarProps = FlexStyleProps & {
  title?: string;
  description?: string;
  left?: React.ReactNode;
  right?: React.ReactNode;
  back?: boolean;
};

const ToolbarStyle = styled(Flex)<FlexStyleProps>(
  css({
    justifyContent: 'space-between',
    alignItems: 'center',
    height: ['46px', '50px'],
    paddingX: 'small',
    borderBottom: 'thin',
    borderColor: 'secondary',
  })
);

const Toolbar: React.FC<ToolbarProps> = ({
  title,
  back = false,
  description,
  left,
  right,
  ...rest
}) => {
  const { goBack } = useHistory();

  return (
    <ToolbarStyle {...rest}>
      {back && (
        <Button
          variant="transparent"
          size={['35px', '40px']}
          rounded={true}
          onClick={goBack}
        >
          <Icon>arrow_back</Icon>
        </Button>
      )}
      {left}

      <Flex
        justifyContent="center"
        flexDirection="column"
        width="100%"
        paddingX="normal"
      >
        {title && (
          <Heading
            as="span"
            fontSize={['paragraph', 'title']}
            marginTop="small"
            truncate={1}
            css={css({ textOverflow: 'ellipsis' })}
          >
            {title}
          </Heading>
        )}
        {description && (
          <Caption as="span" truncate={1}>
            {description}
          </Caption>
        )}
      </Flex>

      {right}
    </ToolbarStyle>
  );
};

export default Toolbar;
