import Anotation from 'pages/Anotation/Anotations';
import GroupForm from 'pages/Group/GroupForm';
import Group from 'pages/Group/Group';
import AnotationForm from 'pages/Anotation/AnotationForm';
import GroupDetail from 'pages/Group/GroupDetail';
import AnotationDetail from 'pages/Anotation/AnotationDetail';
import GroupInvite from 'pages/Group/GroupInvite';
import GroupUpdate from 'pages/Group/GroupUpdate';
import Profile from 'pages/User/User';
import { IRouteProps } from 'routes/routes';
import GroupJoinByLink from 'pages/Group/GroupJoinByLink';
import GroupJoin from 'pages/Group/GroupJoin';
import Analytic from 'pages/Analytic/Analytic';
import GroupActivity from 'pages/Group/GroupActivity';

const mainRoutes: IRouteProps[] = [
  {
    exact: true,
    path: '/',
    component: Group,
  },
  {
    exact: true,
    path: '/group',
    component: Group,
  },
  {
    path: '/group/create',
    component: GroupForm,
  },
  {
    path: '/group/:groupId/update',
    component: GroupUpdate,
  },
  {
    path: '/group/:groupId/detail',
    component: GroupDetail,
  },
  {
    path: '/group/:groupId/invite',
    component: GroupInvite,
  },
  {
    path: '/group/:groupId/activity',
    component: GroupActivity,
  },
  {
    path: '/group/join',
    component: GroupJoin,
  },
  {
    path: '/join/:groupToken',
    component: GroupJoinByLink,
  },
  {
    exact: true,
    path: '/group/:groupId',
    component: Anotation,
  },
  {
    path: '/group/:groupId/anotation/create',
    component: AnotationForm,
  },
  {
    path: '/group/:groupId/anotation/:id',
    component: AnotationDetail,
  },
  {
    path: '/analytic/:groupId',
    component: Analytic,
  },
  {
    path: '/account',
    component: Profile,
  },
];

export default mainRoutes;
