import {
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS,
  GET_USER_BY_ID_FAILURE,
  GET_USER_BY_ID_SUCCESS,
  Profile,
  REQUEST_RESET_PASSWORD_FAILURE,
  REQUEST_RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  UPDATE_USER_FAILURE,
  Users,
  UsersAction,
} from './userTypes';

export interface UserState {
  users: Users;
  userById: Profile | null;
  errorGetUsers: string | null;
  errorGetUserById: string | null;
  errorRequestResetPassword: string | null;
  errorResetPassword: string | null;
  errorUpdateUser: string | null;
}

const initialState: UserState = {
  users: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  userById: null,
  errorGetUsers: null,
  errorGetUserById: null,
  errorRequestResetPassword: null,
  errorResetPassword: null,
  errorUpdateUser: null,
};

const userReducer = (state = initialState, action: UsersAction): UserState => {
  switch (action.type) {
    case GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
        errorGetUsers: null,
      };

    case GET_USERS_FAILURE:
      return {
        ...state,
        errorGetUsers: action.payload.error,
      };

    case GET_USER_BY_ID_SUCCESS:
      return {
        ...state,
        userById: action.payload,
        errorGetUserById: null,
      };

    case GET_USER_BY_ID_FAILURE:
      return {
        ...state,
        errorGetUserById: action.payload.error,
      };

    case REQUEST_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        errorRequestResetPassword: null,
      };

    case REQUEST_RESET_PASSWORD_FAILURE:
      return {
        ...state,
        errorRequestResetPassword: action.payload.error,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        errorResetPassword: null,
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        errorResetPassword: action.payload.error,
      };

    case UPDATE_USER_FAILURE:
      return {
        ...state,
        errorUpdateUser: action.payload.error,
      };

    default:
      return state;
  }
};

export default userReducer;
