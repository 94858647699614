import Grid, { GridStyleProps } from 'components/atoms/Grid';
import { Caption } from 'components/atoms/Typography';
import React, { Fragment } from 'react';
import { useCallback } from 'react';

export type ListDataProps = GridStyleProps & {
  data: any;
  columnArray?: any;
};

const ListData: React.FC<ListDataProps> = ({
  data,
  columnArray,
  children,
  ...rest
}) => {
  // Row Component
  const RowComponent = useCallback(
    (title: string = '', content: string = '', index) => (
      <Fragment key={index}>
        <Caption as="span" style={{ wordBreak: 'break-word' }}>
          {title}
        </Caption>
        <span>:</span>
        <Caption as="span" variant="link" style={{ wordBreak: 'break-word' }}>
          {content}
        </Caption>
      </Fragment>
    ),
    []
  );

  return (
    <Grid
      gridAutoColumns="1fr"
      gridTemplateColumns="minmax(auto, 100px) auto 1fr"
      gridTemplateRows="1fr"
      gapY="small"
      gapX="small"
      {...rest}
    >
      {Object.keys(data).map((key, keyIndex) =>
        Array.isArray(data[key])
          ? data[key].map((result: any, index: number) => (
              <React.Fragment key={`${key}${keyIndex}${index}`}>
                {data[key].length > 1 && (
                  <>
                    <Caption as="span" fontWeight="bold" my="small">
                      <u>Data ke {index + 1}</u>
                    </Caption>
                    <div />
                    <div />
                  </>
                )}
                {columnArray
                  ? columnArray.map((columnKey: any, columnIndex: number) =>
                      RowComponent(
                        columnKey.name,
                        result[columnKey.id],
                        columnIndex
                      )
                    )
                  : Object.keys(result).map((columnKey) =>
                      RowComponent(columnKey, result[columnKey], index)
                    )}
              </React.Fragment>
            ))
          : RowComponent(key, data[key], keyIndex)
      )}

      {children}
    </Grid>
  );
};

export default ListData;
