import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Switch from 'components/atoms/Switch';
import Grid from 'components/atoms/Grid';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import Input from 'components/atoms/Input';
import Spinner from 'components/atoms/Spinner';
import Field from 'components/molecules/Field';
import PopUpView from 'components/organisms/PopUpView';

import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import CreateableSelect from 'react-select/creatable';
import { ApiState } from 'redux/api/apiReducer';
import { addGroup, getGroups, IGroupProps } from 'redux/group/groupAction';
import { AppState } from 'redux/store';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Caption } from 'components/atoms/Typography';
import { GroupState } from 'redux/group/groupReducer';
import { getSelectValues, SelectOptionProps } from 'hooks/SelectOption';

const optionDataZoonostic: SelectOptionProps[] = [
  {
    value: 'methods',
    label: 'Metode',
  },
  {
    value: 'preservatives',
    label: 'Bahan Pengawet',
  },
  {
    value: 'species',
    label: 'Spesies',
  },
  {
    value: 'specimens',
    label: 'Spesimen',
  },
];

const selectOption = [
  {
    value: 'text',
    label: 'Teks',
  },
  {
    value: 'number',
    label: 'Angka',
  },
  {
    value: 'select',
    label: 'Pilihan',
  },
  {
    value: 'textarea',
    label: 'Teks Area',
  },
  {
    value: 'date',
    label: 'Tanggal',
  },

  {
    label: 'Data dari Zoonostic',
    options: optionDataZoonostic,
  },
];

const GroupForm: React.FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const token = localStorage.getItem('token');

  const { errorAddGroup } = useSelector<AppState, GroupState>(
    ({ group }) => group
  );
  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm<IGroupProps>({
    defaultValues: {
      name: '',
      description: '',
      is_public: true,
      key_fields: [
        {
          name: '',
          type: 'text',
          is_required: false,
          option: [],
        },
      ],
    },
    mode: 'onTouched',
  });

  const { fields, append, remove } = useFieldArray({
    name: 'key_fields',
    control,
  });

  // Action Add Group Success
  const addGroupSuccessAction = useCallback(() => {
    dispatch(getGroups(token));
    push('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const onSubmit: SubmitHandler<IGroupProps> = useCallback(
    (data) => {
      data.key_fields.forEach((keyField, index) => {
        if ('option' in keyField) {
          if (keyField.type !== 'select') {
            delete data.key_fields[index].option;
          } else if (keyField.option) {
            data.key_fields[index].option = getSelectValues(keyField.option);
          }
        }
      });

      dispatch(
        addGroup(
          token,
          {
            ...data,
          },
          () => addGroupSuccessAction()
        )
      );
    },
    [token]
  );

  return (
    <PopUpView
      width={['', '500px']}
      title="Proyek Baru"
      description="Mengisi form untuk membuat group baru sadsad sad asd sa dsa das dsa das"
    >
      <Box paddingX="large" paddingY="large" gapY="large">
        {isLoadingData && (
          <Spinner
            spinnerSize={50}
            position="absolute"
            backgroundColor="#ffffff80"
            top="0"
            left="0"
            right="0"
            bottom="0"
          />
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Field label="Nama Proyek" error={errors.name?.message}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: 'Kolom ini tidak boleh kosong !',
              }}
              render={({ field, fieldState: { error } }) => (
                <Input {...field} error={!!error} placeholder="Nama Proyek" />
              )}
            />
          </Field>

          <Field label="Deskripsi Proyek" error={errors.description?.message}>
            <Controller
              name="description"
              control={control}
              rules={{ required: 'Kolom ini tidak boleh kosong !' }}
              render={({ field, fieldState: { error } }) => (
                <Input
                  {...field}
                  as="textarea"
                  rows={3}
                  error={!!error}
                  placeholder="Deskripsi Proyek"
                />
              )}
            />
          </Field>

          <Field
            label="Visibilitas"
            width="fit-content"
            tooltips="(?) Apakah data Anda berkenan dibagikan."
          >
            <Controller
              name="is_public"
              control={control}
              render={({ field: { value, ...field } }) => (
                <Switch defaultChecked={value} {...field} />
              )}
            />
          </Field>

          <Heading marginBottom="normal" marginTop="large">
            Mendesain Data
          </Heading>

          {fields.map((fieldItem, index) => (
            <Grid
              key={fieldItem.id}
              gridAutoColumns="1fr"
              gridTemplateColumns="1fr 120px auto"
              gridTemplateRows="1fr"
              gridColumnGap="small"
              alignItems="center"
            >
              <Field label="Nama Kolom">
                <Controller
                  name={`key_fields.${index}.name`}
                  control={control}
                  defaultValue={fieldItem.name}
                  rules={{ required: 'Kolom ini tidak boleh kosong !' }}
                  render={({ field, fieldState: { error } }) => (
                    <Input
                      {...field}
                      error={!!error}
                      placeholder="Nama Kolom"
                    />
                  )}
                />
              </Field>

              <Field label="Jenis Inputan">
                <Controller
                  name={`key_fields.${index}.type`}
                  control={control}
                  defaultValue={fieldItem.type}
                  render={({ field: { value, onChange, ...field } }) => (
                    <Select
                      {...field}
                      options={selectOption}
                      isSearchable={false}
                      styles={{
                        menu: (provided, state) => {
                          return {
                            ...provided,
                            width: '180px',
                            right: 0,
                          };
                        },
                      }}
                      onChange={(e) => onChange(e?.value)}
                      defaultValue={selectOption.find(
                        (result) => result.value === value
                      )}
                    />
                  )}
                />
              </Field>

              <Button
                type="button"
                variant="primary"
                size="40px"
                marginTop="5px"
                disabled={fields.length === 1}
                onClick={() => remove(index)}
              >
                <Icon color="white">close</Icon>
              </Button>

              {watch(`key_fields.${index}.type`) === 'select' && (
                <Controller
                  key={fieldItem.id}
                  name={`key_fields.${index}.option`}
                  control={control}
                  defaultValue={fieldItem.option}
                  render={({ field: { ...field }, fieldState: { error } }) => (
                    <Field
                      label="Data pilihan"
                      error={error?.message}
                      gridArea="2 / 1 / 3 / 3"
                    >
                      <CreateableSelect
                        isClearable
                        isMulti
                        components={{
                          DropdownIndicator: null,
                        }}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            minHeight: '40px',
                          }),
                        }}
                        {...field}
                      />
                    </Field>
                  )}
                />
              )}

              <Field
                label="Kolom boleh kosong?"
                gridArea="3 / 1 / 3 / 3"
                width="fit-content"
              >
                <Controller
                  name={`key_fields.${index}.is_required`}
                  control={control}
                  defaultValue={fieldItem.is_required}
                  render={({ field: { onBlur, value, onChange, ref } }) => (
                    <Switch
                      defaultChecked={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      ref={ref}
                    />
                  )}
                />
              </Field>
            </Grid>
          ))}

          <Button
            type="button"
            variant="secondary"
            onClick={() =>
              append({
                name: '',
                type: 'text',
                is_required: false,
              })
            }
          >
            <Icon marginRight="normal">add</Icon> Tambah Kolom
          </Button>

          {errorAddGroup && (
            <Caption color="error" marginTop="large">
              {errorAddGroup}
            </Caption>
          )}

          <Button
            marginTop="large"
            as="input"
            type="submit"
            value="Buat Proyek"
          />
        </form>
      </Box>
    </PopUpView>
  );
};

export default GroupForm;
