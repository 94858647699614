import React, { memo, useCallback } from 'react';
import DatePicker from 'react-date-picker';
import styled from 'styled-components';
import css from '@styled-system/css';

import 'react-calendar/dist/Calendar.css';
import Box, { BoxStyleProps } from 'components/atoms/Box';
import Field from '../Field';
import Flex from 'components/atoms/Flex';

export type DateFilter = {
  start_date: Date;
  end_date: Date;
};

export type FilterByDateProps = BoxStyleProps & {
  label?: string;
  dateFilter: DateFilter;
  setDateFilter: React.Dispatch<React.SetStateAction<DateFilter>>;
};

// Date Pickers styling
const DatePickers = styled(DatePicker)(
  css({
    '.react-date-picker__wrapper': {
      border: 'thin',
      borderColor: 'secondaryLighten',
      borderRadius: 'small',
      py: 'small',
    },
  })
);

const DateRangePicker: React.FC<FilterByDateProps> = memo(
  ({ label, dateFilter, setDateFilter, ...rest }) => {
    // Function for handle set start date
    const handleStartDate = useCallback((value: Date) => {
      setDateFilter((prev) => ({
        ...prev,
        start_date: value,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Function for handle set end date
    const handleEndDate = useCallback((value: Date) => {
      setDateFilter((prev) => ({
        ...prev,
        end_date: value,
      }));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <Field label={label} {...rest}>
        <Flex alignItems="center" gapX="small" pt="small">
          <DatePickers
            value={new Date(dateFilter.start_date)}
            onChange={handleStartDate}
            format="d-M-y"
            clearIcon={null}
            calendarIcon={null}
          />

          <Box>-</Box>

          <DatePickers
            value={new Date(dateFilter.end_date)}
            onChange={handleEndDate}
            format="d-M-y"
            clearIcon={null}
            calendarIcon={null}
          />
        </Flex>
      </Field>
    );
  }
);

export default DateRangePicker;
