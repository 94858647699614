import css from '@styled-system/css';
import Grid, { GridStyleProps } from 'components/atoms/Grid';
import styled from 'styled-components';
import { mediaQueries } from 'themes/config';

export type SideViewStyleProps = GridStyleProps;

const SideViewStyle = styled(Grid)<SideViewStyleProps>`
  ${mediaQueries.mobile} {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 100%;
    grid-template-rows: 50px 1fr;
    width: 350px;
  }

  ${css({
    position: 'relative',
    backgroundColor: 'background',
    borderRadius: ['16px 16px 0 0', 'medium'],
    minHeight: ['30vh', '100vh'],
    paddingTop: ['small', 'none'],
    top: ['70vh', 0],
    left: 0,
    right: 0,
    boxShadow: 'medium',
  })}
`;

export default SideViewStyle;
