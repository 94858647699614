import css from '@styled-system/css';
import Box, { BoxStyleProps } from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import { ButtonVariantProps } from 'components/atoms/Button/button.style';
import Icon from 'components/atoms/Icon';
import useClickOutside from 'hooks/useClickOutside';
import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import ListLink, { ListLinkProps } from '../ListLink';

export type TDropdownListDataProps = {
  text: string;
  link: ListLinkProps;
}[];

export type TDropdownListProps = BoxStyleProps & {
  listData: TDropdownListDataProps;
  icon: string;
  variant?: ButtonVariantProps;
  size?: string | string[];
  width?: string | string[];
  height?: string | string[];
  padding?: string;
  iconSize?: string | string[];
  rounded?: boolean;
};

const DropdownMenuStyle = styled(Box)(
  css({
    position: 'relative',
  })
);

const DropdownListStyle = styled(Box)(
  css({
    position: 'absolute',
    backgroundColor: 'background',
    maxWidth: '220px',
    right: 0,
    top: '110%',
    margin: 0,
    borderRadius: 'medium',
    boxShadow: 'small',
    zIndex: 20,
    overflow: 'hidden',
  })
);

const DropdownMenu: React.FC<TDropdownListProps> = ({
  icon,
  listData,
  variant,
  size,
  width,
  height,
  padding,
  iconSize,
  rounded = true,
  children,
  ...rest
}) => {
  const wrapper = useRef(null);

  const [isDropdonwMenuOpen, setIsDropdownMenuOpen] = useState<boolean>(false);

  const closeListMenu = useCallback((action?: () => void) => {
    setIsDropdownMenuOpen(false);

    if (action) {
      action();
    }
  }, []);

  const handleButtonList = (event: any) => {
    event.preventDefault();
    setIsDropdownMenuOpen(!isDropdonwMenuOpen);
  };

  useClickOutside(wrapper, closeListMenu);

  return (
    <DropdownMenuStyle ref={wrapper} {...rest}>
      <Button
        variant={variant}
        minHeight={height || size}
        minWidth={width || size}
        width={width || size || ['35px', '40px']}
        height={height || size || ['35px', '40px']}
        padding={padding}
        rounded={rounded}
        fontSize={iconSize}
        onClick={handleButtonList}
      >
        {children}
        <Icon fontSize={iconSize}>{icon}</Icon>
      </Button>

      {isDropdonwMenuOpen && (
        <DropdownListStyle>
          {listData.map(({ text, link: { action, ...linkRest } }) => (
            <ListLink
              key={text}
              action={() => closeListMenu(action)}
              children={text}
              {...linkRest}
            />
          ))}
        </DropdownListStyle>
      )}
    </DropdownMenuStyle>
  );
};

export default DropdownMenu;
