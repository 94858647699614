import css from '@styled-system/css';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Heading from 'components/atoms/Heading';
import Icon from 'components/atoms/Icon';
import { Paragraph } from 'components/atoms/Typography';
import MapsControl from 'components/organisms/MapsControl';
import { LatLngTuple, Map } from 'leaflet';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { MapContainer } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { setHideTips, setShowInput } from 'redux/map/mapAction';
import { IMapState } from 'redux/map/mapTypes';
import { AppState } from 'redux/store';
import styled from 'styled-components';
import AnotationMarker from './AnotationsMarker';
import TagLocationMarker from './TagsLocationMarker';

const minZoom: number = 2;

const MapStyle = styled(MapContainer)`
  ${css({
    width: '100%',
    height: '100%',
    zIndex: 0,

    '.leaflet-pane': {
      zIndex: 1,
    },
  })}
`;

const InformationPane = styled.div(
  css({
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(16, 19, 44, 0.6)',
    zIndex: 10,
  })
);

const Maps: React.FC<{ defaultLocation?: LatLngTuple; defaultZoom?: number }> =
  ({
    defaultLocation = [-0.789275, 113.921327],
    defaultZoom = 5,
    children,
    ...rest
  }) => {
    const dispatch = useDispatch();
    const [mapRef, setMapRef] = useState<Map | null>(null);

    const { location, showTips } = useSelector<AppState, IMapState>(
      (state) => state.map
    );

    const handleHideTips = useCallback((e: React.MouseEvent) => {
      dispatch(setHideTips());
      e.preventDefault();
      e.stopPropagation();
      dispatch(setShowInput());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      if (location.lat && location.lng) {
        mapRef?.flyTo({ lat: location.lat!, lng: location.lng! }, 12, {
          duration: 1,
        });
      }
    }, [location.lat, location.lng, mapRef]);

    return (
      <>
        <MapStyle
          className="markercluster-map"
          center={defaultLocation}
          zoom={defaultZoom}
          minZoom={minZoom}
          zoomControl={false}
          whenCreated={setMapRef}
          {...rest}
        >
          {showTips && (
            <InformationPane onClick={handleHideTips}>
              <Box right="normal" top="normal" position="absolute">
                <Button
                  variant="secondary"
                  size="20px"
                  minHeight="20px"
                  minWidth="20px"
                  padding="small"
                  rounded={true}
                  onClick={handleHideTips}
                >
                  <Icon fontSize="small">close</Icon>
                </Button>
              </Box>
              <Heading variant="brand" color="white">
                Tips : Klik lokasimu di peta !
              </Heading>

              <Paragraph color="white">
                Tekan dimana saja untuk menutup tips ini
              </Paragraph>
            </InformationPane>
          )}

          <MapsControl />
          <TagLocationMarker />
          <AnotationMarker />
        </MapStyle>
      </>
    );
  };
export default Maps;
