import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import { Select as CustomSelect } from 'components/atoms/Select';
import Spinner from 'components/atoms/Spinner';
import { Caption } from 'components/atoms/Typography';
import Field from 'components/molecules/Field';
import PopUpView from 'components/organisms/PopUpView';
import React, { useCallback } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { ApiState } from 'redux/api/apiReducer';
import { addGroupMember, getGroupById } from 'redux/group/groupAction';
import { GroupState } from 'redux/group/groupReducer';
import { TMemberLevel } from 'redux/group/groupTypes';
import { AppState } from 'redux/store';
import { getUsers } from 'redux/user/userAction';
import { UserState } from 'redux/user/userReducer';

export type TOptionType = {
  value: string;
  label: string;
}[];

const GroupInvite: React.FC = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { groupId }: any = useParams();

  const token = localStorage.getItem('token');

  const { users, errorGetUsers } = useSelector<AppState, UserState>(
    (state) => state.user
  );
  const { groupById } = useSelector<AppState, GroupState>(
    (state) => state.group
  );

  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  const userData: any = useMemo(
    () =>
      users.results
        .filter(
          (filtered) =>
            !groupById?.members.some(
              (member) => member.person.email === filtered.email
            ) && groupById?.owner.email !== filtered.email
        )
        .map((result) => ({
          value: result.email,
          label: result.email,
        })),
    [groupById?.members, groupById?.owner.email, users.results]
  );

  const { handleSubmit, control } = useForm<{
    email: TOptionType;
    level: TMemberLevel;
  }>({
    defaultValues: {
      email: [],
      level: 'member',
    },
  });

  const onSubmit = useCallback(
    (data: { email: TOptionType; level: TMemberLevel }) => {
      console.log('ONSUBMIT ', data);

      const email = data.email.map((result) => result.value);

      dispatch(
        addGroupMember(
          token,
          groupId,
          {
            emails: email,
            level: data.level,
          },
          () => push(`/group/${groupId}`)
        )
      );
    },
    [groupId, token]
  );

  const handleGetUser = useCallback(
    async (value: string = '') => {
      dispatch(getUsers(token, value));
      return userData;
    },
    [token, userData]
  );

  useEffect(() => {
    if (!groupById) {
      dispatch(getGroupById(token, groupId));
    }
  }, [groupById, groupId, token]);

  return (
    <PopUpView title="Undang Anggota" width={['', '400px']}>
      <Box paddingX="medium" paddingY="large">
        {isLoadingData && (
          <Spinner
            spinnerSize={50}
            position="absolute"
            backgroundColor="#ffffff80"
            top="0"
            left="0"
            right="0"
            bottom="0"
            borderRadius="8px"
          />
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            rules={{ required: 'Kolom ini tidak boleh kosong !' }}
            render={({ field, fieldState: { error } }) => (
              <Field label="Email" error={error?.message}>
                <AsyncSelect
                  isMulti={true}
                  cacheOptions={true}
                  defaultOptions={true}
                  loadOptions={handleGetUser}
                  placeholder="Masukan Email..."
                  {...field}
                />
              </Field>
            )}
          />

          <Field label="Hak Akses">
            <Controller
              name="level"
              control={control}
              defaultValue="member"
              rules={{ required: 'Kolom ini tidak boleh kosong !' }}
              render={({ field }) => (
                <CustomSelect
                  {...field}
                  optionData={['member', 'guest', 'admin']}
                />
              )}
            />
          </Field>

          <Button marginTop="large" as="input" type="submit" value="Undang" />
        </form>

        {errorGetUsers && <Caption variant="error">{errorGetUsers}</Caption>}
      </Box>
    </PopUpView>
  );
};

export default GroupInvite;
