import Box from 'components/atoms/Box';
import Spinner from 'components/atoms/Spinner';
import { Caption, Paragraph } from 'components/atoms/Typography';
import PopUpView from 'components/organisms/PopUpView';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ApiState } from 'redux/api/apiReducer';
import { getInviteByLink } from 'redux/group/groupAction';
import { GroupState } from 'redux/group/groupReducer';
import { AppState } from 'redux/store';

const GroupJoinByLink: React.FC = () => {
  const dispatch = useDispatch();
  const { groupToken }: any = useParams();
  const { push } = useHistory();

  const { errorGetInviteByLink } = useSelector<AppState, GroupState>(
    ({ group }) => group
  );
  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  useEffect(() => {
    const token = localStorage.getItem('token');
    dispatch(getInviteByLink(token, groupToken, push));
  }, []);

  return (
    <PopUpView title="Gabung Proyek" closePath="/">
      <Box paddingX="medium" paddingY="large">
        {isLoadingData && (
          <>
            <Spinner
              spinnerSize={50}
              position="absolute"
              backgroundColor="#ffffff80"
              top="0"
              left="0"
              right="0"
              bottom="0"
              borderRadius="8px"
            />
            <Paragraph>Menunggu untuk gabung proyek</Paragraph>
          </>
        )}
        {errorGetInviteByLink && (
          <Caption variant="error">{errorGetInviteByLink}</Caption>
        )}
      </Box>
    </PopUpView>
  );
};

export default GroupJoinByLink;
