import css from '@styled-system/css';
import Flex from 'components/atoms/Flex';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { BoxStyleProps } from '../../atoms/Box';
import Icon from '../../atoms/Icon';

type ListStyleProps = BoxStyleProps & {
  activeColor?: boolean;
};

export type ListLinkProps = ListStyleProps & {
  to?: string;
  action?: any;
  iconLeft?: string;
  iconRight?: string;
};

const ListStyle = styled.li<ListStyleProps>(
  css({
    '.active': {
      backgroundColor: 'secondary',
    },
  })
);

const ListItemStyle = css({
  width: '100%',
  color: 'text',
  display: 'flex',
  alignItems: 'center',
  fontSize: 'paragraph',
  paddingY: 'medium',
  whiteSpace: 'nowrap',

  '.material-icons': {
    marginRight: 'large',
    marginLeft: 'normal',
    color: 'text',
  },

  '&:hover': {
    backgroundColor: 'primary',
    color: 'white',

    '.material-icons': {
      color: 'white',
    },
  },
});

const ListLinkStyle = styled(NavLink)<ListStyleProps>(
  ListItemStyle,
  css({
    paddingX: 'normal',
  })
);

const ButtonLinkStyle = styled.button<ListStyleProps>(
  ListItemStyle,
  css({
    border: 'none',
    backgroundColor: 'inherit',
  })
);

const ListLink: React.FC<ListLinkProps> = ({
  to,
  iconLeft,
  iconRight,
  action,
  children,
  ...rest
}) => (
  <ListStyle onClick={action}>
    {to ? (
      <ListLinkStyle to={to} {...rest}>
        {iconLeft && <Icon>{iconLeft}</Icon>}

        <Flex width="100%" alignItems="center" mr="24px">
          {children}
        </Flex>

        {iconRight && <Icon>{iconRight}</Icon>}
      </ListLinkStyle>
    ) : (
      <ButtonLinkStyle {...rest}>
        {iconLeft && <Icon>{iconLeft}</Icon>}

        <Flex width="100%" alignItems="center" mr="24px">
          {children}
        </Flex>

        {iconRight && <Icon>{iconRight}</Icon>}
      </ButtonLinkStyle>
    )}
  </ListStyle>
);

export default ListLink;
