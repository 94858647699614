import React, { useCallback, useMemo } from 'react';
import MainTemplate from 'components/templates/MainTemplate';
import mainRoutes from 'routes/mainRoutes';
import { DrawerRoutesProps } from 'components/organisms/Drawer/drawer';
import { Caption } from 'components/atoms/Typography';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Field from 'components/molecules/Field';
import Input from 'components/atoms/Input';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'redux/store';
import { GlobalState } from 'redux/global/globalReducer';
import {
  addFeedback,
  setIsFeedbackFormOpen,
  setModalOpen,
} from 'redux/global/globalAction';
import FeedbackForm from 'pages/FeedbackForm';
import { Feedback } from 'redux/global/globalTypes';
import { ApiState } from 'redux/api/apiReducer';
import { matchPath } from 'react-router-dom';

const defaultDrawerRoutes: DrawerRoutesProps = [
  {
    path: '/group',
    text: 'Proyek',
    iconLeft: 'dashboard',
  },
  {
    path: '/account',
    text: 'Account',
    iconLeft: 'account_circle',
    bottom: true,
  },
  {
    path: '/logout',
    text: 'Logout',
    iconLeft: 'logout',
    bottom: true,
  },
];

const Home: React.FC = () => {
  const dispatch = useDispatch();

  const match = matchPath<{ groupId: string }>(window.location.pathname, {
    path: ['/group/:groupId', '/analytic/:groupId'],
  });

  const drawerRoutes = useMemo<DrawerRoutesProps>(() => {
    let routes = defaultDrawerRoutes;

    if (match?.params?.groupId) {
      routes = [
        ...routes,
        // {
        //   path: `/analytic/${match?.params.groupId}`,
        //   text: 'Analitik',
        //   iconLeft: 'insights',
        // },
      ];
    }

    return [...routes];
  }, [match]);

  const { errorAddBVETFeedback } = useSelector<AppState, GlobalState>(
    ({ global }) => global
  );
  const { isLoadingData } = useSelector<AppState, ApiState>(({ api }) => api);

  const { handleSubmit, control, reset } = useForm<Feedback>({
    defaultValues: {
      message: '',
    },
  });

  const handleCloseFeedback = useCallback(() => {
    dispatch(setIsFeedbackFormOpen(false));
    dispatch(
      setModalOpen({
        title: 'Feedback Telah Dikirim',
        description: 'Terimakasih telah memberikan kritik dan saran',
        secondActionText: 'Tutup',
      })
    );

    reset({ message: '' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit: SubmitHandler<Feedback> = (data) => {
    const token = localStorage.getItem('token');

    dispatch(addFeedback(token, data, handleCloseFeedback));
  };

  return (
    <MainTemplate drawerRoutes={drawerRoutes} routes={mainRoutes}>
      <FeedbackForm
        title="Feedback Form"
        onSubmit={handleSubmit(onSubmit)}
        loading={isLoadingData}
      >
        <Controller
          name="message"
          control={control}
          rules={{
            required: 'Kolom ini tidak boleh kosong !',
          }}
          render={({ field, fieldState: { error } }) => (
            <Field label="Komentar" error={error?.message}>
              <Input
                {...field}
                error={!!error}
                as="textarea"
                placeholder="Berikan masukan Anda, untuk perbaikan kami kedepannya untuk pemantauan wabah penyakit hewan"
                rows={3}
              />
            </Field>
          )}
        />

        {errorAddBVETFeedback && (
          <Caption variant="error">{errorAddBVETFeedback}</Caption>
        )}
      </FeedbackForm>
    </MainTemplate>
  );
};

export default Home;
