import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGroupById } from 'redux/group/groupAction';
import { GroupState } from 'redux/group/groupReducer';
import { Member, MemberLevel } from 'redux/member/memberTypes';
import { AppState } from 'redux/store';
import { User } from 'redux/user/userTypes';

const checkIfMember = (userId: number, members: Member[]) => {
  return members.find((result) => userId === result.person.id);
};

const useCheckMemberLevel = (groupId: string): MemberLevel => {
  const dispatch = useDispatch();

  const token = localStorage.getItem('token');

  const [memberLevel, setMemberLevel] = useState<MemberLevel>('guest');

  const { id: userId }: User = JSON.parse(localStorage.getItem('user') || '{}');

  const { groupById } = useSelector<AppState, GroupState>(
    (props) => props.group
  );

  // Check member level from
  useEffect(() => {
    if (groupById) {
      if (userId === groupById.owner.id) return setMemberLevel('owner');

      if (groupById.members.length > 0) {
        const member = checkIfMember(userId, groupById.members);
        if (member) return setMemberLevel(member.level);
      }
    }
  }, [groupById, groupId, userId]);

  // Handle get group by id data when group by id is null or not same
  useEffect(() => {
    if (!groupById || groupById.id !== parseFloat(groupId)) {
      dispatch(getGroupById(token, parseFloat(groupId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupById, groupId, token]);

  return memberLevel;
};

export default useCheckMemberLevel;
