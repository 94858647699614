import React from 'react';
import css from '@styled-system/css';
import Brand from 'components/atoms/Brand';
import Flex, { FlexStyleProps } from 'components/atoms/Flex';
import Heading from 'components/atoms/Heading';
import styled from 'styled-components';
import { mediaQueries } from 'themes/config';
import Footer from 'components/molecules/Footer';
import { Link } from 'react-router-dom';
import { Caption } from 'components/atoms/Typography';
import Spinner from 'components/atoms/Spinner';

export type TAuthTemplateLink = {
  path: string;
  text: string;
}[];

export interface IAuthTemplateProps {
  title?: string;
  link?: TAuthTemplateLink;
  loading?: boolean;
}

const CardStyle = styled(Flex)<FlexStyleProps>`
  ${mediaQueries.mobile} {
    max-width: 450px;
  }

  ${css({
    width: '100%',
    position: 'relative',
    justifyContent: 'center',
    flexDirection: 'column',
    marginY: '24px',
    marginX: 'large',
    paddingX: '24px',
    paddingY: '24px',
    paddingBottom: '50px',
    backgroundColor: 'background',
    borderRadius: 'large',
    boxShadow: 'medium',
  })}
`;

const AuthTemplate: React.FC<IAuthTemplateProps> = ({
  title,
  link,
  loading = false,
  children,
  ...rest
}) => (
  <Flex
    minHeight="100vh"
    backgroundColor="muted"
    alignItems="center"
    justifyContent="center"
  >
    <CardStyle overflow="hidden" {...rest}>
      {loading && (
        <Spinner
          position="absolute"
          backgroundColor="#ffffff80"
          top="0"
          left="0"
          right="0"
          bottom="0"
          spinnerSize={50}
        />
      )}

      <Flex
        justifyContent="center"
        paddingX="40px"
        paddingTop="large"
        paddingBottom="32px"
      >
        <Brand maxWidth="350px" width="100%" />
      </Flex>

      {title && (
        <Heading variant="heading" marginBottom="normal">
          {title}
        </Heading>
      )}

      {children}

      {link && (
        <Flex justifyContent="space-between" marginY="medium" marginX="normal">
          {link.map((result) => (
            <Link key={result.path} to={result.path}>
              <Caption variant="link">{result.text}</Caption>
            </Link>
          ))}
        </Flex>
      )}
      <Footer />
    </CardStyle>
  </Flex>
);

export default AuthTemplate;
