import { User } from 'redux/user/userTypes';
import {
  AuthAction,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
} from './authTypes';

export interface AuthState {
  token?: string | null;
  user?: User | null;
  errorLogin?: string | null;
  errorRegister?: string | null;
}

const initialState: AuthState = {
  token: null,
  user: null,
  errorLogin: null,
  errorRegister: null,
};

const authReducer = (state = initialState, action: AuthAction): AuthState => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.access_token,
        user: action.payload.user,
        errorLogin: null,
      };

    case LOGIN_FAILURE: {
      return {
        ...initialState,
        errorLogin: action.payload.error,
      };
    }

    case REGISTER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        errorRegister: null,
      };
    }

    case REGISTER_FAILURE: {
      return {
        ...initialState,
        errorRegister: action.payload.error,
      };
    }

    default:
      return state;
  }
};

export default authReducer;
