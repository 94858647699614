import React, { useMemo, useRef } from 'react';
import { Marker as MarkerProps } from 'leaflet';
import { Marker, Popup, useMapEvent } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import { setLocation } from 'redux/map/mapAction';
import { AppState } from 'redux/store';
import { IMapState } from 'redux/map/mapTypes';

const TagLocationMarker: React.FC = () => {
  const dispatch = useDispatch();
  const markerRef = useRef<MarkerProps>(null);

  const { showInput, location } = useSelector<AppState, IMapState>(
    (state) => state.map
  );

  const map = useMapEvent('click', (e) => {
    if (showInput) {
      dispatch(
        setLocation({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
          description: 'Dipilih dari Peta',
        })
      );

      map.flyTo(e.latlng, 12, {
        duration: 1,
      });
    }
  });

  const handleDragMarker = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          const positionAfterDrag = marker.getLatLng();

          dispatch(
            setLocation({
              lat: positionAfterDrag.lat,
              lng: positionAfterDrag.lng,
              description: 'Dipilih dari Peta',
            })
          );
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return location.lat && location.lng ? (
    <Marker
      draggable={true}
      eventHandlers={handleDragMarker}
      position={[location.lat, location.lng]}
      ref={markerRef}
    >
      <Popup>Geser untuk memindah lokasi</Popup>
    </Marker>
  ) : null;
};

export default TagLocationMarker;
