import { User } from 'redux/user/userTypes';

export const ADD_GROUP_MEMBER = 'ADD_GROUP_MEMBER';
export const ADD_GROUP_MEMBER_SUCCESS = 'ADD_GROUP_MEMBER_SUCCESS';
export const ADD_GROUP_MEMBER_FAILURE = 'ADD_GROUP_MEMBER_FAILURE';
export const UPDATE_GROUP_MEMBER = 'UPDATE_GROUP_MEMBER';
export const UPDATE_GROUP_MEMBER_SUCCESS = 'UPDATE_GROUP_MEMBER_SUCCESS';
export const UPDATE_GROUP_MEMBER_FAILURE = 'UPDATE_GROUP_MEMBER_FAILURE';
export const DELETE_GROUP_MEMBER = 'DELETE_GROUP_MEMBER';
export const DELETE_GROUP_MEMBER_SUCCESS = 'DELETE_GROUP_MEMBER_SUCCESS';
export const DELETE_GROUP_MEMBER_FAILURE = 'DELETE_GROUP_MEMBER_FAILURE';

export type MemberLevel = 'owner' | 'member' | 'guest' | 'admin';

export interface Member {
  id: number;
  person: User;
  level: MemberLevel;
  date_joined: string;
}

// Action
interface AddGroupMemberSuccess {
  type: typeof ADD_GROUP_MEMBER_SUCCESS;
}

interface AddGroupMemberFailure {
  type: typeof ADD_GROUP_MEMBER_FAILURE;
  payload: {
    error: string;
  };
}

interface UpdateGroupMemberSuccess {
  type: typeof UPDATE_GROUP_MEMBER_SUCCESS;
}

interface UpdateGroupMemberFailure {
  type: typeof UPDATE_GROUP_MEMBER_FAILURE;
  payload: {
    error: string;
  };
}

interface DeleteGroupMemberSuccess {
  type: typeof DELETE_GROUP_MEMBER_SUCCESS;
}

interface DeleteGroupMemberFailure {
  type: typeof DELETE_GROUP_MEMBER_FAILURE;
  payload: {
    error: string;
  };
}

export type MembersAction =
  | AddGroupMemberSuccess
  | AddGroupMemberFailure
  | UpdateGroupMemberSuccess
  | UpdateGroupMemberFailure
  | DeleteGroupMemberSuccess
  | DeleteGroupMemberFailure;
